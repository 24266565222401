export const propertiesOntology = {
  "propertyCategories": [
    {
      "name": "Voorzieningen betaling",
      "id": "1",
      "datatype": "freetext",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Voorzieningen betaling"
        }
      },
      "properties": [
        {
          "name": "Opmerking prijzen",
          "id": "1.1",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Opmerking prijzen"
            }
          }
        },
        {
          "name": "Betaalmogelijkheden",
          "id": "1.2",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Betaalmogelijkheden"
            }
          },
          "options": [
            {
              "name": "Contant",
              "id": "1.2.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Contant"
                }
              }
            },
            {
              "name": "VVV Cadeaukaart",
              "id": "1.2.10",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "VVV Cadeaukaart"
                }
              }
            },
            {
              "name": "PIN",
              "id": "1.2.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "PIN"
                }
              }
            },
            {
              "name": "Creditcard",
              "id": "1.2.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Creditcard"
                }
              }
            },
            {
              "name": "Chipknip",
              "id": "1.2.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Chipknip"
                }
              }
            },
            {
              "name": "Online",
              "id": "1.2.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Online"
                }
              }
            },
            {
              "name": "VVV Cadeaubon",
              "id": "1.2.6",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "VVV Cadeaubon"
                }
              }
            },
            {
              "name": "VVV Dinercheque",
              "id": "1.2.7",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "VVV Dinercheque"
                }
              }
            },
            {
              "name": "VVV Giftcard",
              "id": "1.2.8",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "VVV Giftcard"
                }
              }
            },
            {
              "name": "VVV Lekkerweg",
              "id": "1.2.9",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "VVV Lekkerweg"
                }
              }
            }
          ]
        },
        {
          "name": "Betalen met PIN mogelijk",
          "id": "1.3",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Betalen met PIN mogelijk"
            }
          }
        },
        {
          "name": "Betalen met chip(knip) mogelijk",
          "id": "1.4",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Betalen met chip(knip) mogelijk"
            }
          }
        },
        {
          "name": "Betalen met creditcard mogelijk",
          "id": "1.5",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Betalen met creditcard mogelijk"
            }
          }
        },
        {
          "name": "Contant betalen mogelijk",
          "id": "1.6",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Contant betalen mogelijk"
            }
          }
        },
        {
          "name": "Betalen met iDEAL mogelijk",
          "id": "1.7",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Betalen met iDEAL mogelijk"
            }
          }
        },
        {
          "name": "Betalen met cadeaubon mogelijk",
          "id": "1.9",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Betalen met cadeaubon mogelijk"
            }
          }
        }
      ]
    },
    {
      "name": "Ligging",
      "id": "10",
      "datatype": "freetext",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Ligging"
        }
      },
      "properties": [
        {
          "name": "Rustig gelegen",
          "id": "10.1",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Rustig gelegen"
            }
          }
        },
        {
          "name": "Aan/bij natuurwater",
          "id": "10.10",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Aan/bij natuurwater"
            }
          }
        },
        {
          "name": "Bij strand/zee",
          "id": "10.11",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Bij strand/zee"
            }
          }
        },
        {
          "name": "In/bij bos",
          "id": "10.12",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "In/bij bos"
            }
          }
        },
        {
          "name": "In/bij duinen",
          "id": "10.13",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "In/bij duinen"
            }
          }
        },
        {
          "name": "In/bij heidegebied",
          "id": "10.14",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "In/bij heidegebied"
            }
          }
        },
        {
          "name": "In/bij heuvels",
          "id": "10.15",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "In/bij heuvels"
            }
          }
        },
        {
          "name": "In/bij natuurgebied",
          "id": "10.16",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "In/bij natuurgebied"
            }
          }
        },
        {
          "name": "In/bij wandelgebieden",
          "id": "10.17",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "In/bij wandelgebieden"
            }
          }
        },
        {
          "name": "In het centrum",
          "id": "10.18",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "In het centrum"
            }
          }
        },
        {
          "name": "In/bij stedelijk gebied",
          "id": "10.19",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "In/bij stedelijk gebied"
            }
          }
        },
        {
          "name": "Fraai gelegen",
          "id": "10.2",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Fraai gelegen"
            }
          }
        },
        {
          "name": "In/bij museumgebied",
          "id": "10.20",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "In/bij museumgebied"
            }
          }
        },
        {
          "name": "Buiten het centrum",
          "id": "10.21",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Buiten het centrum"
            }
          }
        },
        {
          "name": "Landelijk gelegen",
          "id": "10.22",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Landelijk gelegen"
            }
          }
        },
        {
          "name": "Op een eiland",
          "id": "10.23",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Op een eiland"
            }
          }
        },
        {
          "name": "Bijzonder gebouw",
          "id": "10.24",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Bijzonder gebouw"
            }
          }
        },
        {
          "name": "Zeezicht",
          "id": "10.25",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Zeezicht"
            }
          }
        },
        {
          "name": "Panoramisch uitzicht",
          "id": "10.26",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Panoramisch uitzicht"
            }
          }
        },
        {
          "name": "Boerderij in bedrijf",
          "id": "10.27",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Boerderij in bedrijf"
            }
          }
        },
        {
          "name": "Nabij doorgaande route",
          "id": "10.28",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Nabij doorgaande route"
            }
          }
        },
        {
          "name": "In uitgaanscentrum",
          "id": "10.29",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "In uitgaanscentrum"
            }
          }
        },
        {
          "name": "Afstand tot centrum dorp/stad",
          "id": "10.3",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Afstand tot centrum dorp/stad"
            }
          },
          "unit": "km"
        },
        {
          "name": "Bereikbaarheid met OV",
          "id": "10.30",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Bereikbaarheid met OV"
            }
          }
        },
        {
          "name": "Aan rivier",
          "id": "10.31",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Aan rivier"
            }
          }
        },
        {
          "name": "Regio Arnhem",
          "id": "10.32",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Regio Arnhem"
            }
          }
        },
        {
          "name": "Regio Rijk van Nijmegen",
          "id": "10.33",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Regio Rijk van Nijmegen"
            }
          }
        },
        {
          "name": "Regio Veluwezoom",
          "id": "10.34",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Regio Veluwezoom"
            }
          }
        },
        {
          "name": "Regio De Gelderse Poort",
          "id": "10.35",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Regio De Gelderse Poort"
            }
          }
        },
        {
          "name": "Regio Liemers",
          "id": "10.36",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Regio Liemers"
            }
          }
        },
        {
          "name": "Regio Betuwe",
          "id": "10.37",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Regio Betuwe"
            }
          }
        },
        {
          "name": "Regio Land van Maas en Waal",
          "id": "10.38",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Regio Land van Maas en Waal"
            }
          }
        },
        {
          "name": "Afstand tot treinstation",
          "id": "10.39",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Afstand tot treinstation"
            }
          },
          "unit": "km"
        },
        {
          "name": "Afstand tot strand",
          "id": "10.4",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Afstand tot strand"
            }
          },
          "unit": "km"
        },
        {
          "name": "Afstand tot rivier/meer",
          "id": "10.5",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Afstand tot rivier/meer"
            }
          },
          "unit": "km"
        },
        {
          "name": "Afstand tot snelweg",
          "id": "10.6",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Afstand tot snelweg"
            }
          },
          "unit": "km"
        },
        {
          "name": "Afstand tot openbaar zwembad",
          "id": "10.7",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Afstand tot openbaar zwembad"
            }
          },
          "unit": "km"
        },
        {
          "name": "Afstand tot openbaar vervoer",
          "id": "10.8",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Afstand tot openbaar vervoer"
            }
          },
          "unit": "km"
        },
        {
          "name": "Afstand tot vliegveld",
          "id": "10.9",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Afstand tot vliegveld"
            }
          },
          "unit": "km"
        }
      ]
    },
    {
      "name": "Accommodatie",
      "id": "11",
      "datatype": "freetext",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Accommodatie"
        }
      },
      "properties": [
        {
          "name": "Soort gebouw",
          "id": "11.1",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Soort gebouw"
            }
          },
          "options": [
            {
              "name": "Anders dan anders",
              "id": "11.1.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Anders dan anders"
                }
              }
            },
            {
              "name": "Deltawerk",
              "id": "11.1.10",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Deltawerk"
                }
              }
            },
            {
              "name": "Distilleerderij",
              "id": "11.1.11",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Distilleerderij"
                }
              }
            },
            {
              "name": "Fabriek",
              "id": "11.1.12",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Fabriek"
                }
              }
            },
            {
              "name": "Fort/vestingwerk",
              "id": "11.1.13",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Fort/vestingwerk"
                }
              }
            },
            {
              "name": "Gevangenis",
              "id": "11.1.14",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Gevangenis"
                }
              }
            },
            {
              "name": "Gildehuis",
              "id": "11.1.15",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Gildehuis"
                }
              }
            },
            {
              "name": "Grachtenpand",
              "id": "11.1.16",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Grachtenpand"
                }
              }
            },
            {
              "name": "Grot",
              "id": "11.1.17",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Grot"
                }
              }
            },
            {
              "name": "Jachthuis",
              "id": "11.1.18",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Jachthuis"
                }
              }
            },
            {
              "name": "Kapel",
              "id": "11.1.19",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Kapel"
                }
              }
            },
            {
              "name": "Apotheek",
              "id": "11.1.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Apotheek"
                }
              }
            },
            {
              "name": "Kasteel",
              "id": "11.1.20",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Kasteel"
                }
              }
            },
            {
              "name": "Kerk",
              "id": "11.1.21",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Kerk"
                }
              }
            },
            {
              "name": "Klooster",
              "id": "11.1.22",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Klooster"
                }
              }
            },
            {
              "name": "Koetshuis",
              "id": "11.1.23",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Koetshuis"
                }
              }
            },
            {
              "name": "Landhuis",
              "id": "11.1.24",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Landhuis"
                }
              }
            },
            {
              "name": "Loge",
              "id": "11.1.25",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Loge"
                }
              }
            },
            {
              "name": "Molen",
              "id": "11.1.26",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Molen"
                }
              }
            },
            {
              "name": "Monumentaal Pand",
              "id": "11.1.27",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Monumentaal Pand"
                }
              }
            },
            {
              "name": "Muntgebouw",
              "id": "11.1.28",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Muntgebouw"
                }
              }
            },
            {
              "name": "Museum",
              "id": "11.1.29",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Museum"
                }
              }
            },
            {
              "name": "Arsenaal",
              "id": "11.1.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Arsenaal"
                }
              }
            },
            {
              "name": "Paardenstal",
              "id": "11.1.30",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Paardenstal"
                }
              }
            },
            {
              "name": "Pakhuis",
              "id": "11.1.31",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Pakhuis"
                }
              }
            },
            {
              "name": "Paleis",
              "id": "11.1.32",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Paleis"
                }
              }
            },
            {
              "name": "Pastorie",
              "id": "11.1.33",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Pastorie"
                }
              }
            },
            {
              "name": "Raadhuis",
              "id": "11.1.34",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Raadhuis"
                }
              }
            },
            {
              "name": "Ranch",
              "id": "11.1.35",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ranch"
                }
              }
            },
            {
              "name": "Rechthuis",
              "id": "11.1.36",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Rechthuis"
                }
              }
            },
            {
              "name": "Schaapskooi",
              "id": "11.1.37",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Schaapskooi"
                }
              }
            },
            {
              "name": "Schip",
              "id": "11.1.38",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Schip"
                }
              }
            },
            {
              "name": "Smederij",
              "id": "11.1.39",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Smederij"
                }
              }
            },
            {
              "name": "Bakkerij",
              "id": "11.1.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Bakkerij"
                }
              }
            },
            {
              "name": "Spa",
              "id": "11.1.40",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Spa"
                }
              }
            },
            {
              "name": "Spuithuis",
              "id": "11.1.41",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Spuithuis"
                }
              }
            },
            {
              "name": "Stadswoning",
              "id": "11.1.42",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Stadswoning"
                }
              }
            },
            {
              "name": "Tolhuis",
              "id": "11.1.43",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Tolhuis"
                }
              }
            },
            {
              "name": "Toren",
              "id": "11.1.44",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Toren"
                }
              }
            },
            {
              "name": "Uitspanning-stalhouderij",
              "id": "11.1.45",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Uitspanning-stalhouderij"
                }
              }
            },
            {
              "name": "Veerhuis",
              "id": "11.1.46",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Veerhuis"
                }
              }
            },
            {
              "name": "Vesting",
              "id": "11.1.47",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Vesting"
                }
              }
            },
            {
              "name": "Villa",
              "id": "11.1.48",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Villa"
                }
              }
            },
            {
              "name": "Vuurtoren",
              "id": "11.1.49",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Vuurtoren"
                }
              }
            },
            {
              "name": "Beursgebouw",
              "id": "11.1.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Beursgebouw"
                }
              }
            },
            {
              "name": "Waaggebouw",
              "id": "11.1.50",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Waaggebouw"
                }
              }
            },
            {
              "name": "Watertoren",
              "id": "11.1.51",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Watertoren"
                }
              }
            },
            {
              "name": "Weeshuis",
              "id": "11.1.52",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Weeshuis"
                }
              }
            },
            {
              "name": "Werfkelder",
              "id": "11.1.53",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Werfkelder"
                }
              }
            },
            {
              "name": "Woonboot",
              "id": "11.1.54",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Woonboot"
                }
              }
            },
            {
              "name": "Gerechtsgebouw",
              "id": "11.1.55",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Gerechtsgebouw"
                }
              }
            },
            {
              "name": "Vliegtuig",
              "id": "11.1.56",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Vliegtuig"
                }
              }
            },
            {
              "name": "Sportaccommodatie",
              "id": "11.1.57",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Sportaccommodatie"
                }
              }
            },
            {
              "name": "Studiocomplex",
              "id": "11.1.58",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Studiocomplex"
                }
              }
            },
            {
              "name": "Tram/trein",
              "id": "11.1.59",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Tram/trein"
                }
              }
            },
            {
              "name": "Boerderij",
              "id": "11.1.6",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Boerderij"
                }
              }
            },
            {
              "name": "Voetbalstadion",
              "id": "11.1.60",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Voetbalstadion"
                }
              }
            },
            {
              "name": "Boot",
              "id": "11.1.7",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Boot"
                }
              }
            },
            {
              "name": "Brouwerij",
              "id": "11.1.8",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Brouwerij"
                }
              }
            },
            {
              "name": "Buitenplaats",
              "id": "11.1.9",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Buitenplaats"
                }
              }
            }
          ]
        },
        {
          "name": "Uitstraling Zakelijk",
          "id": "11.10",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Uitstraling Zakelijk"
            }
          },
          "options": [
            {
              "name": "Puur Zakelijk",
              "id": "11.10.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Puur Zakelijk"
                }
              }
            },
            {
              "name": "Groen",
              "id": "11.10.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Groen"
                }
              }
            },
            {
              "name": "(Ont)spannend",
              "id": "11.10.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "(Ont)spannend"
                }
              }
            }
          ]
        },
        {
          "name": "Soort accommodatie",
          "id": "11.2",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Soort accommodatie"
            }
          },
          "options": [
            {
              "name": "All Suite",
              "id": "11.2.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "All Suite"
                }
              }
            },
            {
              "name": "Condo",
              "id": "11.2.10",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Condo"
                }
              }
            },
            {
              "name": "Conferentiecentrum",
              "id": "11.2.11",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Conferentiecentrum"
                }
              }
            },
            {
              "name": "Cottage, gîte, casa rural",
              "id": "11.2.12",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Cottage, gîte, casa rural"
                }
              }
            },
            {
              "name": "Designhotel",
              "id": "11.2.13",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Designhotel"
                }
              }
            },
            {
              "name": "Hostel",
              "id": "11.2.14",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Hostel"
                }
              }
            },
            {
              "name": "Hotel",
              "id": "11.2.15",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Hotel"
                }
              }
            },
            {
              "name": "Hotel Garni",
              "id": "11.2.16",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Hotel Garni"
                }
              }
            },
            {
              "name": "Hotel/Café",
              "id": "11.2.17",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Hotel/Café"
                }
              }
            },
            {
              "name": "Hotel/Café/Restaurant",
              "id": "11.2.18",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Hotel/Café/Restaurant"
                }
              }
            },
            {
              "name": "Hotel/Pension",
              "id": "11.2.19",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Hotel/Pension"
                }
              }
            },
            {
              "name": "Appartement",
              "id": "11.2.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Appartement"
                }
              }
            },
            {
              "name": "Hotel/Restaurant",
              "id": "11.2.20",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Hotel/Restaurant"
                }
              }
            },
            {
              "name": "Hotel - en Conferentiecentrum",
              "id": "11.2.21",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Hotel - en Conferentiecentrum"
                }
              }
            },
            {
              "name": "Huisje van hout",
              "id": "11.2.22",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Huisje van hout"
                }
              }
            },
            {
              "name": "Huisje van hout en steen",
              "id": "11.2.23",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Huisje van hout en steen"
                }
              }
            },
            {
              "name": "Huisje van steen",
              "id": "11.2.24",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Huisje van steen"
                }
              }
            },
            {
              "name": "Inn",
              "id": "11.2.25",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Inn"
                }
              }
            },
            {
              "name": "Kampeerterrein",
              "id": "11.2.26",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Kampeerterrein"
                }
              }
            },
            {
              "name": "Kampeerboerderij",
              "id": "11.2.27",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Kampeerboerderij"
                }
              }
            },
            {
              "name": "Kasteelhotel",
              "id": "11.2.28",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Kasteelhotel"
                }
              }
            },
            {
              "name": "Landhotel",
              "id": "11.2.29",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Landhotel"
                }
              }
            },
            {
              "name": "Appartementenhotel",
              "id": "11.2.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Appartementenhotel"
                }
              }
            },
            {
              "name": "Lang verblijf",
              "id": "11.2.30",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Lang verblijf"
                }
              }
            },
            {
              "name": "Logies & Ontbijt",
              "id": "11.2.31",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Logies & Ontbijt"
                }
              }
            },
            {
              "name": "Motel",
              "id": "11.2.32",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Motel"
                }
              }
            },
            {
              "name": "Pension",
              "id": "11.2.33",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Pension"
                }
              }
            },
            {
              "name": "Plattelandshotel",
              "id": "11.2.34",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Plattelandshotel"
                }
              }
            },
            {
              "name": "Residentieel hotel",
              "id": "11.2.35",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Residentieel hotel"
                }
              }
            },
            {
              "name": "Resort",
              "id": "11.2.36",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Resort"
                }
              }
            },
            {
              "name": "Vakantiehuis",
              "id": "11.2.37",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Vakantiehuis"
                }
              }
            },
            {
              "name": "Vakantiewoning",
              "id": "11.2.38",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Vakantiewoning"
                }
              }
            },
            {
              "name": "Landgoed",
              "id": "11.2.39",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Landgoed"
                }
              }
            },
            {
              "name": "Bed and Breakfast",
              "id": "11.2.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Bed and Breakfast"
                }
              }
            },
            {
              "name": "Restaurant",
              "id": "11.2.40",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Restaurant"
                }
              }
            },
            {
              "name": "Strandpaviljoen",
              "id": "11.2.41",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Strandpaviljoen"
                }
              }
            },
            {
              "name": "Museum",
              "id": "11.2.42",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Museum"
                }
              }
            },
            {
              "name": "Culturele locatie",
              "id": "11.2.43",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Culturele locatie"
                }
              }
            },
            {
              "name": "Monumentale locatie",
              "id": "11.2.44",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Monumentale locatie"
                }
              }
            },
            {
              "name": "Theehuis",
              "id": "11.2.45",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Theehuis"
                }
              }
            },
            {
              "name": "Botel",
              "id": "11.2.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Botel"
                }
              }
            },
            {
              "name": "Bungalow",
              "id": "11.2.6",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Bungalow"
                }
              }
            },
            {
              "name": "Caravan",
              "id": "11.2.7",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Caravan"
                }
              }
            },
            {
              "name": "Caravan van hout",
              "id": "11.2.8",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Caravan van hout"
                }
              }
            },
            {
              "name": "Chalet",
              "id": "11.2.9",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Chalet"
                }
              }
            }
          ]
        },
        {
          "name": "Situering",
          "id": "11.3",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Situering"
            }
          },
          "options": [
            {
              "name": "Vrijstaand",
              "id": "11.3.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Vrijstaand"
                }
              }
            },
            {
              "name": "Twee onder één dak",
              "id": "11.3.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Twee onder één dak"
                }
              }
            },
            {
              "name": "Meer appartementen onder één dak",
              "id": "11.3.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Meer appartementen onder één dak"
                }
              }
            },
            {
              "name": "Onderdeel van bungalowpark",
              "id": "11.3.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Onderdeel van bungalowpark"
                }
              }
            },
            {
              "name": "Onderdeel van appartementencomplex",
              "id": "11.3.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Onderdeel van appartementencomplex"
                }
              }
            },
            {
              "name": "Op camping",
              "id": "11.3.6",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Op camping"
                }
              }
            },
            {
              "name": "Op boerderij",
              "id": "11.3.7",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Op boerderij"
                }
              }
            },
            {
              "name": "Geschakeld",
              "id": "11.3.8",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Geschakeld"
                }
              }
            }
          ]
        },
        {
          "name": "Bouwstijl accommodatie",
          "id": "11.4",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Bouwstijl accommodatie"
            }
          },
          "options": [
            {
              "name": "art nouveau/Jugendstil",
              "id": "11.4.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "art nouveau/Jugendstil"
                }
              }
            },
            {
              "name": "neo-romaans",
              "id": "11.4.10",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "neo-romaans"
                }
              }
            },
            {
              "name": "rationalisme",
              "id": "11.4.11",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "rationalisme"
                }
              }
            },
            {
              "name": "renaissance",
              "id": "11.4.12",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "renaissance"
                }
              }
            },
            {
              "name": "romaans",
              "id": "11.4.13",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "romaans"
                }
              }
            },
            {
              "name": "waterstaatstijl",
              "id": "11.4.14",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "waterstaatstijl"
                }
              }
            },
            {
              "name": "barok",
              "id": "11.4.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "barok"
                }
              }
            },
            {
              "name": "expressionisme/Amsterdamse School",
              "id": "11.4.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "expressionisme/Amsterdamse School"
                }
              }
            },
            {
              "name": "expressionisme/art deco",
              "id": "11.4.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "expressionisme/art deco"
                }
              }
            },
            {
              "name": "gotiek",
              "id": "11.4.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "gotiek"
                }
              }
            },
            {
              "name": "neo-barok",
              "id": "11.4.6",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "neo-barok"
                }
              }
            },
            {
              "name": "neo-classicisme",
              "id": "11.4.7",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "neo-classicisme"
                }
              }
            },
            {
              "name": "neo-gotiek",
              "id": "11.4.8",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "neo-gotiek"
                }
              }
            },
            {
              "name": "neo-renaissance",
              "id": "11.4.9",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "neo-renaissance"
                }
              }
            }
          ]
        },
        {
          "name": "Uitstraling/Inrichting accommodatie",
          "id": "11.5",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Uitstraling/Inrichting accommodatie"
            }
          },
          "options": [
            {
              "name": "apres-ski",
              "id": "11.5.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "apres-ski"
                }
              }
            },
            {
              "name": "feestelijk",
              "id": "11.5.10",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "feestelijk"
                }
              }
            },
            {
              "name": "grand luxe",
              "id": "11.5.11",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "grand luxe"
                }
              }
            },
            {
              "name": "klassiek",
              "id": "11.5.12",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "klassiek"
                }
              }
            },
            {
              "name": "latin",
              "id": "11.5.13",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "latin"
                }
              }
            },
            {
              "name": "luxe",
              "id": "11.5.14",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "luxe"
                }
              }
            },
            {
              "name": "mediterraan",
              "id": "11.5.15",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "mediterraan"
                }
              }
            },
            {
              "name": "modern",
              "id": "11.5.16",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "modern"
                }
              }
            },
            {
              "name": "romantisch",
              "id": "11.5.17",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "romantisch"
                }
              }
            },
            {
              "name": "seventies",
              "id": "11.5.18",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "seventies"
                }
              }
            },
            {
              "name": "trendy",
              "id": "11.5.19",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "trendy"
                }
              }
            },
            {
              "name": "authentiek",
              "id": "11.5.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "authentiek"
                }
              }
            },
            {
              "name": "zakelijk",
              "id": "11.5.20",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "zakelijk"
                }
              }
            },
            {
              "name": "zeer comfortabel",
              "id": "11.5.21",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "zeer comfortabel"
                }
              }
            },
            {
              "name": "beach",
              "id": "11.5.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "beach"
                }
              }
            },
            {
              "name": "bruin",
              "id": "11.5.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "bruin"
                }
              }
            },
            {
              "name": "comfortabel",
              "id": "11.5.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "comfortabel"
                }
              }
            },
            {
              "name": "design",
              "id": "11.5.6",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "design"
                }
              }
            },
            {
              "name": "eenvoudig",
              "id": "11.5.7",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "eenvoudig"
                }
              }
            },
            {
              "name": "eighties",
              "id": "11.5.8",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "eighties"
                }
              }
            },
            {
              "name": "exclusief",
              "id": "11.5.9",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "exclusief"
                }
              }
            }
          ]
        },
        {
          "name": "Contactpersoon",
          "id": "11.6",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Contactpersoon"
            }
          }
        },
        {
          "name": "Bouwjaar",
          "id": "11.8",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Bouwjaar"
            }
          }
        },
        {
          "name": "Soort molen",
          "id": "11.9",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Soort molen"
            }
          },
          "options": [
            {
              "name": "Rosmolen",
              "id": "11.9.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Rosmolen"
                }
              }
            },
            {
              "name": "Water(rad)molen",
              "id": "11.9.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Water(rad)molen"
                }
              }
            },
            {
              "name": "Windmolen",
              "id": "11.9.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Windmolen"
                }
              }
            }
          ]
        }
      ]
    },
    {
      "name": "Verhuur",
      "id": "12",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Verhuur"
        }
      },
      "properties": [
        {
          "name": "Weekverhuur",
          "id": "12.1",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Weekverhuur"
            }
          }
        },
        {
          "name": "Zeilboten",
          "id": "12.10",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Zeilboten"
            }
          }
        },
        {
          "name": "Weekendverhuur",
          "id": "12.2",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Weekendverhuur"
            }
          }
        },
        {
          "name": "Midweekverhuur",
          "id": "12.3",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Midweekverhuur"
            }
          }
        },
        {
          "name": "Stacaravans",
          "id": "12.4",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Stacaravans"
            }
          }
        },
        {
          "name": "Trekkershutten",
          "id": "12.5",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Trekkershutten"
            }
          }
        },
        {
          "name": "Tenten",
          "id": "12.6",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Tenten"
            }
          }
        },
        {
          "name": "Fietsverhuur",
          "id": "12.11",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Fietserhuur"
            }
          }
        },
        {
          "name": "Bergruimte",
          "id": "12.7",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Bergruimte"
            }
          }
        },
        {
          "name": "Kano's",
          "id": "12.8",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kano's"
            }
          }
        },
        {
          "name": "Sloepen",
          "id": "12.9",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Sloepen"
            }
          }
        }
      ]
    },
    {
      "name": "Verwarming",
      "id": "13",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Verwarming"
        }
      },
      "properties": [
        {
          "name": "Airconditioning",
          "id": "13.1",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Airconditioning"
            }
          }
        },
        {
          "name": "Centrale verwarming",
          "id": "13.2",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Centrale verwarming"
            }
          }
        },
        {
          "name": "Verwarming anders dan CV",
          "id": "13.3",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Verwarming anders dan CV"
            }
          }
        },
        {
          "name": "Open haard",
          "id": "13.4",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Open haard"
            }
          }
        },
        {
          "name": "Kampvuurplaats",
          "id": "13.5",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kampvuurplaats"
            }
          }
        },
        {
          "name": "Geiser",
          "id": "13.6",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Geiser"
            }
          }
        }
      ]
    },
    {
      "name": "Faciliteiten (Algemeen)",
      "id": "14",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Faciliteiten (Algemeen)"
        }
      },
      "properties": [
        {
          "name": "Roken",
          "id": "14.1",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Roken"
            }
          },
          "options": [
            {
              "name": "Roken toegestaan",
              "id": "14.1.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Roken toegestaan"
                }
              }
            },
            {
              "name": "Hoek voor niet rokers",
              "id": "14.1.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Hoek voor niet rokers"
                }
              }
            },
            {
              "name": "Hoek voor rokers",
              "id": "14.1.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Hoek voor rokers"
                }
              }
            },
            {
              "name": "Aparte ruimte voor rokers",
              "id": "14.1.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Aparte ruimte voor rokers"
                }
              }
            },
            {
              "name": "Rookvrije kamers",
              "id": "14.1.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Rookvrije kamers"
                }
              }
            },
            {
              "name": "Volledig rookvrij",
              "id": "14.1.6",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Volledig rookvrij"
                }
              }
            },
            {
              "name": "Onbekend",
              "id": "14.1.7",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Onbekend"
                }
              }
            }
          ]
        },
        {
          "name": "Garderobe",
          "id": "14.2",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Garderobe"
            }
          },
          "options": [
            {
              "name": "Onbekend",
              "id": "14.2.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Onbekend"
                }
              }
            },
            {
              "name": "Geen garderobe",
              "id": "14.2.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Geen garderobe"
                }
              }
            },
            {
              "name": "Onbewaakte garderobe",
              "id": "14.2.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Onbewaakte garderobe"
                }
              }
            },
            {
              "name": "Bewaakte garderobe",
              "id": "14.2.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Bewaakte garderobe"
                }
              }
            }
          ]
        },
        {
          "name": "EHBO-post",
          "id": "14.3",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "EHBO-post"
            }
          }
        },
        {
          "name": "Restaurant (voor gasten)",
          "id": "14.4",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Restaurant (voor gasten)"
            }
          }
        },
        {
          "name": "Rookruimte",
          "id": "14.5",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Rookruimte"
            }
          }
        },
        {
          "name": "Horecavoorzieningen",
          "id": "14.6",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Horecavoorzieningen"
            }
          }
        },
        {
          "name": "Kluisjes aanwezig",
          "id": "14.7",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kluisjes aanwezig"
            }
          }
        },
        {
          "name": "Pendeldienst van/naar vliegveld",
          "id": "14.8",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Pendeldienst van/naar vliegveld"
            }
          }
        },
        {
          "name": "Overnachten op locatie",
          "id": "14.99.1",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Overnachten op locatie"
            }
          }
        }
      ]
    },
    {
      "name": "Faciliteiten (Accommodatie)",
      "id": "15",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Faciliteiten (Accommodatie)"
        }
      },
      "properties": [
        {
          "name": "Bruidssuite",
          "id": "15.1",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Bruidssuite"
            }
          }
        },
        {
          "name": "Lift",
          "id": "15.10",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Lift"
            }
          }
        },
        {
          "name": "Ook individueel overnachten",
          "id": "15.11",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Ook individueel overnachten"
            }
          }
        },
        {
          "name": "Overdekt terras",
          "id": "15.12",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Overdekt terras"
            }
          }
        },
        {
          "name": "Suites",
          "id": "15.13",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Suites"
            }
          }
        },
        {
          "name": "Terras",
          "id": "15.14",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Terras"
            }
          }
        },
        {
          "name": "Dakterras",
          "id": "15.15",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Dakterras"
            }
          }
        },
        {
          "name": "Tuinstoelen",
          "id": "15.16",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Tuinstoelen"
            }
          }
        },
        {
          "name": "Tuin",
          "id": "15.17",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Tuin"
            }
          }
        },
        {
          "name": "Ventilator",
          "id": "15.18",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Ventilator"
            }
          }
        },
        {
          "name": "Verhard terras",
          "id": "15.19",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Verhard terras"
            }
          }
        },
        {
          "name": "Halfpension verplicht",
          "id": "15.2",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Halfpension verplicht"
            }
          }
        },
        {
          "name": "Kluisjes aanwezig",
          "id": "15.20",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kluisjes aanwezig"
            }
          }
        },
        {
          "name": "Kleedkamers",
          "id": "15.21",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kleedkamers"
            }
          }
        },
        {
          "name": "Bar",
          "id": "15.22",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Bar"
            }
          }
        },
        {
          "name": "Zwembad",
          "id": "15.25",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Zwembad"
            }
          }
        },
        {
          "name": "Fitness",
          "id": "15.23",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Fitness"
            }
          }
        },
        {
          "name": "Wellness",
          "id": "15.24",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Wellness"
            }
          }
        },
        {
          "name": "Kamer met balkon",
          "id": "15.3",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kamer met balkon"
            }
          }
        },
        {
          "name": "Kamer met terras/wintertuin",
          "id": "15.4",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kamer met terras/wintertuin"
            }
          },
          "unit": "m2"
        },
        {
          "name": "Kamers op begane grond",
          "id": "15.5",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kamers op begane grond"
            }
          }
        },
        {
          "name": "Accommodatie gelijkvloers",
          "id": "15.6",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Accommodatie gelijkvloers"
            }
          }
        },
        {
          "name": "Accommodatie op verdieping",
          "id": "15.7",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Accommodatie op verdieping"
            }
          }
        },
        {
          "name": "Kluis in accommodatie",
          "id": "15.8",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kluis in accommodatie"
            }
          }
        },
        {
          "name": "Kluis op kamer",
          "id": "15.9",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kluis op kamer"
            }
          }
        }
      ]
    },
    {
      "name": "Faciliteiten (Groepsaccommodatie)",
      "id": "16",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Faciliteiten (Groepsaccommodatie)"
        }
      },
      "properties": [
        {
          "name": "Woonkamer",
          "id": "16.1",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Woonkamer"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Dagverblijf",
          "id": "16.2",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Dagverblijf"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Verwarmd dagverblijf",
          "id": "16.3",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Verwarmd dagverblijf"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Recreatiezaal",
          "id": "16.4",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Recreatiezaal"
            }
          }
        },
        {
          "name": "Familiekamers",
          "id": "16.5",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Familiekamers"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Stafkamer",
          "id": "16.6",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Stafkamer"
            }
          }
        }
      ]
    },
    {
      "name": "Faciliteiten (Camping)",
      "id": "17",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Faciliteiten (Camping)"
        }
      },
      "properties": [
        {
          "name": "Type camping",
          "id": "17.1",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Type camping"
            }
          },
          "options": [
            {
              "name": "camping",
              "id": "17.1.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "camping"
                }
              }
            },
            {
              "name": "boerderijcamping",
              "id": "17.1.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "boerderijcamping"
                }
              }
            },
            {
              "name": "familiecamping",
              "id": "17.1.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "familiecamping"
                }
              }
            },
            {
              "name": "jeugdcampinggedeelte",
              "id": "17.1.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "jeugdcampinggedeelte"
                }
              }
            },
            {
              "name": "landgoed-/kasteelcamping",
              "id": "17.1.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "landgoed-/kasteelcamping"
                }
              }
            },
            {
              "name": "minicamping",
              "id": "17.1.6",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "minicamping"
                }
              }
            },
            {
              "name": "naturistencamping",
              "id": "17.1.7",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "naturistencamping"
                }
              }
            },
            {
              "name": "natuurcamping",
              "id": "17.1.8",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "natuurcamping"
                }
              }
            },
            {
              "name": "watersportcamping",
              "id": "17.1.9",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "watersportcamping"
                }
              }
            }
          ]
        },
        {
          "name": "Eigen gasaansluiting",
          "id": "17.10",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Eigen gasaansluiting"
            }
          }
        },
        {
          "name": "Privé sanitair",
          "id": "17.11",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Privé sanitair"
            }
          }
        },
        {
          "name": "Rioolafvoer op standplaats",
          "id": "17.12",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Rioolafvoer op standplaats"
            }
          }
        },
        {
          "name": "Rioolaansl. Toercaravan",
          "id": "17.13",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Rioolaansl. Toercaravan"
            }
          }
        },
        {
          "name": "Electriciteitsaansluiting",
          "id": "17.14",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Electriciteitsaansluiting"
            }
          }
        },
        {
          "name": "Stortplaats chemisch toilet",
          "id": "17.15",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Stortplaats chemisch toilet"
            }
          }
        },
        {
          "name": "Wateraansluiting",
          "id": "17.16",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Wateraansluiting"
            }
          }
        },
        {
          "name": "Watertappunt",
          "id": "17.17",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Watertappunt"
            }
          }
        },
        {
          "name": "CEE-norm",
          "id": "17.18",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "CEE-norm"
            }
          }
        },
        {
          "name": "kantoor/receptie",
          "id": "17.19",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "kantoor/receptie"
            }
          }
        },
        {
          "name": "Beschutting",
          "id": "17.2",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Beschutting"
            }
          },
          "options": [
            {
              "name": "geen",
              "id": "17.2.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "geen"
                }
              }
            },
            {
              "name": "weinig",
              "id": "17.2.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "weinig"
                }
              }
            },
            {
              "name": "gedeeltelijk",
              "id": "17.2.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "gedeeltelijk"
                }
              }
            },
            {
              "name": "geheel",
              "id": "17.2.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "geheel"
                }
              }
            }
          ]
        },
        {
          "name": "Parkeren bij caravan",
          "id": "17.20",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Parkeren bij caravan"
            }
          }
        },
        {
          "name": "STN",
          "id": "17.21",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "STN"
            }
          }
        },
        {
          "name": "alleenreizende jongeren toegestaan",
          "id": "17.22",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "alleenreizende jongeren toegestaan"
            }
          }
        },
        {
          "name": "barbecue toegestaan",
          "id": "17.23",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "barbecue toegestaan"
            }
          }
        },
        {
          "name": "campers toegestaan",
          "id": "17.24",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "campers toegestaan"
            }
          }
        },
        {
          "name": "eigen toilet standplaats",
          "id": "17.25",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "eigen toilet standplaats"
            }
          }
        },
        {
          "name": "eigen sanitair standplaats",
          "id": "17.26",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "eigen sanitair standplaats"
            }
          }
        },
        {
          "name": "eigen wateraansluiting standplaats",
          "id": "17.27",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "eigen wateraansluiting standplaats"
            }
          }
        },
        {
          "name": "Campingwinkel",
          "id": "17.28",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Campingwinkel"
            }
          }
        },
        {
          "name": "Safaritenten (op camping)",
          "id": "17.29",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Safaritenten (op camping)"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Grond",
          "id": "17.3",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Grond"
            }
          },
          "options": [
            {
              "name": "Bos",
              "id": "17.3.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Bos"
                }
              }
            },
            {
              "name": "Gras",
              "id": "17.3.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Gras"
                }
              }
            },
            {
              "name": "Steen",
              "id": "17.3.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Steen"
                }
              }
            },
            {
              "name": "Zand",
              "id": "17.3.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Zand"
                }
              }
            }
          ]
        },
        {
          "name": "Campinggas",
          "id": "17.4",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Campinggas"
            }
          }
        },
        {
          "name": "Trekkershutten (op camping)",
          "id": "17.5",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Trekkershutten (op camping)"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "(Sta)caravans te huur (op camping)",
          "id": "17.6",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "(Sta)caravans te huur (op camping)"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Tentenverhuur (op camping)",
          "id": "17.7",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Tentenverhuur (op camping)"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Terrasvormig terrein",
          "id": "17.8",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Terrasvormig terrein"
            }
          }
        },
        {
          "name": "Camper servicestation",
          "id": "17.9",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Camper servicestation"
            }
          }
        }
      ]
    },
    {
      "name": "Faciliteiten (Jachthaven)",
      "id": "18",
      "datatype": "freetext",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Faciliteiten (Jachthaven)"
        }
      },
      "properties": [
        {
          "name": "Diepte haven",
          "id": "18.1",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Diepte haven"
            }
          },
          "unit": "meter"
        },
        {
          "name": "Diepte haveningang",
          "id": "18.2",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Diepte haveningang"
            }
          },
          "unit": "meter"
        },
        {
          "name": "Passanten ligplaats",
          "id": "18.3",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Passanten ligplaats"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Ligging jachthaven",
          "id": "18.31",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Ligging jachthaven"
            }
          },
          "options": [
            {
              "name": "Binnenwater",
              "id": "18.31.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Binnenwater"
                }
              }
            },
            {
              "name": "Ruim water",
              "id": "18.31.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ruim water"
                }
              }
            },
            {
              "name": "Zee",
              "id": "18.31.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Zee"
                }
              }
            },
            {
              "name": "Getijdehaven",
              "id": "18.31.4",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Getijdehaven"
                }
              }
            }
          ]
        },
        {
          "name": "Algemene voorzieningen",
          "id": "18.32",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Algemene voorzieningen"
            }
          },
          "options": [
            {
              "name": "Aanlegsteiger",
              "id": "18.32.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Aanlegsteiger"
                }
              }
            },
            {
              "name": "Stortplaats afgewerkte olie",
              "id": "18.32.10",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Stortplaats afgewerkte olie"
                }
              }
            },
            {
              "name": "Stortplaats chemisch toilet",
              "id": "18.32.11",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Stortplaats chemisch toilet"
                }
              }
            },
            {
              "name": "Stortplaats vuil water",
              "id": "18.32.12",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Stortplaats vuil water"
                }
              }
            },
            {
              "name": "Tankstation benzine",
              "id": "18.32.13",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Tankstation benzine"
                }
              }
            },
            {
              "name": "Tankstation diesel",
              "id": "18.32.14",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Tankstation diesel"
                }
              }
            },
            {
              "name": "Trailerhelling",
              "id": "18.32.16",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Trailerhelling"
                }
              }
            },
            {
              "name": "Verlichting haveningang",
              "id": "18.32.18",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Verlichting haveningang"
                }
              }
            },
            {
              "name": "Verlichting steigers",
              "id": "18.32.19",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Verlichting steigers"
                }
              }
            },
            {
              "name": "Camping / vakantiewoning",
              "id": "18.32.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Camping / vakantiewoning"
                }
              }
            },
            {
              "name": "Watertank",
              "id": "18.32.22",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Watertank"
                }
              }
            },
            {
              "name": "Electriciteitsaansluiting",
              "id": "18.32.4",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Electriciteitsaansluiting"
                }
              }
            },
            {
              "name": "Hefkraan",
              "id": "18.32.5",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Hefkraan"
                }
              }
            },
            {
              "name": "Levensmiddelenwinkel",
              "id": "18.32.6",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Levensmiddelenwinkel"
                }
              }
            },
            {
              "name": "Onderhoudswerf",
              "id": "18.32.7",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Onderhoudswerf"
                }
              }
            },
            {
              "name": "Scheepsbenodigdheden",
              "id": "18.32.8",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Scheepsbenodigdheden"
                }
              }
            },
            {
              "name": "Stalling voor jollen",
              "id": "18.32.9",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Stalling voor jollen"
                }
              }
            }
          ]
        }
      ]
    },
    {
      "name": "Faciliteiten (Hotel)",
      "id": "19",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Faciliteiten (Hotel)"
        }
      },
      "properties": [
        {
          "name": "Laundryservice",
          "id": "19.1",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Laundryservice"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Minibar op kamer",
          "id": "19.10",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Minibar op kamer"
            }
          }
        },
        {
          "name": "Roomservice",
          "id": "19.2",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Roomservice"
            }
          }
        },
        {
          "name": "Receptie 24 uur",
          "id": "19.3",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Receptie 24 uur"
            }
          }
        },
        {
          "name": "Pendeldienst van/naar vliegveld",
          "id": "19.4",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Pendeldienst van/naar vliegveld"
            }
          }
        },
        {
          "name": "Ticketservice",
          "id": "19.5",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Ticketservice"
            }
          }
        },
        {
          "name": "Bagage-opslag",
          "id": "19.6",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Bagage-opslag"
            }
          }
        },
        {
          "name": "Winkels in het hotel",
          "id": "19.7",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Winkels in het hotel"
            }
          }
        },
        {
          "name": "Anti-allergene kamers",
          "id": "19.8",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Anti-allergene kamers"
            }
          }
        },
        {
          "name": "Rookvrije kamers",
          "id": "19.9",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Rookvrije kamers"
            }
          }
        }
      ]
    },
    {
      "name": "Voorzieningen gehandicapten",
      "id": "2",
      "datatype": "freetext",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Voorzieningen gehandicapten"
        }
      },
      "properties": [
        {
          "name": "ITS geclassificeerd",
          "id": "2.1",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "ITS geclassificeerd"
            }
          }
        },
        {
          "name": "Blindengeleidehond toegestaan",
          "id": "2.10",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Blindengeleidehond toegestaan"
            }
          }
        },
        {
          "name": "Hulphond toegestaan",
          "id": "2.12",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Hulphond toegestaan"
            }
          }
        },
        {
          "name": "Toegankelijk voor gehandicapten",
          "id": "2.2",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Toegankelijk voor gehandicapten"
            }
          }
        },
        {
          "name": "Aangepast voor gehandicapten",
          "id": "2.3",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Aangepast voor gehandicapten"
            }
          }
        },
        {
          "name": "Voorzieningen voor blinden en slechtzienden",
          "id": "2.4",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Voorzieningen voor blinden en slechtzienden"
            }
          }
        },
        {
          "name": "rolstoel (op aanvraag) beschikbaar",
          "id": "2.5",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "rolstoel (op aanvraag) beschikbaar"
            }
          }
        },
        {
          "name": "gehandicaptenparkeerplaats",
          "id": "2.6",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "gehandicaptenparkeerplaats"
            }
          }
        },
        {
          "name": "rolstoeltoegankelijk toilet",
          "id": "2.7",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "rolstoeltoegankelijk toilet"
            }
          }
        },
        {
          "name": "Rook en Dier-vrij (ivm Cara)",
          "id": "2.8",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Rook en Dier-vrij (ivm Cara)"
            }
          },
          "options": [
            {
              "name": "onbekend",
              "id": "2.8.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "onbekend"
                }
              }
            },
            {
              "name": "niet geldig",
              "id": "2.8.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "niet geldig"
                }
              }
            },
            {
              "name": "niet van toepassing",
              "id": "2.8.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "niet van toepassing"
                }
              }
            },
            {
              "name": "nee",
              "id": "2.8.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "nee"
                }
              }
            },
            {
              "name": "gedeeltelijk",
              "id": "2.8.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "gedeeltelijk"
                }
              }
            },
            {
              "name": "ja",
              "id": "2.8.6",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "ja"
                }
              }
            }
          ]
        },
        {
          "name": "Toelichting toegankelijkheid",
          "id": "2.9",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Toelichting toegankelijkheid"
            }
          }
        }
      ]
    },
    {
      "name": "Vervoer",
      "id": "20",
      "datatype": "freetext",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Vervoer"
        }
      },
      "properties": [
        {
          "name": "Autovrij terrein",
          "id": "20.1",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Autovrij terrein"
            }
          }
        },
        {
          "name": "Bereikbaar per auto",
          "id": "20.17",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Bereikbaar per auto"
            }
          }
        },
        {
          "name": "Gratis parkeren",
          "id": "20.10",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Gratis parkeren"
            }
          }
        },
        {
          "name": "Parkeerinformatie",
          "id": "20.11",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Parkeerinformatie"
            }
          }
        },
        {
          "name": "Toelichting bereikbaarheid OV",
          "id": "20.12",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Toelichting bereikbaarheid OV"
            }
          }
        },
        {
          "name": "Toelichting parkeren",
          "id": "20.13",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Toelichting parkeren"
            }
          }
        },
        {
          "name": "Aantal parkeerplaatsen",
          "id": "20.14",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Aantal parkeerplaatsen"
            }
          },
          "unit": "parkeerplaatsen"
        },
        {
          "name": "Doorrijhoogte",
          "id": "20.15",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Doorrijhoogte"
            }
          },
          "unit": "meter"
        },
        {
          "name": "24/7 Open",
          "id": "20.16",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "24/7 Open"
            }
          }
        },
        {
          "name": "Eigen parkeerterrein",
          "id": "20.2",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Eigen parkeerterrein"
            }
          }
        },
        {
          "name": "Parkeergarage",
          "id": "20.3",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Parkeergarage"
            }
          }
        },
        {
          "name": "Betaald parkeren",
          "id": "20.4",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Betaald parkeren"
            }
          }
        },
        {
          "name": "Parkeren bij accommodatie",
          "id": "20.5",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Parkeren bij accommodatie"
            }
          }
        },
        {
          "name": "Parkeergelegenheid bussen",
          "id": "20.6",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Parkeergelegenheid bussen"
            }
          }
        },
        {
          "name": "Fietsenstalling",
          "id": "20.7",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Fietsenstalling"
            }
          },
          "options": [
            {
              "name": "Afsluitbaar",
              "id": "20.7.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Afsluitbaar"
                }
              }
            },
            {
              "name": "Bewaakt",
              "id": "20.7.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Bewaakt"
                }
              }
            },
            {
              "name": "Open",
              "id": "20.7.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Open"
                }
              }
            },
            {
              "name": "Bewaakt gratis",
              "id": "20.7.4",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Bewaakt gratis"
                }
              }
            }
          ]
        },
        {
          "name": "Met openbaar vervoer bereikbaar",
          "id": "20.8",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Met openbaar vervoer bereikbaar"
            }
          }
        },
        {
          "name": "Bewaakt parkeerterrein",
          "id": "20.9",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Bewaakt parkeerterrein"
            }
          }
        }
      ]
    },
    {
      "name": "Slapen",
      "id": "21",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Slapen"
        }
      },
      "properties": [
        {
          "name": "Eenpersoonskamers",
          "id": "21.1",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Eenpersoonskamers"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Dekens",
          "id": "21.10",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Dekens"
            }
          }
        },
        {
          "name": "Slaapbank in woonkamer",
          "id": "21.11",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Slaapbank in woonkamer"
            }
          }
        },
        {
          "name": "Stapelbedden",
          "id": "21.12",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Stapelbedden"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Niet gestapelde bedden",
          "id": "21.13",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Niet gestapelde bedden"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Eenpersoonsbedden",
          "id": "21.14",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Eenpersoonsbedden"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Tweepersoonsbedden",
          "id": "21.15",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Tweepersoonsbedden"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Tweepersoonskamers met aparte bedden",
          "id": "21.16",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Tweepersoonskamers met aparte bedden"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Tweepersoonskamers",
          "id": "21.2",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Tweepersoonskamers"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "3/4 Persoonskamers",
          "id": "21.3",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "3/4 Persoonskamers"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "6-persoonskamers",
          "id": "21.4",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "6-persoonskamers"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "8-persoonskamers",
          "id": "21.5",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "8-persoonskamers"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Slaapzalen",
          "id": "21.6",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Slaapzalen"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Extra lang bed (>2m)",
          "id": "21.7",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Extra lang bed (>2m)"
            }
          }
        },
        {
          "name": "Bedlinnen",
          "id": "21.8",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Bedlinnen"
            }
          },
          "options": [
            {
              "name": "Aanwezig",
              "id": "21.8.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Aanwezig"
                }
              }
            },
            {
              "name": "Verplicht te huur",
              "id": "21.8.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Verplicht te huur"
                }
              }
            },
            {
              "name": "Te huur",
              "id": "21.8.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Te huur"
                }
              }
            },
            {
              "name": "Niet beschikbaar",
              "id": "21.8.4",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Niet beschikbaar"
                }
              }
            }
          ]
        },
        {
          "name": "Dekbedden",
          "id": "21.9",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Dekbedden"
            }
          }
        }
      ]
    },
    {
      "name": "TV, audio, video & Internet",
      "id": "22",
      "datatype": "freetext",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "TV, audio, video & Internet"
        }
      },
      "properties": [
        {
          "name": "Betaal-televisie",
          "id": "22.1",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Betaal-televisie"
            }
          }
        },
        {
          "name": "PC in accommodatie",
          "id": "22.10",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "PC in accommodatie"
            }
          }
        },
        {
          "name": "Internetverbinding",
          "id": "22.11",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Internetverbinding"
            }
          },
          "options": [
            {
              "name": "tegen betaling",
              "id": "22.11.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "tegen betaling"
                }
              }
            },
            {
              "name": "Gemeenschappelijk",
              "id": "22.11.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Gemeenschappelijk"
                }
              }
            },
            {
              "name": "via telefoonlijn",
              "id": "22.11.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "via telefoonlijn"
                }
              }
            },
            {
              "name": "WiFi",
              "id": "22.11.4",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "WiFi"
                }
              }
            },
            {
              "name": "ADSL",
              "id": "22.11.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "ADSL"
                }
              }
            },
            {
              "name": "vrij beschikbaar",
              "id": "22.11.6",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "vrij beschikbaar"
                }
              }
            },
            {
              "name": "Wifi in kamer",
              "id": "22.11.7",
              "datatype": "choice",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Wifi in kamer"
                }
              }
            }
          ]
        },
        {
          "name": "Laptopaansluiting",
          "id": "22.12",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Laptopaansluiting"
            }
          }
        },
        {
          "name": "Overhead projector",
          "id": "22.13",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Overhead projector"
            }
          }
        },
        {
          "name": "Projectiescherm",
          "id": "22.14",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Projectiescherm"
            }
          }
        },
        {
          "name": "Beamer",
          "id": "22.15",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Beamer"
            }
          }
        },
        {
          "name": "Videospellen",
          "id": "22.16",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Videospellen"
            }
          }
        },
        {
          "name": "Geluidsinstallatie",
          "id": "22.17",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Geluidsinstallatie"
            }
          }
        },
        {
          "name": "Stereo-installatie",
          "id": "22.2",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Stereo-installatie"
            }
          }
        },
        {
          "name": "Televisie in accommodatie",
          "id": "22.3",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Televisie in accommodatie"
            }
          }
        },
        {
          "name": "TV-ruimte",
          "id": "22.4",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "TV-ruimte"
            }
          }
        },
        {
          "name": "Radio",
          "id": "22.5",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Radio"
            }
          }
        },
        {
          "name": "Satelliet ontvangst",
          "id": "22.6",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Satelliet ontvangst"
            }
          }
        },
        {
          "name": "Videospeler/recorder",
          "id": "22.7",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Videospeler/recorder"
            }
          }
        },
        {
          "name": "DVD-speler/recorder",
          "id": "22.8",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "DVD-speler/recorder"
            }
          }
        },
        {
          "name": "Home Cinema-systeem",
          "id": "22.9",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Home Cinema-systeem"
            }
          }
        }
      ]
    },
    {
      "name": "Sanitair",
      "id": "23",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Sanitair"
        }
      },
      "properties": [
        {
          "name": "Badlinnen",
          "id": "23.1",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Badlinnen"
            }
          },
          "options": [
            {
              "name": "Aanwezig",
              "id": "23.4.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Aanwezig"
                }
              }
            },
            {
              "name": "Te huur",
              "id": "23.4.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Te huur"
                }
              }
            },
            {
              "name": "Verplicht te huur",
              "id": "23.4.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Verplicht te huur"
                }
              }
            },
            {
              "name": "Niet beschikbaar",
              "id": "23.4.4",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Niet beschikbaar"
                }
              }
            }
          ]
        },
        {
          "name": "Toilet",
          "id": "23.10",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Toilet"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Wastafels koud water",
          "id": "23.11",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Wastafels koud water"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Wastafels warm water",
          "id": "23.12",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Wastafels warm water"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Bad voor algemeen gebruik",
          "id": "23.13",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Bad voor algemeen gebruik"
            }
          }
        },
        {
          "name": "Douche voor algemeen gebruik",
          "id": "23.14",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Douche voor algemeen gebruik"
            }
          }
        },
        {
          "name": "Gescheiden wasgelegenheid",
          "id": "23.15",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Gescheiden wasgelegenheid"
            }
          }
        },
        {
          "name": "Familie wascabine",
          "id": "23.16",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Familie wascabine"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Wascabines koud water",
          "id": "23.17",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Wascabines koud water"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Wascabines warm water",
          "id": "23.18",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Wascabines warm water"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Waskranen boven goot",
          "id": "23.19",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Waskranen boven goot"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Badkamer met douche",
          "id": "23.2",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Badkamer met douche"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Wasplaats baby's",
          "id": "23.20",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Wasplaats baby's"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Wastafels koud water collectief",
          "id": "23.21",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Wastafels koud water collectief"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Wastafels warm water collectief",
          "id": "23.22",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Wastafels warm water collectief"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Haardroger",
          "id": "23.23",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Haardroger"
            }
          }
        },
        {
          "name": "Scheerstopcontact",
          "id": "23.24",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Scheerstopcontact"
            }
          }
        },
        {
          "name": "Sauna",
          "id": "23.25",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Sauna"
            }
          }
        },
        {
          "name": "Whirlpool/Jacuzzi/Bubbelbad",
          "id": "23.26",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Whirlpool/Jacuzzi/Bubbelbad"
            }
          }
        },
        {
          "name": "Turks stoombad",
          "id": "23.27",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Turks stoombad"
            }
          }
        },
        {
          "name": "Verwarmd sanitair",
          "id": "23.28",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Verwarmd sanitair"
            }
          }
        },
        {
          "name": "Zonnebank",
          "id": "23.29",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Zonnebank"
            }
          }
        },
        {
          "name": "Badkamer met ligbad",
          "id": "23.3",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Badkamer met ligbad"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Privé badkamer",
          "id": "23.30",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Privé badkamer"
            }
          }
        },
        {
          "name": "Badkamer met toilet",
          "id": "23.4",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Badkamer met toilet"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Gedeelde badkamer",
          "id": "23.5",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Gedeelde badkamer"
            }
          }
        },
        {
          "name": "Ligbad",
          "id": "23.6",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Ligbad"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Zitbad",
          "id": "23.7",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Zitbad"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Douche (koud water)",
          "id": "23.8",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Douche (koud water)"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Douche (warm water)",
          "id": "23.9",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Douche (warm water)"
            }
          },
          "unit": "aantal"
        }
      ]
    },
    {
      "name": "Textiel- en vaatwas",
      "id": "24",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Textiel- en vaatwas"
        }
      },
      "properties": [
        {
          "name": "Wasserij",
          "id": "24.1",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Wasserij"
            }
          }
        },
        {
          "name": "Strijkplank en ijzer",
          "id": "24.10",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Strijkplank en ijzer"
            }
          }
        },
        {
          "name": "Strijkservice",
          "id": "24.11",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Strijkservice"
            }
          }
        },
        {
          "name": "afwasbakken koud",
          "id": "24.12",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "afwasbakken koud"
            }
          }
        },
        {
          "name": "afwasbakken warm",
          "id": "24.13",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "afwasbakken warm"
            }
          }
        },
        {
          "name": "textielwasbakken koud",
          "id": "24.14",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "textielwasbakken koud"
            }
          }
        },
        {
          "name": "textielwasbakken warm",
          "id": "24.15",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "textielwasbakken warm"
            }
          }
        },
        {
          "name": "Stomerij",
          "id": "24.2",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Stomerij"
            }
          }
        },
        {
          "name": "Wasplaats textiel",
          "id": "24.3",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Wasplaats textiel"
            }
          }
        },
        {
          "name": "Was en drooggelegenheid",
          "id": "24.4",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Was en drooggelegenheid"
            }
          }
        },
        {
          "name": "Droogtrommel",
          "id": "24.5",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Droogtrommel"
            }
          }
        },
        {
          "name": "Wasmachine",
          "id": "24.6",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Wasmachine"
            }
          },
          "options": [
            {
              "name": "Beschikbaar",
              "id": "24.6.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Beschikbaar"
                }
              }
            },
            {
              "name": "Gemeenschappelijk",
              "id": "24.6.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Gemeenschappelijk"
                }
              }
            },
            {
              "name": "Tegen betaling",
              "id": "24.6.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Tegen betaling"
                }
              }
            }
          ]
        },
        {
          "name": "Wasserette",
          "id": "24.7",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Wasserette"
            }
          }
        },
        {
          "name": "Vaatwasmachine",
          "id": "24.8",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Vaatwasmachine"
            }
          }
        },
        {
          "name": "Vaatwasplaats",
          "id": "24.9",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Vaatwasplaats"
            }
          }
        }
      ]
    },
    {
      "name": "Keuken en koken",
      "id": "25",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Keuken en koken"
        }
      },
      "properties": [
        {
          "name": "Afzuigkap",
          "id": "25.1",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Afzuigkap"
            }
          }
        },
        {
          "name": "Koffiezetapparaat",
          "id": "25.10",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Koffiezetapparaat"
            }
          }
        },
        {
          "name": "Koffie/Theevoorzieningen",
          "id": "25.11",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Koffie/Theevoorzieningen"
            }
          }
        },
        {
          "name": "Kookgelegenheid voor groepen",
          "id": "25.12",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kookgelegenheid voor groepen"
            }
          }
        },
        {
          "name": "Kookgelegenheid voor individuen",
          "id": "25.13",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kookgelegenheid voor individuen"
            }
          }
        },
        {
          "name": "Kookgerei aanwezig",
          "id": "25.14",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kookgerei aanwezig"
            }
          }
        },
        {
          "name": "Magnetron",
          "id": "25.15",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Magnetron"
            }
          }
        },
        {
          "name": "Stofzuiger",
          "id": "25.16",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Stofzuiger"
            }
          }
        },
        {
          "name": "Oven",
          "id": "25.17",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Oven"
            }
          }
        },
        {
          "name": "Type keuken",
          "id": "25.2",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Type keuken"
            }
          },
          "options": [
            {
              "name": "Aparte keuken",
              "id": "25.2.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Aparte keuken"
                }
              }
            },
            {
              "name": "Open keuken",
              "id": "25.2.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Open keuken"
                }
              }
            },
            {
              "name": "Kook of keukenhoek",
              "id": "25.2.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Kook of keukenhoek"
                }
              }
            },
            {
              "name": "Kitchenette",
              "id": "25.2.4",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Kitchenette"
                }
              }
            }
          ]
        },
        {
          "name": "Bestek aanwezig",
          "id": "25.3",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Bestek aanwezig"
            }
          }
        },
        {
          "name": "Bar met tap-installatie",
          "id": "25.4",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Bar met tap-installatie"
            }
          }
        },
        {
          "name": "Invriezen koelelementen",
          "id": "25.5",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Invriezen koelelementen"
            }
          }
        },
        {
          "name": "Vriezer of vriesvak",
          "id": "25.6",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Vriezer of vriesvak"
            }
          }
        },
        {
          "name": "Gas verkrijgbaar",
          "id": "25.7",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Gas verkrijgbaar"
            }
          }
        },
        {
          "name": "Keukenlinnen",
          "id": "25.8",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Keukenlinnen"
            }
          },
          "options": [
            {
              "name": "Aanwezig",
              "id": "25.8.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Aanwezig"
                }
              }
            },
            {
              "name": "Verplicht te huur",
              "id": "25.8.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Verplicht te huur"
                }
              }
            },
            {
              "name": "Te huur",
              "id": "25.8.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Te huur"
                }
              }
            },
            {
              "name": "Niet beschikbaar",
              "id": "25.8.4",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Niet beschikbaar"
                }
              }
            }
          ]
        },
        {
          "name": "Koelkast",
          "id": "25.9",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Koelkast"
            }
          },
          "options": [
            {
              "name": "Aanwezig",
              "id": "25.9.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Aanwezig"
                }
              }
            },
            {
              "name": "Verplicht te huur",
              "id": "25.9.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Verplicht te huur"
                }
              }
            },
            {
              "name": "Te huur",
              "id": "25.9.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Te huur"
                }
              }
            },
            {
              "name": "Niet beschikbaar",
              "id": "25.9.4",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Niet beschikbaar"
                }
              }
            }
          ]
        }
      ]
    },
    {
      "name": "Recreatie",
      "id": "26",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Recreatie"
        }
      },
      "properties": [
        {
          "name": "Bar",
          "id": "26.1",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Bar"
            }
          },
          "options": [
            {
              "name": "Ja",
              "id": "26.1.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ja"
                }
              }
            },
            {
              "name": "Nee",
              "id": "26.1.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Nee"
                }
              }
            },
            {
              "name": "Alleen voor gasten",
              "id": "26.1.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Alleen voor gasten"
                }
              }
            }
          ]
        },
        {
          "name": "Fitness",
          "id": "26.10",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Fitness"
            }
          },
          "options": [
            {
              "name": "Nee",
              "id": "26.10.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Nee"
                }
              }
            },
            {
              "name": "Alleen voor gasten",
              "id": "26.10.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Alleen voor gasten"
                }
              }
            },
            {
              "name": "Ja",
              "id": "26.8.10",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ja"
                }
              }
            }
          ]
        },
        {
          "name": "Gelegenheid tot paardrijden",
          "id": "26.11",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Gelegenheid tot paardrijden"
            }
          },
          "options": [
            {
              "name": "Ja",
              "id": "26.11.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ja"
                }
              }
            },
            {
              "name": "Nee",
              "id": "26.11.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Nee"
                }
              }
            },
            {
              "name": "Alleen voor gasten",
              "id": "26.11.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Alleen voor gasten"
                }
              }
            }
          ]
        },
        {
          "name": "Golfbaan",
          "id": "26.12",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Golfbaan"
            }
          },
          "options": [
            {
              "name": "Ja",
              "id": "26.12.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ja"
                }
              }
            },
            {
              "name": "Nee",
              "id": "26.12.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Nee"
                }
              }
            },
            {
              "name": "Alleen voor gasten",
              "id": "26.12.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Alleen voor gasten"
                }
              }
            }
          ]
        },
        {
          "name": "Jeu de boules/Petanque",
          "id": "26.13",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Jeu de boules/Petanque"
            }
          }
        },
        {
          "name": "Kegelen",
          "id": "26.14",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kegelen"
            }
          },
          "options": [
            {
              "name": "Ja",
              "id": "26.14.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ja"
                }
              }
            },
            {
              "name": "Nee",
              "id": "26.14.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Nee"
                }
              }
            },
            {
              "name": "Alleen voor gasten",
              "id": "26.14.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Alleen voor gasten"
                }
              }
            }
          ]
        },
        {
          "name": "Klimmuur/klimhal",
          "id": "26.15",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Klimmuur/klimhal"
            }
          },
          "options": [
            {
              "name": "Ja",
              "id": "26.15.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ja"
                }
              }
            },
            {
              "name": "Nee",
              "id": "26.15.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Nee"
                }
              }
            },
            {
              "name": "Alleen voor gasten",
              "id": "26.15.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Alleen voor gasten"
                }
              }
            }
          ]
        },
        {
          "name": "Kuurmogelijkheden",
          "id": "26.16",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kuurmogelijkheden"
            }
          }
        },
        {
          "name": "Manege",
          "id": "26.17",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Manege"
            }
          }
        },
        {
          "name": "Midgetgolf",
          "id": "26.18",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Midgetgolf"
            }
          },
          "options": [
            {
              "name": "Ja",
              "id": "26.18.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ja"
                }
              }
            },
            {
              "name": "Nee",
              "id": "26.18.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Nee"
                }
              }
            },
            {
              "name": "Alleen voor gasten",
              "id": "26.18.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Alleen voor gasten"
                }
              }
            }
          ]
        },
        {
          "name": "Mountainbike-verhuur",
          "id": "26.19",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Mountainbike-verhuur"
            }
          }
        },
        {
          "name": "Bibliotheek",
          "id": "26.2",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Bibliotheek"
            }
          }
        },
        {
          "name": "Natuurwandelingen (gids)",
          "id": "26.20",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Natuurwandelingen (gids)"
            }
          }
        },
        {
          "name": "Pooltafel",
          "id": "26.21",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Pooltafel"
            }
          },
          "options": [
            {
              "name": "Ja",
              "id": "26.21.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ja"
                }
              }
            },
            {
              "name": "Nee",
              "id": "26.21.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Nee"
                }
              }
            },
            {
              "name": "Alleen voor gasten",
              "id": "26.21.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Alleen voor gasten"
                }
              }
            }
          ]
        },
        {
          "name": "Recreatiezaal",
          "id": "26.22",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Recreatiezaal"
            }
          }
        },
        {
          "name": "Ruiterkampen",
          "id": "26.23",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Ruiterkampen"
            }
          }
        },
        {
          "name": "Sauna",
          "id": "26.24",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Sauna"
            }
          },
          "options": [
            {
              "name": "Ja",
              "id": "26.24.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ja"
                }
              }
            },
            {
              "name": "Nee",
              "id": "26.24.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Nee"
                }
              }
            },
            {
              "name": "Alleen voor gasten",
              "id": "26.24.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Alleen voor gasten"
                }
              }
            }
          ]
        },
        {
          "name": "Solarium",
          "id": "26.25",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Solarium"
            }
          },
          "options": [
            {
              "name": "Ja",
              "id": "26.25.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ja"
                }
              }
            },
            {
              "name": "Nee",
              "id": "26.25.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Nee"
                }
              }
            },
            {
              "name": "Alleen voor gasten",
              "id": "26.25.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Alleen voor gasten"
                }
              }
            }
          ]
        },
        {
          "name": "Sportattributen te leen",
          "id": "26.26",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Sportattributen te leen"
            }
          }
        },
        {
          "name": "Sporthal",
          "id": "26.27",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Sporthal"
            }
          }
        },
        {
          "name": "Sportveld",
          "id": "26.28",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Sportveld"
            }
          }
        },
        {
          "name": "Squash",
          "id": "26.29",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Squash"
            }
          },
          "options": [
            {
              "name": "Ja",
              "id": "26.29.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ja"
                }
              }
            },
            {
              "name": "Nee",
              "id": "26.29.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Nee"
                }
              }
            },
            {
              "name": "Alleen voor gasten",
              "id": "26.29.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Alleen voor gasten"
                }
              }
            }
          ]
        },
        {
          "name": "Biljart",
          "id": "26.3",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Biljart"
            }
          },
          "options": [
            {
              "name": "Ja",
              "id": "26.3.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ja"
                }
              }
            },
            {
              "name": "Nee",
              "id": "26.3.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Nee"
                }
              }
            },
            {
              "name": "Alleen voor gasten",
              "id": "26.3.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Alleen voor gasten"
                }
              }
            }
          ]
        },
        {
          "name": "Tafeltennis",
          "id": "26.30",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Tafeltennis"
            }
          }
        },
        {
          "name": "Tafelvoetbal",
          "id": "26.31",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Tafelvoetbal"
            }
          }
        },
        {
          "name": "Tennisbaan",
          "id": "26.32",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Tennisbaan"
            }
          },
          "options": [
            {
              "name": "Ja",
              "id": "26.32.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ja"
                }
              }
            },
            {
              "name": "Nee",
              "id": "26.32.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Nee"
                }
              }
            },
            {
              "name": "Alleen voor gasten",
              "id": "26.32.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Alleen voor gasten"
                }
              }
            }
          ]
        },
        {
          "name": "Tennishal",
          "id": "26.33",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Tennishal"
            }
          },
          "options": [
            {
              "name": "Ja",
              "id": "26.33.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ja"
                }
              }
            },
            {
              "name": "Nee",
              "id": "26.33.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Nee"
                }
              }
            },
            {
              "name": "Alleen voor gasten",
              "id": "26.33.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Alleen voor gasten"
                }
              }
            }
          ]
        },
        {
          "name": "Tennisles",
          "id": "26.34",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Tennisles"
            }
          },
          "options": [
            {
              "name": "Ja",
              "id": "26.34.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ja"
                }
              }
            },
            {
              "name": "Nee",
              "id": "26.34.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Nee"
                }
              }
            },
            {
              "name": "Alleen voor gasten",
              "id": "26.34.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Alleen voor gasten"
                }
              }
            }
          ]
        },
        {
          "name": "Trampoline",
          "id": "26.35",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Trampoline"
            }
          }
        },
        {
          "name": "Trimbaan",
          "id": "26.36",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Trimbaan"
            }
          }
        },
        {
          "name": "Fietsen inclusief",
          "id": "26.37",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Fietsen inclusief"
            }
          }
        },
        {
          "name": "Verhuur fietsen",
          "id": "26.38",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Verhuur fietsen"
            }
          }
        },
        {
          "name": "Wandelroutes",
          "id": "26.39",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Wandelroutes"
            }
          }
        },
        {
          "name": "Bowling",
          "id": "26.4",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Bowling"
            }
          },
          "options": [
            {
              "name": "Ja",
              "id": "26.4.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ja"
                }
              }
            },
            {
              "name": "Nee",
              "id": "26.4.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Nee"
                }
              }
            },
            {
              "name": "Alleen voor gasten",
              "id": "26.4.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Alleen voor gasten"
                }
              }
            }
          ]
        },
        {
          "name": "Wellnesscentrum",
          "id": "26.40",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Wellnesscentrum"
            }
          }
        },
        {
          "name": "Langlaufen",
          "id": "26.41",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Langlaufen"
            }
          }
        },
        {
          "name": "Overige sporten",
          "id": "26.42",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Overige sporten"
            }
          },
          "options": [
            {
              "name": "(motor)vliegen",
              "id": "26.42.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "(motor)vliegen"
                }
              }
            },
            {
              "name": "boogschieten",
              "id": "26.42.10",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "boogschieten"
                }
              }
            },
            {
              "name": "bowlen",
              "id": "26.42.11",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "bowlen"
                }
              }
            },
            {
              "name": "bugraces",
              "id": "26.42.12",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "bugraces"
                }
              }
            },
            {
              "name": "bungy-jumpen",
              "id": "26.42.13",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "bungy-jumpen"
                }
              }
            },
            {
              "name": "curling",
              "id": "26.42.14",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "curling"
                }
              }
            },
            {
              "name": "darts",
              "id": "26.42.15",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "darts"
                }
              }
            },
            {
              "name": "deltavliegen",
              "id": "26.42.16",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "deltavliegen"
                }
              }
            },
            {
              "name": "duiken",
              "id": "26.42.17",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "duiken"
                }
              }
            },
            {
              "name": "fietscross",
              "id": "26.42.18",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "fietscross"
                }
              }
            },
            {
              "name": "jeu de boules",
              "id": "26.42.19",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "jeu de boules"
                }
              }
            },
            {
              "name": "abseilen",
              "id": "26.42.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "abseilen"
                }
              }
            },
            {
              "name": "karten",
              "id": "26.42.20",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "karten"
                }
              }
            },
            {
              "name": "kleiduivenschieten",
              "id": "26.42.21",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "kleiduivenschieten"
                }
              }
            },
            {
              "name": "klimmen (toren)",
              "id": "26.42.22",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "klimmen (toren)"
                }
              }
            },
            {
              "name": "klimmen (wand)",
              "id": "26.42.23",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "klimmen (wand)"
                }
              }
            },
            {
              "name": "klootschieten",
              "id": "26.42.24",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "klootschieten"
                }
              }
            },
            {
              "name": "kunstschaatsen",
              "id": "26.42.25",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "kunstschaatsen"
                }
              }
            },
            {
              "name": "langlaufen",
              "id": "26.42.26",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "langlaufen"
                }
              }
            },
            {
              "name": "lasergame",
              "id": "26.42.27",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "lasergame"
                }
              }
            },
            {
              "name": "midgetgolf",
              "id": "26.42.28",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "midgetgolf"
                }
              }
            },
            {
              "name": "motorsport",
              "id": "26.42.29",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "motorsport"
                }
              }
            },
            {
              "name": "autosport",
              "id": "26.42.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "autosport"
                }
              }
            },
            {
              "name": "oudhollandse spelen",
              "id": "26.42.30",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "oudhollandse spelen"
                }
              }
            },
            {
              "name": "paardrijden",
              "id": "26.42.31",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "paardrijden"
                }
              }
            },
            {
              "name": "paintball",
              "id": "26.42.32",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "paintball"
                }
              }
            },
            {
              "name": "parachutespringen",
              "id": "26.42.33",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "parachutespringen"
                }
              }
            },
            {
              "name": "paragliding/-sailing",
              "id": "26.42.34",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "paragliding/-sailing"
                }
              }
            },
            {
              "name": "poolbiljart",
              "id": "26.42.35",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "poolbiljart"
                }
              }
            },
            {
              "name": "rolschaatsen",
              "id": "26.42.36",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "rolschaatsen"
                }
              }
            },
            {
              "name": "schaatsen",
              "id": "26.42.37",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "schaatsen"
                }
              }
            },
            {
              "name": "skaten/skeeleren",
              "id": "26.42.38",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "skaten/skeeleren"
                }
              }
            },
            {
              "name": "skelteren",
              "id": "26.42.39",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "skelteren"
                }
              }
            },
            {
              "name": "badminton",
              "id": "26.42.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "badminton"
                }
              }
            },
            {
              "name": "skiën",
              "id": "26.42.40",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "skiën"
                }
              }
            },
            {
              "name": "snooker",
              "id": "26.42.41",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "snooker"
                }
              }
            },
            {
              "name": "snowboarden",
              "id": "26.42.42",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "snowboarden"
                }
              }
            },
            {
              "name": "sportschool",
              "id": "26.42.43",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "sportschool"
                }
              }
            },
            {
              "name": "squash",
              "id": "26.42.44",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "squash"
                }
              }
            },
            {
              "name": "surfen",
              "id": "26.42.45",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "surfen"
                }
              }
            },
            {
              "name": "tafeltennis",
              "id": "26.42.46",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "tafeltennis"
                }
              }
            },
            {
              "name": "tennis",
              "id": "26.42.47",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "tennis"
                }
              }
            },
            {
              "name": "tokkelbaan",
              "id": "26.42.48",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "tokkelbaan"
                }
              }
            },
            {
              "name": "trimbaan",
              "id": "26.42.49",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "trimbaan"
                }
              }
            },
            {
              "name": "ballonvaren",
              "id": "26.42.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "ballonvaren"
                }
              }
            },
            {
              "name": "voetbal",
              "id": "26.42.50",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "voetbal"
                }
              }
            },
            {
              "name": "waterskien (boot)",
              "id": "26.42.51",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "waterskien (boot)"
                }
              }
            },
            {
              "name": "waterskien (kabelbaan)",
              "id": "26.42.52",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "waterskien (kabelbaan)"
                }
              }
            },
            {
              "name": "wielerbaan",
              "id": "26.42.53",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "wielerbaan"
                }
              }
            },
            {
              "name": "zeilen",
              "id": "26.42.54",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "zeilen"
                }
              }
            },
            {
              "name": "zweefvliegen",
              "id": "26.42.55",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "zweefvliegen"
                }
              }
            },
            {
              "name": "zwemmen",
              "id": "26.42.56",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "zwemmen"
                }
              }
            },
            {
              "name": "basketbal",
              "id": "26.42.6",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "basketbal"
                }
              }
            },
            {
              "name": "beachvolleybal",
              "id": "26.42.7",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "beachvolleybal"
                }
              }
            },
            {
              "name": "beugelen",
              "id": "26.42.8",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "beugelen"
                }
              }
            },
            {
              "name": "biljarten",
              "id": "26.42.9",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "biljarten"
                }
              }
            }
          ]
        },
        {
          "name": "Excursieprogramma",
          "id": "26.43",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Excursieprogramma"
            }
          }
        },
        {
          "name": "Animatieprogramma",
          "id": "26.44",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Animatieprogramma"
            }
          }
        },
        {
          "name": "Karaoke",
          "id": "26.45",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Karaoke"
            }
          }
        },
        {
          "name": "Live muziek",
          "id": "26.46",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Live muziek"
            }
          }
        },
        {
          "name": "DJ",
          "id": "26.47",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "DJ"
            }
          }
        },
        {
          "name": "Darts",
          "id": "26.48",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Darts"
            }
          }
        },
        {
          "name": "Café",
          "id": "26.5",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Café"
            }
          },
          "options": [
            {
              "name": "Ja",
              "id": "26.5.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ja"
                }
              }
            },
            {
              "name": "Nee",
              "id": "26.5.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Nee"
                }
              }
            },
            {
              "name": "Alleen voor gasten",
              "id": "26.5.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Alleen voor gasten"
                }
              }
            }
          ]
        },
        {
          "name": "Pedicure",
          "id": "26.50",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Pedicure"
            }
          },
          "options": [
            {
              "name": "Ja",
              "id": "26.50.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ja"
                }
              }
            },
            {
              "name": "Nee",
              "id": "26.50.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Nee"
                }
              }
            },
            {
              "name": "Alleen voor gasten",
              "id": "26.50.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Alleen voor gasten"
                }
              }
            }
          ]
        },
        {
          "name": "Massage",
          "id": "26.51",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Massage"
            }
          },
          "options": [
            {
              "name": "Ja",
              "id": "26.51.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ja"
                }
              }
            },
            {
              "name": "Nee",
              "id": "26.51.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Nee"
                }
              }
            },
            {
              "name": "Alleen voor gasten",
              "id": "26.51.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Alleen voor gasten"
                }
              }
            }
          ]
        },
        {
          "name": "Manicure",
          "id": "26.52",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Manicure"
            }
          },
          "options": [
            {
              "name": "Ja",
              "id": "26.52.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ja"
                }
              }
            },
            {
              "name": "Nee",
              "id": "26.52.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Nee"
                }
              }
            },
            {
              "name": "Alleen voor gasten",
              "id": "26.52.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Alleen voor gasten"
                }
              }
            }
          ]
        },
        {
          "name": "Casino",
          "id": "26.6",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Casino"
            }
          },
          "options": [
            {
              "name": "Ja",
              "id": "26.6.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ja"
                }
              }
            },
            {
              "name": "Nee",
              "id": "26.6.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Nee"
                }
              }
            },
            {
              "name": "Alleen voor gasten",
              "id": "26.6.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Alleen voor gasten"
                }
              }
            }
          ]
        },
        {
          "name": "Danszaal",
          "id": "26.7",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Danszaal"
            }
          },
          "options": [
            {
              "name": "Ja",
              "id": "26.7.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ja"
                }
              }
            },
            {
              "name": "Nee",
              "id": "26.7.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Nee"
                }
              }
            },
            {
              "name": "Alleen voor gasten",
              "id": "26.7.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Alleen voor gasten"
                }
              }
            }
          ]
        },
        {
          "name": "Disco",
          "id": "26.8",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Disco"
            }
          },
          "options": [
            {
              "name": "Ja",
              "id": "26.8.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ja"
                }
              }
            },
            {
              "name": "Nee",
              "id": "26.8.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Nee"
                }
              }
            },
            {
              "name": "Alleen voor gasten",
              "id": "26.8.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Alleen voor gasten"
                }
              }
            }
          ]
        },
        {
          "name": "Fietsroutes",
          "id": "26.9",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Fietsroutes"
            }
          }
        }
      ]
    },
    {
      "name": "Recreatie (Watersport)",
      "id": "27",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Recreatie (Watersport)"
        }
      },
      "properties": [
        {
          "name": "Visgelegenheid",
          "id": "27.1",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Visgelegenheid"
            }
          }
        },
        {
          "name": "Windsurfles",
          "id": "27.10",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Windsurfles"
            }
          }
        },
        {
          "name": "Windsurfplankverhuur",
          "id": "27.11",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Windsurfplankverhuur"
            }
          }
        },
        {
          "name": "Overige watersporten",
          "id": "27.12",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Overige watersporten"
            }
          },
          "options": [
            {
              "name": "Banaanvaren",
              "id": "27.12.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Banaanvaren"
                }
              }
            },
            {
              "name": "Wakeboarden",
              "id": "27.12.10",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Wakeboarden"
                }
              }
            },
            {
              "name": "Bootverhuur",
              "id": "27.12.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Bootverhuur"
                }
              }
            },
            {
              "name": "Catamaran",
              "id": "27.12.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Catamaran"
                }
              }
            },
            {
              "name": "Chartervaart",
              "id": "27.12.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Chartervaart"
                }
              }
            },
            {
              "name": "Kitesurfen",
              "id": "27.12.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Kitesurfen"
                }
              }
            },
            {
              "name": "Opleiding vaarbewijzen",
              "id": "27.12.6",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Opleiding vaarbewijzen"
                }
              }
            },
            {
              "name": "Parasailing",
              "id": "27.12.7",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Parasailing"
                }
              }
            },
            {
              "name": "Snelle watersport",
              "id": "27.12.8",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Snelle watersport"
                }
              }
            },
            {
              "name": "Snorkelen",
              "id": "27.12.9",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Snorkelen"
                }
              }
            }
          ]
        },
        {
          "name": "Waterski",
          "id": "27.2",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Waterski"
            }
          }
        },
        {
          "name": "Kanoën",
          "id": "27.3",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kanoën"
            }
          }
        },
        {
          "name": "Roeien",
          "id": "27.4",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Roeien"
            }
          }
        },
        {
          "name": "Zeilen",
          "id": "27.5",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Zeilen"
            }
          }
        },
        {
          "name": "Surfen",
          "id": "27.6",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Surfen"
            }
          }
        },
        {
          "name": "Duiken",
          "id": "27.7",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Duiken"
            }
          }
        },
        {
          "name": "Waterscooter",
          "id": "27.8",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Waterscooter"
            }
          }
        },
        {
          "name": "Recreatieplas",
          "id": "27.9",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Recreatieplas"
            }
          }
        }
      ]
    },
    {
      "name": "Watersport - strand",
      "id": "28",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Watersport - strand"
        }
      },
      "properties": [
        {
          "name": "(zee)surfen toegestaan",
          "id": "28.1",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "(zee)surfen toegestaan"
            }
          }
        },
        {
          "name": "jetskiën toegestaan",
          "id": "28.2",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "jetskiën toegestaan"
            }
          }
        },
        {
          "name": "naturisme toegestaan",
          "id": "28.3",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "naturisme toegestaan"
            }
          }
        },
        {
          "name": "paardrijden toegestaan",
          "id": "28.4",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "paardrijden toegestaan"
            }
          }
        },
        {
          "name": "vliegeren toegestaan",
          "id": "28.5",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "vliegeren toegestaan"
            }
          }
        },
        {
          "name": "Zwemtoezicht",
          "id": "28.6",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Zwemtoezicht"
            }
          }
        }
      ]
    },
    {
      "name": "Voor kinderen",
      "id": "29",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Voor kinderen"
        }
      },
      "properties": [
        {
          "name": "kinderboerderij",
          "id": "29.1",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "kinderboerderij"
            }
          }
        },
        {
          "name": "Kinderopvang",
          "id": "29.10",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kinderopvang"
            }
          }
        },
        {
          "name": "Commode",
          "id": "29.11",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Commode"
            }
          }
        },
        {
          "name": "Kinderbed",
          "id": "29.12",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kinderbed"
            }
          }
        },
        {
          "name": "Kinderbox",
          "id": "29.13",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kinderbox"
            }
          }
        },
        {
          "name": "Kinderstoel",
          "id": "29.14",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kinderstoel"
            }
          }
        },
        {
          "name": "Kindermeubelen te huur",
          "id": "29.15",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kindermeubelen te huur"
            }
          }
        },
        {
          "name": "Kindveilige stopcontacten",
          "id": "29.16",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kindveilige stopcontacten"
            }
          }
        },
        {
          "name": "Kinderen alleen o.l.v. volwassene",
          "id": "29.17",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kinderen alleen o.l.v. volwassene"
            }
          }
        },
        {
          "name": "Kinderbad",
          "id": "29.18",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kinderbad"
            }
          }
        },
        {
          "name": "Kinderspeelhoek",
          "id": "29.2",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kinderspeelhoek"
            }
          }
        },
        {
          "name": "Meehelpen op de boerderij",
          "id": "29.3",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Meehelpen op de boerderij"
            }
          }
        },
        {
          "name": "Recreatiebegeleiding",
          "id": "29.4",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Recreatiebegeleiding"
            }
          }
        },
        {
          "name": "Speel-o-theek",
          "id": "29.5",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Speel-o-theek"
            }
          }
        },
        {
          "name": "Speeltuin",
          "id": "29.6",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Speeltuin"
            }
          }
        },
        {
          "name": "Speelveld",
          "id": "29.7",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Speelveld"
            }
          }
        },
        {
          "name": "Overdekte speelruimte",
          "id": "29.8",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Overdekte speelruimte"
            }
          }
        },
        {
          "name": "Babysitservice",
          "id": "29.9",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Babysitservice"
            }
          }
        }
      ]
    },
    {
      "name": "Lidmaatschappen",
      "id": "3",
      "datatype": "freetext",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Lidmaatschappen"
        }
      },
      "properties": [
        {
          "name": "Recronlid",
          "id": "3.1",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Recronlid"
            }
          }
        },
        {
          "name": "Lid Nivon",
          "id": "3.10",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Lid Nivon"
            }
          }
        },
        {
          "name": "Lid Stichting Natuurkampeerterreinen",
          "id": "3.11",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Lid Stichting Natuurkampeerterreinen"
            }
          }
        },
        {
          "name": "Lid NOC*NSF",
          "id": "3.12",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Lid NOC*NSF"
            }
          }
        },
        {
          "name": "Fietscafé / Fietsers welkom",
          "id": "3.13",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Fietscafé / Fietsers welkom"
            }
          }
        },
        {
          "name": "ANWB Erkend",
          "id": "3.2",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "ANWB Erkend"
            }
          }
        },
        {
          "name": "ECEAT Erkend",
          "id": "3.3",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "ECEAT Erkend"
            }
          }
        },
        {
          "name": "Koninklijke Horeca Nederland erkend",
          "id": "3.4",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Koninklijke Horeca Nederland erkend"
            }
          }
        },
        {
          "name": "Lid HISWA",
          "id": "3.5",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Lid HISWA"
            }
          }
        },
        {
          "name": "Lid VVV",
          "id": "3.6",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Lid VVV"
            }
          }
        },
        {
          "name": "Lid SVR",
          "id": "3.7",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Lid SVR"
            }
          }
        },
        {
          "name": "Lid VeKaBo",
          "id": "3.8",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Lid VeKaBo"
            }
          }
        },
        {
          "name": "Lid NFN",
          "id": "3.9",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Lid NFN"
            }
          }
        }
      ]
    },
    {
      "name": "Zwemmen",
      "id": "30",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Zwemmen"
        }
      },
      "properties": [
        {
          "name": "Zwembad in/op accommodatie",
          "id": "30.1",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Zwembad in/op accommodatie"
            }
          },
          "options": [
            {
              "name": "Natuurwater",
              "id": "30.1.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Natuurwater"
                }
              }
            },
            {
              "name": "Buiten",
              "id": "30.1.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Buiten"
                }
              }
            },
            {
              "name": "Verwarmd",
              "id": "30.1.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Verwarmd"
                }
              }
            },
            {
              "name": "Overdekt",
              "id": "30.1.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Overdekt"
                }
              }
            },
            {
              "name": "Subtropisch",
              "id": "30.1.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Subtropisch"
                }
              }
            }
          ]
        },
        {
          "name": "Gratis toegang tot (openbaar) zwembad buiten accom",
          "id": "30.2",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Gratis toegang tot (openbaar) zwembad buiten accom"
            }
          },
          "options": [
            {
              "name": "Natuurwater",
              "id": "30.2.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Natuurwater"
                }
              }
            },
            {
              "name": "Buiten",
              "id": "30.2.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Buiten"
                }
              }
            },
            {
              "name": "Verwarmd",
              "id": "30.2.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Verwarmd"
                }
              }
            },
            {
              "name": "Overdekt",
              "id": "30.2.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Overdekt"
                }
              }
            },
            {
              "name": "Subtropisch",
              "id": "30.2.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Subtropisch"
                }
              }
            }
          ]
        },
        {
          "name": "Privé zwembad",
          "id": "30.3",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Privé zwembad"
            }
          },
          "options": [
            {
              "name": "Natuurwater",
              "id": "30.3.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Natuurwater"
                }
              }
            },
            {
              "name": "Buiten",
              "id": "30.3.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Buiten"
                }
              }
            },
            {
              "name": "Verwarmd",
              "id": "30.3.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Verwarmd"
                }
              }
            },
            {
              "name": "Overdekt",
              "id": "30.3.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Overdekt"
                }
              }
            },
            {
              "name": "Subtropisch",
              "id": "30.3.5",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Subtropisch"
                }
              }
            }
          ]
        },
        {
          "name": "Afstand tot zwembad",
          "id": "30.4",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Afstand tot zwembad"
            }
          },
          "unit": "km"
        },
        {
          "name": "Zwembad",
          "id": "30.5",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Zwembad"
            }
          },
          "options": [
            {
              "name": "Natuurwater",
              "id": "30.5.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Natuurwater"
                }
              }
            },
            {
              "name": "Buiten",
              "id": "30.5.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Buiten"
                }
              }
            },
            {
              "name": "Verwarmd",
              "id": "30.5.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Verwarmd"
                }
              }
            },
            {
              "name": "Overdekt",
              "id": "30.5.4",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Overdekt"
                }
              }
            },
            {
              "name": "Subtropisch",
              "id": "30.5.5",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Subtropisch"
                }
              }
            },
            {
              "name": "Zwemgelegenheid",
              "id": "30.5.6",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Zwemgelegenheid"
                }
              }
            }
          ]
        },
        {
          "name": "Kleuterbad",
          "id": "30.6",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kleuterbad"
            }
          }
        },
        {
          "name": "Waterglijbaan",
          "id": "30.7",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Waterglijbaan"
            }
          }
        }
      ]
    },
    {
      "name": "Huisdieren",
      "id": "31",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Huisdieren"
        }
      },
      "properties": [
        {
          "name": "Huisdieren",
          "id": "31.1",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Huisdieren"
            }
          },
          "options": [
            {
              "name": "Huisdieren toegestaan",
              "id": "31.1.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Huisdieren toegestaan"
                }
              }
            },
            {
              "name": "Huisdieren alleen na overleg",
              "id": "31.1.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Huisdieren alleen na overleg"
                }
              }
            },
            {
              "name": "Huisdieren niet toegestaan",
              "id": "31.1.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Huisdieren niet toegestaan"
                }
              }
            },
            {
              "name": "Huisdieren alleen aan de lijn",
              "id": "31.1.4",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Huisdieren alleen aan de lijn"
                }
              }
            }
          ]
        },
        {
          "name": "Hondenopvang",
          "id": "31.2",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Hondenopvang"
            }
          }
        },
        {
          "name": "Toelichting honden",
          "id": "31.3",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Toelichting honden"
            }
          }
        }
      ]
    },
    {
      "name": "Verzorging",
      "id": "32",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Verzorging"
        }
      },
      "properties": [
        {
          "name": "Verzorging",
          "id": "32.1",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Verzorging"
            }
          },
          "options": [
            {
              "name": "Zelfverzorging",
              "id": "32.1.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Zelfverzorging"
                }
              }
            },
            {
              "name": "Logies",
              "id": "32.1.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Logies"
                }
              },
              "properties": [
                {
                  "name": "Hotel",
                  "id": "11.2.15",
                  "propertytranslations": {
                    "Propertytranslation": {
                      "lang": "nl",
                      "text": "Hotel"
                    }
                  }
                }
              ]
            },
            {
              "name": "Logies en ontbijt",
              "id": "32.1.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Logies en ontbijt"
                }
              }
            },
            {
              "name": "Logies, ontbijt en lunch",
              "id": "32.1.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Logies, ontbijt en lunch"
                }
              }
            },
            {
              "name": "Halfpension",
              "id": "32.1.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Halfpension"
                }
              }
            },
            {
              "name": "Volpension",
              "id": "32.1.6",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Volpension"
                }
              }
            },
            {
              "name": "All Inclusive",
              "id": "32.1.7",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "All Inclusive"
                }
              }
            }
          ]
        },
        {
          "name": "Ontbijt",
          "id": "32.2",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Ontbijt"
            }
          },
          "options": [
            {
              "name": "Ontbijtbuffet",
              "id": "32.2.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ontbijtbuffet"
                }
              }
            },
            {
              "name": "Ontbijt op bed",
              "id": "32.2.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ontbijt op bed"
                }
              }
            },
            {
              "name": "Continentaal ontbijt",
              "id": "32.2.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Continentaal ontbijt"
                }
              }
            },
            {
              "name": "English breakfast",
              "id": "32.2.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "English breakfast"
                }
              }
            }
          ]
        },
        {
          "name": "Lunch",
          "id": "32.3",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Lunch"
            }
          },
          "options": [
            {
              "name": "Lunchpakket",
              "id": "32.3.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Lunchpakket"
                }
              }
            },
            {
              "name": "Lunchbuffet",
              "id": "32.3.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Lunchbuffet"
                }
              }
            },
            {
              "name": "Verzorgde lunch",
              "id": "32.3.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Verzorgde lunch"
                }
              }
            }
          ]
        },
        {
          "name": "Diner",
          "id": "32.4",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Diner"
            }
          },
          "options": [
            {
              "name": "Dinerbuffet",
              "id": "32.4.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Dinerbuffet"
                }
              }
            },
            {
              "name": "A la carte",
              "id": "32.4.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "A la carte"
                }
              }
            }
          ]
        },
        {
          "name": "Maaltijdservice",
          "id": "32.5",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Maaltijdservice"
            }
          }
        },
        {
          "name": "Brunch",
          "id": "32.6",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Brunch"
            }
          }
        },
        {
          "name": "High Teas",
          "id": "32.7",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "High Teas"
            }
          }
        },
        {
          "name": "Ontbijtlocatie",
          "id": "32.8",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Ontbijtlocatie"
            }
          }
        },
        {
          "name": "Borrellocatie",
          "id": "32.9",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Borrellocatie"
            }
          }
        }
      ]
    },
    {
      "name": "Hoteltijden",
      "id": "33",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Hoteltijden"
        }
      },
      "properties": [
        {
          "name": "Check-in vanaf",
          "id": "33.1",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Check-in vanaf"
            }
          }
        },
        {
          "name": "Check-in tot",
          "id": "33.2",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Check-in tot"
            }
          }
        },
        {
          "name": "Check-out vanaf",
          "id": "33.3",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Check-out vanaf"
            }
          }
        },
        {
          "name": "Check-out tot",
          "id": "33.4",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Check-out tot"
            }
          }
        }
      ]
    },
    {
      "name": "Eetgelegenheid",
      "id": "34",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Eetgelegenheid"
        }
      },
      "properties": [
        {
          "name": "Type eetgelegenheid",
          "id": "34.1",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Type eetgelegenheid"
            }
          },
          "options": [
            {
              "name": "afhaalrestaurant",
              "id": "34.1.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "afhaalrestaurant"
                }
              }
            },
            {
              "name": "eetgelegenheid",
              "id": "34.1.10",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "eetgelegenheid"
                }
              }
            },
            {
              "name": "fast food",
              "id": "34.1.11",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "fast food"
                }
              }
            },
            {
              "name": "grand-café",
              "id": "34.1.12",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "grand-café"
                }
              }
            },
            {
              "name": "Kantine",
              "id": "34.1.13",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Kantine"
                }
              }
            },
            {
              "name": "Kantine met maaltijden",
              "id": "34.1.14",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Kantine met maaltijden"
                }
              }
            },
            {
              "name": "Koffiehoek",
              "id": "34.1.15",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Koffiehoek"
                }
              }
            },
            {
              "name": "lunchroom",
              "id": "34.1.16",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "lunchroom"
                }
              }
            },
            {
              "name": "nachtrestaurant",
              "id": "34.1.17",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "nachtrestaurant"
                }
              }
            },
            {
              "name": "onderdeel hotel",
              "id": "34.1.18",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "onderdeel hotel"
                }
              }
            },
            {
              "name": "Party centrum",
              "id": "34.1.19",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Party centrum"
                }
              }
            },
            {
              "name": "bistro",
              "id": "34.1.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "bistro"
                }
              }
            },
            {
              "name": "pizzeria",
              "id": "34.1.20",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "pizzeria"
                }
              }
            },
            {
              "name": "restaurant",
              "id": "34.1.21",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "restaurant"
                }
              }
            },
            {
              "name": "Snackbar",
              "id": "34.1.22",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Snackbar"
                }
              }
            },
            {
              "name": "snackcounter",
              "id": "34.1.23",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "snackcounter"
                }
              }
            },
            {
              "name": "traiteur",
              "id": "34.1.24",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "traiteur"
                }
              }
            },
            {
              "name": "wegrestaurant",
              "id": "34.1.25",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "wegrestaurant"
                }
              }
            },
            {
              "name": "wegverzorgend horecabedrijf",
              "id": "34.1.26",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "wegverzorgend horecabedrijf"
                }
              }
            },
            {
              "name": "thuisbezorgen",
              "id": "34.1.27",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "thuisbezorgen"
                }
              }
            },
            {
              "name": "brasserie",
              "id": "34.1.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "brasserie"
                }
              }
            },
            {
              "name": "broodjeszaak",
              "id": "34.1.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "broodjeszaak"
                }
              }
            },
            {
              "name": "café-restaurant",
              "id": "34.1.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "café-restaurant"
                }
              }
            },
            {
              "name": "catering",
              "id": "34.1.6",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "catering"
                }
              }
            },
            {
              "name": "croissanterie",
              "id": "34.1.7",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "croissanterie"
                }
              }
            },
            {
              "name": "drive-in restaurant",
              "id": "34.1.8",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "drive-in restaurant"
                }
              }
            },
            {
              "name": "eetcafé",
              "id": "34.1.9",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "eetcafé"
                }
              }
            }
          ]
        },
        {
          "name": "Ontbijt",
          "id": "34.12",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Ontbijt"
            }
          }
        },
        {
          "name": "Lunch",
          "id": "34.13",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Lunch"
            }
          }
        },
        {
          "name": "Diner",
          "id": "34.14",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Diner"
            }
          }
        },
        {
          "name": "Privé ruimte beschikbaar",
          "id": "34.15",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Privé ruimte beschikbaar"
            }
          }
        },
        {
          "name": "Nationaliteit keuken",
          "id": "34.2",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Nationaliteit keuken"
            }
          },
          "options": [
            {
              "name": "Afrikaans",
              "id": "34.2.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Afrikaans"
                }
              }
            },
            {
              "name": "Chileens",
              "id": "34.2.10",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Chileens"
                }
              }
            },
            {
              "name": "Chinees",
              "id": "34.2.11",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Chinees"
                }
              }
            },
            {
              "name": "Duits",
              "id": "34.2.12",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Duits"
                }
              }
            },
            {
              "name": "Egyptisch",
              "id": "34.2.13",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Egyptisch"
                }
              }
            },
            {
              "name": "Engels",
              "id": "34.2.14",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Engels"
                }
              }
            },
            {
              "name": "Ethiopisch",
              "id": "34.2.15",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ethiopisch"
                }
              }
            },
            {
              "name": "Filippijns",
              "id": "34.2.16",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Filippijns"
                }
              }
            },
            {
              "name": "Frans",
              "id": "34.2.17",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Frans"
                }
              }
            },
            {
              "name": "Grieks",
              "id": "34.2.18",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Grieks"
                }
              }
            },
            {
              "name": "Hongaars",
              "id": "34.2.19",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Hongaars"
                }
              }
            },
            {
              "name": "Amerikaans",
              "id": "34.2.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Amerikaans"
                }
              }
            },
            {
              "name": "Iers",
              "id": "34.2.20",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Iers"
                }
              }
            },
            {
              "name": "Indiaas",
              "id": "34.2.21",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Indiaas"
                }
              }
            },
            {
              "name": "Indisch/Indonesisch",
              "id": "34.2.22",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Indisch/Indonesisch"
                }
              }
            },
            {
              "name": "Israëlisch",
              "id": "34.2.23",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Israëlisch"
                }
              }
            },
            {
              "name": "Italiaans",
              "id": "34.2.24",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Italiaans"
                }
              }
            },
            {
              "name": "Japans",
              "id": "34.2.25",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Japans"
                }
              }
            },
            {
              "name": "Joods",
              "id": "34.2.26",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Joods"
                }
              }
            },
            {
              "name": "Koreaans",
              "id": "34.2.27",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Koreaans"
                }
              }
            },
            {
              "name": "Kroatisch",
              "id": "34.2.28",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Kroatisch"
                }
              }
            },
            {
              "name": "Libanees",
              "id": "34.2.29",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Libanees"
                }
              }
            },
            {
              "name": "Argentijns",
              "id": "34.2.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Argentijns"
                }
              }
            },
            {
              "name": "Marokkaans",
              "id": "34.2.30",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Marokkaans"
                }
              }
            },
            {
              "name": "Mediterraans",
              "id": "34.2.31",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Mediterraans"
                }
              }
            },
            {
              "name": "Mexicaans",
              "id": "34.2.32",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Mexicaans"
                }
              }
            },
            {
              "name": "Nederlands",
              "id": "34.2.33",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Nederlands"
                }
              }
            },
            {
              "name": "Oost-Europees",
              "id": "34.2.34",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Oost-Europees"
                }
              }
            },
            {
              "name": "Oostenrijks",
              "id": "34.2.35",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Oostenrijks"
                }
              }
            },
            {
              "name": "Oriëntaals",
              "id": "34.2.36",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Oriëntaals"
                }
              }
            },
            {
              "name": "Perzisch",
              "id": "34.2.37",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Perzisch"
                }
              }
            },
            {
              "name": "Portugees",
              "id": "34.2.38",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Portugees"
                }
              }
            },
            {
              "name": "Russisch",
              "id": "34.2.39",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Russisch"
                }
              }
            },
            {
              "name": "Authentiek Chinees",
              "id": "34.2.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Authentiek Chinees"
                }
              }
            },
            {
              "name": "Scandinavisch",
              "id": "34.2.40",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Scandinavisch"
                }
              }
            },
            {
              "name": "Sloveens",
              "id": "34.2.41",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Sloveens"
                }
              }
            },
            {
              "name": "Spaans",
              "id": "34.2.42",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Spaans"
                }
              }
            },
            {
              "name": "Surinaams",
              "id": "34.2.43",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Surinaams"
                }
              }
            },
            {
              "name": "Szechuan",
              "id": "34.2.44",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Szechuan"
                }
              }
            },
            {
              "name": "Thais",
              "id": "34.2.45",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Thais"
                }
              }
            },
            {
              "name": "Tunesisch",
              "id": "34.2.46",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Tunesisch"
                }
              }
            },
            {
              "name": "Turks",
              "id": "34.2.47",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Turks"
                }
              }
            },
            {
              "name": "Vlaams",
              "id": "34.2.48",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Vlaams"
                }
              }
            },
            {
              "name": "Vietnamees",
              "id": "34.2.49",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Vietnamees"
                }
              }
            },
            {
              "name": "Balkan",
              "id": "34.2.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Balkan"
                }
              }
            },
            {
              "name": "Zwitsers",
              "id": "34.2.50",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Zwitsers"
                }
              }
            },
            {
              "name": "Internationaal",
              "id": "34.2.51",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Internationaal"
                }
              }
            },
            {
              "name": "Braziliaans",
              "id": "34.2.6",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Braziliaans"
                }
              }
            },
            {
              "name": "Cajun",
              "id": "34.2.7",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Cajun"
                }
              }
            },
            {
              "name": "Cantonees",
              "id": "34.2.8",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Cantonees"
                }
              }
            },
            {
              "name": "Caraïbisch",
              "id": "34.2.9",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Caraïbisch"
                }
              }
            }
          ]
        },
        {
          "name": "Specialiteit keuken",
          "id": "34.3",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Specialiteit keuken"
            }
          },
          "options": [
            {
              "name": "bagels/donuts",
              "id": "34.3.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "bagels/donuts"
                }
              }
            },
            {
              "name": "kip",
              "id": "34.3.10",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "kip"
                }
              }
            },
            {
              "name": "koosjer",
              "id": "34.3.11",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "koosjer"
                }
              }
            },
            {
              "name": "macrobiotisch",
              "id": "34.3.12",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "macrobiotisch"
                }
              }
            },
            {
              "name": "pannenkoeken",
              "id": "34.3.13",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "pannenkoeken"
                }
              }
            },
            {
              "name": "pizza",
              "id": "34.3.14",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "pizza"
                }
              }
            },
            {
              "name": "poffertjes",
              "id": "34.3.15",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "poffertjes"
                }
              }
            },
            {
              "name": "seizoenspecialiteiten",
              "id": "34.3.16",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "seizoenspecialiteiten"
                }
              }
            },
            {
              "name": "soep",
              "id": "34.3.17",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "soep"
                }
              }
            },
            {
              "name": "steengrillen",
              "id": "34.3.18",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "steengrillen"
                }
              }
            },
            {
              "name": "streekgerechten",
              "id": "34.3.19",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "streekgerechten"
                }
              }
            },
            {
              "name": "barbecue",
              "id": "34.3.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "barbecue"
                }
              }
            },
            {
              "name": "sushi",
              "id": "34.3.20",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "sushi"
                }
              }
            },
            {
              "name": "tapas",
              "id": "34.3.21",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "tapas"
                }
              }
            },
            {
              "name": "vegetarisch",
              "id": "34.3.22",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "vegetarisch"
                }
              }
            },
            {
              "name": "vis",
              "id": "34.3.23",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "vis"
                }
              }
            },
            {
              "name": "vlees/steaks",
              "id": "34.3.24",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "vlees/steaks"
                }
              }
            },
            {
              "name": "wild",
              "id": "34.3.25",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "wild"
                }
              }
            },
            {
              "name": "wok",
              "id": "34.3.26",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "wok"
                }
              }
            },
            {
              "name": "wraps",
              "id": "34.3.27",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "wraps"
                }
              }
            },
            {
              "name": "biologisch",
              "id": "34.3.28",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "biologisch"
                }
              }
            },
            {
              "name": "pasta",
              "id": "34.3.29",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "pasta"
                }
              }
            },
            {
              "name": "bourgondisch",
              "id": "34.3.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "bourgondisch"
                }
              }
            },
            {
              "name": "(Vlaamse) frites",
              "id": "34.3.30",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "(Vlaamse) frites"
                }
              }
            },
            {
              "name": "IJs",
              "id": "34.3.31",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "IJs"
                }
              }
            },
            {
              "name": "Koffiespecialiteiten",
              "id": "34.3.32",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Koffiespecialiteiten"
                }
              }
            },
            {
              "name": "Meeneemmaaltijden",
              "id": "34.3.33",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Meeneemmaaltijden"
                }
              }
            },
            {
              "name": "Shoarma",
              "id": "34.3.34",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Shoarma"
                }
              }
            },
            {
              "name": "fondue",
              "id": "34.3.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "fondue"
                }
              }
            },
            {
              "name": "gevarieerd",
              "id": "34.3.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "gevarieerd"
                }
              }
            },
            {
              "name": "gourmet",
              "id": "34.3.6",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "gourmet"
                }
              }
            },
            {
              "name": "grill",
              "id": "34.3.7",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "grill"
                }
              }
            },
            {
              "name": "halal",
              "id": "34.3.8",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "halal"
                }
              }
            },
            {
              "name": "hamburgers",
              "id": "34.3.9",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "hamburgers"
                }
              }
            }
          ]
        },
        {
          "name": "Afhalen mogelijk",
          "id": "34.4",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Afhalen mogelijk"
            }
          }
        },
        {
          "name": "Bezorging mogelijk",
          "id": "34.5",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Bezorging mogelijk"
            }
          }
        },
        {
          "name": "Afhuurmogelijkheid",
          "id": "34.6",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Afhuurmogelijkheid"
            }
          }
        },
        {
          "name": "Dieetwensen",
          "id": "34.7",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Dieetwensen"
            }
          },
          "properties": [
            {
              "name": "Glutenvrij",
              "id": "34.7.1",
              "datatype": "yes",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Glutenvrij"
                }
              }
            },
            {
              "name": "Suikervrij",
              "id": "34.7.2",
              "datatype": "yes",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Suikervrij"
                }
              }
            }
          ]
        }
      ]
    },
    {
      "name": "Zien, Doen & Beleven",
      "id": "35",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Zien, Doen & Beleven"
        }
      },
      "properties": [
        {
          "name": "Educatieve dienst",
          "id": "35.1",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Educatieve dienst"
            }
          }
        },
        {
          "name": "Type monument",
          "id": "35.10",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Type monument"
            }
          },
          "options": [
            {
              "name": "(oorlogs)monument",
              "id": "35.10.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "(oorlogs)monument"
                }
              }
            },
            {
              "name": "dijk",
              "id": "35.10.10",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "dijk"
                }
              }
            },
            {
              "name": "doorlaatwerk",
              "id": "35.10.11",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "doorlaatwerk"
                }
              }
            },
            {
              "name": "erebegraafplaats",
              "id": "35.10.12",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "erebegraafplaats"
                }
              }
            },
            {
              "name": "gemaal",
              "id": "35.10.13",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "gemaal"
                }
              }
            },
            {
              "name": "hunebed",
              "id": "35.10.14",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "hunebed"
                }
              }
            },
            {
              "name": "kerk",
              "id": "35.10.15",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "kerk"
                }
              }
            },
            {
              "name": "kustbescherming",
              "id": "35.10.16",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "kustbescherming"
                }
              }
            },
            {
              "name": "landaanwinning",
              "id": "35.10.17",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "landaanwinning"
                }
              }
            },
            {
              "name": "meetstation",
              "id": "35.10.18",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "meetstation"
                }
              }
            },
            {
              "name": "militair gebouw",
              "id": "35.10.19",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "militair gebouw"
                }
              }
            },
            {
              "name": "(stand)beeld",
              "id": "35.10.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "(stand)beeld"
                }
              }
            },
            {
              "name": "moskee",
              "id": "35.10.20",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "moskee"
                }
              }
            },
            {
              "name": "natuur(gebied)",
              "id": "35.10.21",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "natuur(gebied)"
                }
              }
            },
            {
              "name": "oorlogsgraf",
              "id": "35.10.22",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "oorlogsgraf"
                }
              }
            },
            {
              "name": "overheidsgebouw",
              "id": "35.10.23",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "overheidsgebouw"
                }
              }
            },
            {
              "name": "rivier of kanaal",
              "id": "35.10.24",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "rivier of kanaal"
                }
              }
            },
            {
              "name": "scheepvaart",
              "id": "35.10.25",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "scheepvaart"
                }
              }
            },
            {
              "name": "sculptuur",
              "id": "35.10.26",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "sculptuur"
                }
              }
            },
            {
              "name": "sluis",
              "id": "35.10.27",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "sluis"
                }
              }
            },
            {
              "name": "sport, recreatie, horeca, ed.",
              "id": "35.10.28",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "sport, recreatie, horeca, ed."
                }
              }
            },
            {
              "name": "spuiwerk",
              "id": "35.10.29",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "spuiwerk"
                }
              }
            },
            {
              "name": "(veer)dam",
              "id": "35.10.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "(veer)dam"
                }
              }
            },
            {
              "name": "stuw",
              "id": "35.10.30",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "stuw"
                }
              }
            },
            {
              "name": "synagoge",
              "id": "35.10.31",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "synagoge"
                }
              }
            },
            {
              "name": "tunnel",
              "id": "35.10.32",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "tunnel"
                }
              }
            },
            {
              "name": "verdedigingswerk",
              "id": "35.10.33",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "verdedigingswerk"
                }
              }
            },
            {
              "name": "vistrap",
              "id": "35.10.34",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "vistrap"
                }
              }
            },
            {
              "name": "waterkering",
              "id": "35.10.35",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "waterkering"
                }
              }
            },
            {
              "name": "weg- en waterbouwkundige bouwwerken",
              "id": "35.10.36",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "weg- en waterbouwkundige bouwwerken"
                }
              }
            },
            {
              "name": "wildviaduct",
              "id": "35.10.37",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "wildviaduct"
                }
              }
            },
            {
              "name": "woningen",
              "id": "35.10.38",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "woningen"
                }
              }
            },
            {
              "name": "zeehaven",
              "id": "35.10.39",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "zeehaven"
                }
              }
            },
            {
              "name": "aquaduct",
              "id": "35.10.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "aquaduct"
                }
              }
            },
            {
              "name": "bedrijfsgebouw",
              "id": "35.10.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "bedrijfsgebouw"
                }
              }
            },
            {
              "name": "binnenhaven",
              "id": "35.10.6",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "binnenhaven"
                }
              }
            },
            {
              "name": "boerderij",
              "id": "35.10.7",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "boerderij"
                }
              }
            },
            {
              "name": "brug",
              "id": "35.10.8",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "brug"
                }
              }
            },
            {
              "name": "cultureel gebouw",
              "id": "35.10.9",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "cultureel gebouw"
                }
              }
            }
          ]
        },
        {
          "name": "Collectietype 1",
          "id": "35.11",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Collectietype 1"
            }
          },
          "options": [
            {
              "name": "geologie en delfstoffen",
              "id": "35.11.19",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "geologie en delfstoffen"
                }
              }
            },
            {
              "name": "gereedschap en meetinstrumenten",
              "id": "35.11.20",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "gereedschap en meetinstrumenten"
                }
              }
            },
            {
              "name": "schilderkunst (1600 - 1850)",
              "id": "35.11.52",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "schilderkunst (1600 - 1850)"
                }
              }
            }
          ]
        },
        {
          "name": "Rondleidingen",
          "id": "35.2",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Rondleidingen"
            }
          }
        },
        {
          "name": "Demonstraties",
          "id": "35.3",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Demonstraties"
            }
          }
        },
        {
          "name": "Kaartverkoop",
          "id": "35.4",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kaartverkoop"
            }
          },
          "options": [
            {
              "name": "Geen kaartverkoop",
              "id": "35.4.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Geen kaartverkoop"
                }
              }
            },
            {
              "name": "Kaartverkoop via accommodatie zelf",
              "id": "35.4.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Kaartverkoop via accommodatie zelf"
                }
              }
            },
            {
              "name": "Kaartverkoop via TicketService",
              "id": "35.4.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Kaartverkoop via TicketService"
                }
              }
            },
            {
              "name": "Kaartverkoop via VVV",
              "id": "35.4.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Kaartverkoop via VVV"
                }
              }
            },
            {
              "name": "Kaartverkoop voor aanvang",
              "id": "35.4.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Kaartverkoop voor aanvang"
                }
              }
            },
            {
              "name": "Online kaartverkoop",
              "id": "35.4.6",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Online kaartverkoop"
                }
              }
            }
          ]
        },
        {
          "name": "Productverkoop/museumshop",
          "id": "35.5",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Productverkoop/museumshop"
            }
          }
        },
        {
          "name": "Type bedrijf",
          "id": "35.6",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Type bedrijf"
            }
          },
          "options": [
            {
              "name": "aardewerk/keramiek",
              "id": "35.6.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "aardewerk/keramiek"
                }
              }
            },
            {
              "name": "glas/glaskunst",
              "id": "35.6.10",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "glas/glaskunst"
                }
              }
            },
            {
              "name": "groente en fruit",
              "id": "35.6.11",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "groente en fruit"
                }
              }
            },
            {
              "name": "houtbewerking",
              "id": "35.6.12",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "houtbewerking"
                }
              }
            },
            {
              "name": "kaarsen",
              "id": "35.6.13",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "kaarsen"
                }
              }
            },
            {
              "name": "kaas/zuivel",
              "id": "35.6.14",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "kaas/zuivel"
                }
              }
            },
            {
              "name": "klompen",
              "id": "35.6.15",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "klompen"
                }
              }
            },
            {
              "name": "kwekerij",
              "id": "35.6.16",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "kwekerij"
                }
              }
            },
            {
              "name": "leerbewerking",
              "id": "35.6.17",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "leerbewerking"
                }
              }
            },
            {
              "name": "media",
              "id": "35.6.18",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "media"
                }
              }
            },
            {
              "name": "meetinstrumenten",
              "id": "35.6.19",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "meetinstrumenten"
                }
              }
            },
            {
              "name": "akkerbouw",
              "id": "35.6.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "akkerbouw"
                }
              }
            },
            {
              "name": "natuur en milieu",
              "id": "35.6.20",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "natuur en milieu"
                }
              }
            },
            {
              "name": "papier",
              "id": "35.6.21",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "papier"
                }
              }
            },
            {
              "name": "rotan-/rietprodukten",
              "id": "35.6.22",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "rotan-/rietprodukten"
                }
              }
            },
            {
              "name": "scheepsbouw",
              "id": "35.6.23",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "scheepsbouw"
                }
              }
            },
            {
              "name": "stuw-/sluiscomplex",
              "id": "35.6.24",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "stuw-/sluiscomplex"
                }
              }
            },
            {
              "name": "textiel",
              "id": "35.6.25",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "textiel"
                }
              }
            },
            {
              "name": "transport",
              "id": "35.6.27",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "transport"
                }
              }
            },
            {
              "name": "uurwerken",
              "id": "35.6.28",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "uurwerken"
                }
              }
            },
            {
              "name": "varia",
              "id": "35.6.29",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "varia"
                }
              }
            },
            {
              "name": "alcoholische dranken",
              "id": "35.6.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "alcoholische dranken"
                }
              }
            },
            {
              "name": "veeteelt",
              "id": "35.6.30",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "veeteelt"
                }
              }
            },
            {
              "name": "veiling/visafslag",
              "id": "35.6.31",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "veiling/visafslag"
                }
              }
            },
            {
              "name": "vis/visprodukten",
              "id": "35.6.32",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "vis/visprodukten"
                }
              }
            },
            {
              "name": "voedingsmiddelen",
              "id": "35.6.33",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "voedingsmiddelen"
                }
              }
            },
            {
              "name": "waterzuivering",
              "id": "35.6.34",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "waterzuivering"
                }
              }
            },
            {
              "name": "bijenteelt",
              "id": "35.6.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "bijenteelt"
                }
              }
            },
            {
              "name": "bloemen en planten",
              "id": "35.6.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "bloemen en planten"
                }
              }
            },
            {
              "name": "bomen en heesters",
              "id": "35.6.6",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "bomen en heesters"
                }
              }
            },
            {
              "name": "diamant",
              "id": "35.6.7",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "diamant"
                }
              }
            },
            {
              "name": "edelmetaal/metaal",
              "id": "35.6.8",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "edelmetaal/metaal"
                }
              }
            },
            {
              "name": "gemengd bedrijf",
              "id": "35.6.9",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "gemengd bedrijf"
                }
              }
            }
          ]
        },
        {
          "name": "theater",
          "id": "35.6.26",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "theater"
            }
          },
          "properties": [
            {
              "name": "Opera",
              "id": "37.1.12",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Opera"
                }
              }
            },
            {
              "name": "Dans/Ballet",
              "id": "37.1.13",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Dans/Ballet"
                }
              }
            },
            {
              "name": "Cabaret",
              "id": "37.1.7",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Cabaret"
                }
              }
            },
            {
              "name": "Musical/Show",
              "id": "37.1.8",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Musical/Show"
                }
              }
            }
          ]
        },
        {
          "name": "Fotograferen toegestaan",
          "id": "35.7",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Fotograferen toegestaan"
            }
          },
          "options": [
            {
              "name": "Fotograferen zonder flits toegestaan",
              "id": "35.7.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Fotograferen zonder flits toegestaan"
                }
              }
            },
            {
              "name": "Fotograferen met flits toegestaan",
              "id": "35.7.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Fotograferen met flits toegestaan"
                }
              }
            },
            {
              "name": "Fotograferen niet toegestaan",
              "id": "35.7.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Fotograferen niet toegestaan"
                }
              }
            }
          ]
        },
        {
          "name": "Excursie",
          "id": "35.8",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Excursie"
            }
          },
          "options": [
            {
              "name": "ballonvaren",
              "id": "35.8.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "ballonvaren"
                }
              }
            },
            {
              "name": "rondleiding",
              "id": "35.8.10",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "rondleiding"
                }
              }
            },
            {
              "name": "rondvaartboot",
              "id": "35.8.11",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "rondvaartboot"
                }
              }
            },
            {
              "name": "rondvlucht",
              "id": "35.8.12",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "rondvlucht"
                }
              }
            },
            {
              "name": "stadsbustour",
              "id": "35.8.13",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "stadsbustour"
                }
              }
            },
            {
              "name": "stadswandeling",
              "id": "35.8.14",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "stadswandeling"
                }
              }
            },
            {
              "name": "overige excursie",
              "id": "35.8.15",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "overige excursie"
                }
              }
            },
            {
              "name": "boottocht",
              "id": "35.8.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "boottocht"
                }
              }
            },
            {
              "name": "bustocht",
              "id": "35.8.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "bustocht"
                }
              }
            },
            {
              "name": "fietstocht",
              "id": "35.8.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "fietstocht"
                }
              }
            },
            {
              "name": "hist. trein/tram",
              "id": "35.8.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "hist. trein/tram"
                }
              }
            },
            {
              "name": "huifkartocht",
              "id": "35.8.6",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "huifkartocht"
                }
              }
            },
            {
              "name": "koetstocht",
              "id": "35.8.7",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "koetstocht"
                }
              }
            },
            {
              "name": "natuurwandeling",
              "id": "35.8.8",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "natuurwandeling"
                }
              }
            },
            {
              "name": "paardentramtocht",
              "id": "35.8.9",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "paardentramtocht"
                }
              }
            }
          ]
        },
        {
          "name": "Type museum",
          "id": "35.9",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Type museum"
            }
          },
          "options": [
            {
              "name": "aardewerk en keramiek",
              "id": "35.9.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "aardewerk en keramiek"
                }
              }
            },
            {
              "name": "art & design",
              "id": "35.9.80",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Art & Design"
                }
              }
            },
            {
              "name": "astronomie",
              "id": "35.9.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "astronomie"
                }
              }
            },
            {
              "name": "communicatie",
              "id": "35.9.10",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "communicatie"
                }
              }
            },
            {
              "name": "fietsen en bromfietsen",
              "id": "35.9.11",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "fietsen en bromfietsen"
                }
              }
            },
            {
              "name": "film en video",
              "id": "35.9.12",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "film en video"
                }
              }
            },
            {
              "name": "flora en fauna",
              "id": "35.9.13",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "flora en fauna"
                }
              }
            },
            {
              "name": "folklore",
              "id": "35.9.14",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "folklore"
                }
              }
            },
            {
              "name": "fotografie",
              "id": "35.9.15",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "fotografie"
                }
              }
            },
            {
              "name": "geneeskunde en anatomie",
              "id": "35.9.16",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "geneeskunde en anatomie"
                }
              }
            },
            {
              "name": "genotmiddelen",
              "id": "35.9.17",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "genotmiddelen"
                }
              }
            },
            {
              "name": "geografie en topografie",
              "id": "35.9.18",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "geografie en topografie"
                }
              }
            },
            {
              "name": "geologie en delfstoffen",
              "id": "35.9.19",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "geologie en delfstoffen"
                }
              }
            },
            {
              "name": "ambacht",
              "id": "35.9.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "ambacht"
                }
              }
            },
            {
              "name": "gereedschap en meetinstrumenten",
              "id": "35.9.20",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "gereedschap en meetinstrumenten"
                }
              }
            },
            {
              "name": "glaskunst",
              "id": "35.9.21",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "glaskunst"
                }
              }
            },
            {
              "name": "grafische kunst",
              "id": "35.9.22",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "grafische kunst"
                }
              }
            },
            {
              "name": "industriële ontwikkeling",
              "id": "35.9.23",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "industriële ontwikkeling"
                }
              }
            },
            {
              "name": "jacht",
              "id": "35.9.24",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "jacht"
                }
              }
            },
            {
              "name": "kachels en haarden",
              "id": "35.9.25",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "kachels en haarden"
                }
              }
            },
            {
              "name": "kerkelijke kunst en religie",
              "id": "35.9.26",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "kerkelijke kunst en religie"
                }
              }
            },
            {
              "name": "kermis",
              "id": "35.9.27",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "kermis"
                }
              }
            },
            {
              "name": "klederdrachten",
              "id": "35.9.28",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "klederdrachten"
                }
              }
            },
            {
              "name": "kleding en textiel",
              "id": "35.9.29",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "kleding en textiel"
                }
              }
            },
            {
              "name": "archeologie",
              "id": "35.9.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "archeologie"
                }
              }
            },
            {
              "name": "kunstnijverheid",
              "id": "35.9.30",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "kunstnijverheid"
                }
              }
            },
            {
              "name": "land- en tuinbouw",
              "id": "35.9.31",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "land- en tuinbouw"
                }
              }
            },
            {
              "name": "letterkunde",
              "id": "35.9.32",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "letterkunde"
                }
              }
            },
            {
              "name": "luchtvaart",
              "id": "35.9.33",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "luchtvaart"
                }
              }
            },
            {
              "name": "maritiem",
              "id": "35.9.81",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Maritiem"
                }
              }
            },
            {
              "name": "meteorologie",
              "id": "35.9.34",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "meteorologie"
                }
              }
            },
            {
              "name": "molen",
              "id": "35.9.35",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "molen"
                }
              }
            },
            {
              "name": "motoren en scooters",
              "id": "35.9.36",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "motoren en scooters"
                }
              }
            },
            {
              "name": "munten en penningen",
              "id": "35.9.37",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "munten en penningen"
                }
              }
            },
            {
              "name": "muziek en podiumkunsten",
              "id": "35.9.38",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "muziek en podiumkunsten"
                }
              }
            },
            {
              "name": "muziekinstrumenten",
              "id": "35.9.39",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "muziekinstrumenten"
                }
              }
            },
            {
              "name": "architectuur",
              "id": "35.9.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "architectuur"
                }
              }
            },
            {
              "name": "natuur en milieu",
              "id": "35.9.40",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "natuur en milieu"
                }
              }
            },
            {
              "name": "natuurkunde",
              "id": "35.9.41",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "natuurkunde"
                }
              }
            },
            {
              "name": "onderwijs en opvoeding",
              "id": "35.9.42",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "onderwijs en opvoeding"
                }
              }
            },
            {
              "name": "oorlog en wapens",
              "id": "35.9.43",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "oorlog en wapens"
                }
              }
            },
            {
              "name": "optiek",
              "id": "35.9.44",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "optiek"
                }
              }
            },
            {
              "name": "oudheidkamer",
              "id": "35.9.45",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "oudheidkamer"
                }
              }
            },
            {
              "name": "overheid",
              "id": "35.9.46",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "overheid"
                }
              }
            },
            {
              "name": "poppenhuizen",
              "id": "35.9.47",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "poppenhuizen"
                }
              }
            },
            {
              "name": "prehistorie",
              "id": "35.9.48",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "prehistorie"
                }
              }
            },
            {
              "name": "rijtuigen en wagens",
              "id": "35.9.49",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "rijtuigen en wagens"
                }
              }
            },
            {
              "name": "rotterdam",
              "id": "35.9.82",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Rotterdam"
                }
              }
            },
            {
              "name": "overig",
              "id": "35.9.83",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Overig"
                }
              }
            },
            {
              "name": "ruimtevaart",
              "id": "35.9.50",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "ruimtevaart"
                }
              }
            },
            {
              "name": "scheepvaart en scheepsbouw",
              "id": "35.9.51",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "scheepvaart en scheepsbouw"
                }
              }
            },
            {
              "name": "schilderkunst (1600 - 1850)",
              "id": "35.9.52",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "schilderkunst (1600 - 1850)"
                }
              }
            },
            {
              "name": "schilderkunst (na 1850)",
              "id": "35.9.53",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "schilderkunst (na 1850)"
                }
              }
            },
            {
              "name": "schilderkunst (voor 1600)",
              "id": "35.9.54",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "schilderkunst (voor 1600)"
                }
              }
            },
            {
              "name": "scooters",
              "id": "35.9.55",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "scooters"
                }
              }
            },
            {
              "name": "sieraden",
              "id": "35.9.56",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "sieraden"
                }
              }
            },
            {
              "name": "speelgoed",
              "id": "35.9.57",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "speelgoed"
                }
              }
            },
            {
              "name": "spoor- en tramwegen",
              "id": "35.9.58",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "spoor- en tramwegen"
                }
              }
            },
            {
              "name": "sport en spel",
              "id": "35.9.59",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "sport en spel"
                }
              }
            },
            {
              "name": "auto's",
              "id": "35.9.6",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "auto's"
                }
              }
            },
            {
              "name": "stads- en streekhistorie",
              "id": "35.9.60",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "stads- en streekhistorie"
                }
              }
            },
            {
              "name": "stijlkamers en interieurs",
              "id": "35.9.61",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "stijlkamers en interieurs"
                }
              }
            },
            {
              "name": "straf- en folterwerktuigen",
              "id": "35.9.62",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "straf- en folterwerktuigen"
                }
              }
            },
            {
              "name": "tapijtkunst",
              "id": "35.9.63",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "tapijtkunst"
                }
              }
            },
            {
              "name": "tegels",
              "id": "35.9.64",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "tegels"
                }
              }
            },
            {
              "name": "tekenkunst",
              "id": "35.9.65",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "tekenkunst"
                }
              }
            },
            {
              "name": "textiel",
              "id": "35.9.66",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "textiel"
                }
              }
            },
            {
              "name": "uniformen en militaria",
              "id": "35.9.67",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "uniformen en militaria"
                }
              }
            },
            {
              "name": "uurwerken",
              "id": "35.9.68",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "uurwerken"
                }
              }
            },
            {
              "name": "veeteelt en producten",
              "id": "35.9.69",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "veeteelt en producten"
                }
              }
            },
            {
              "name": "beeldhouwkunst",
              "id": "35.9.7",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "beeldhouwkunst"
                }
              }
            },
            {
              "name": "verenigingshistorie",
              "id": "35.9.70",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "verenigingshistorie"
                }
              }
            },
            {
              "name": "visserij",
              "id": "35.9.71",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "visserij"
                }
              }
            },
            {
              "name": "voeding en drank",
              "id": "35.9.72",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "voeding en drank"
                }
              }
            },
            {
              "name": "volkenkunde (Europees)",
              "id": "35.9.73",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "volkenkunde (Europees)"
                }
              }
            },
            {
              "name": "volkenkunde (niet-Europees)",
              "id": "35.9.74",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "volkenkunde (niet-Europees)"
                }
              }
            },
            {
              "name": "wassen beelden",
              "id": "35.9.75",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "wassen beelden"
                }
              }
            },
            {
              "name": "waterbouw(kunde)",
              "id": "35.9.76",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "waterbouw(kunde)"
                }
              }
            },
            {
              "name": "werkplaats",
              "id": "35.9.77",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "werkplaats"
                }
              }
            },
            {
              "name": "werktuigen",
              "id": "35.9.78",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "werktuigen"
                }
              }
            },
            {
              "name": "winkel",
              "id": "35.9.79",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "winkel"
                }
              }
            },
            {
              "name": "boekdrukkunst",
              "id": "35.9.8",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "boekdrukkunst"
                }
              }
            },
            {
              "name": "cartografie",
              "id": "35.9.9",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "cartografie"
                }
              }
            }
          ]
        },
        {
          "name": "Film",
          "id": "37.1.14",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Film"
            }
          }
        },
        {
          "name": "Theater",
          "id": "37.1.6",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Theater"
            }
          },
          "properties": [
            {
              "name": "Opera",
              "id": "37.1.12",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Opera"
                }
              }
            },
            {
              "name": "Dans/Ballet",
              "id": "37.1.13",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Dans/Ballet"
                }
              }
            },
            {
              "name": "Cabaret",
              "id": "37.1.7",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Cabaret"
                }
              }
            },
            {
              "name": "Musical/Show",
              "id": "37.1.8",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Musical/Show"
                }
              }
            }
          ]
        }
      ]
    },
    {
      "name": "Tocht/Route",
      "id": "36",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Tocht/Route"
        }
      },
      "properties": [
        {
          "name": "Afstand",
          "id": "36.1",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Afstand"
            }
          },
          "unit": "km"
        },
        {
          "name": "Toelichting seizoen",
          "id": "36.10",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Toelichting seizoen"
            }
          }
        },
        {
          "name": "Toelichting startpunt",
          "id": "36.11",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Toelichting startpunt"
            }
          }
        },
        {
          "name": "Naam beheerder",
          "id": "36.2",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Naam beheerder"
            }
          }
        },
        {
          "name": "Route typering",
          "id": "36.3",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Route typering"
            }
          },
          "options": [
            {
              "name": "Electrisch varen (fluisterboot)",
              "id": "36.3.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Electrisch varen (fluisterboot)"
                }
              }
            },
            {
              "name": "Wielrennen",
              "id": "36.3.10",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Wielrennen"
                }
              }
            },
            {
              "name": "Zeilen",
              "id": "36.3.11",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Zeilen"
                }
              }
            },
            {
              "name": "Kanoën",
              "id": "36.3.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Kanoën"
                }
              }
            },
            {
              "name": "Motorjacht",
              "id": "36.3.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Motorjacht"
                }
              }
            },
            {
              "name": "Mountainbiken",
              "id": "36.3.4",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Mountainbiken"
                }
              }
            },
            {
              "name": "Natuurroute",
              "id": "36.3.5",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Natuurroute"
                }
              }
            },
            {
              "name": "Open motorboot / sloep",
              "id": "36.3.6",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Open motorboot / sloep"
                }
              }
            },
            {
              "name": "Recreatief",
              "id": "36.3.7",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Recreatief"
                }
              }
            },
            {
              "name": "Roeiboot",
              "id": "36.3.8",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Roeiboot"
                }
              }
            },
            {
              "name": "Stadswandeling",
              "id": "36.3.9",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Stadswandeling"
                }
              }
            }
          ]
        },
        {
          "name": "Route obstakels",
          "id": "36.4",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Route obstakels"
            }
          },
          "options": [
            {
              "name": "Brug",
              "id": "36.4.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Brug"
                }
              }
            },
            {
              "name": "Vrij liggend (fiets-)pad",
              "id": "36.4.10",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Vrij liggend (fiets-)pad"
                }
              }
            },
            {
              "name": "Wildrooster",
              "id": "36.4.11",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Wildrooster"
                }
              }
            },
            {
              "name": "Heuvelachtig",
              "id": "36.4.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Heuvelachtig"
                }
              }
            },
            {
              "name": "Met water",
              "id": "36.4.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Met water"
                }
              }
            },
            {
              "name": "Op de weg",
              "id": "36.4.4",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Op de weg"
                }
              }
            },
            {
              "name": "Oversteek provinciale weg",
              "id": "36.4.5",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Oversteek provinciale weg"
                }
              }
            },
            {
              "name": "Spoorwegovergang",
              "id": "36.4.6",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Spoorwegovergang"
                }
              }
            },
            {
              "name": "Tunnel",
              "id": "36.4.7",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Tunnel"
                }
              }
            },
            {
              "name": "Viaduct",
              "id": "36.4.8",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Viaduct"
                }
              }
            },
            {
              "name": "Vlak",
              "id": "36.4.9",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Vlak"
                }
              }
            }
          ]
        },
        {
          "name": "Route kenmerken",
          "id": "36.5",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Route kenmerken"
            }
          },
          "options": [
            {
              "name": "Abellife route",
              "id": "36.5.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Abellife route"
                }
              }
            },
            {
              "name": "LAW route",
              "id": "36.5.10",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "LAW route"
                }
              }
            },
            {
              "name": "LF route",
              "id": "36.5.11",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "LF route"
                }
              }
            },
            {
              "name": "MP3 route",
              "id": "36.5.12",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "MP3 route"
                }
              }
            },
            {
              "name": "Themaroute",
              "id": "36.5.13",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Themaroute"
                }
              }
            },
            {
              "name": "Van A naar A",
              "id": "36.5.14",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Van A naar A"
                }
              }
            },
            {
              "name": "Van A naar B",
              "id": "36.5.15",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Van A naar B"
                }
              }
            },
            {
              "name": "ANWB route",
              "id": "36.5.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "ANWB route"
                }
              }
            },
            {
              "name": "Certificaat buiten rijden vereist",
              "id": "36.5.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Certificaat buiten rijden vereist"
                }
              }
            },
            {
              "name": "Fietsknooppuntenroute",
              "id": "36.5.4",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Fietsknooppuntenroute"
                }
              }
            },
            {
              "name": "geschikt voor Nordic Walking",
              "id": "36.5.5",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "geschikt voor Nordic Walking"
                }
              }
            },
            {
              "name": "geschikt voor rolstoelen",
              "id": "36.5.6",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "geschikt voor rolstoelen"
                }
              }
            },
            {
              "name": "geschikt voor wandelwagens",
              "id": "36.5.7",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "geschikt voor wandelwagens"
                }
              }
            },
            {
              "name": "GPS route",
              "id": "36.5.8",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "GPS route"
                }
              }
            },
            {
              "name": "Kindvriendelijk",
              "id": "36.5.9",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Kindvriendelijk"
                }
              }
            }
          ]
        },
        {
          "name": "Gemarkeerd",
          "id": "36.6",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Gemarkeerd"
            }
          }
        },
        {
          "name": "Toelichting markering",
          "id": "36.7",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Toelichting markering"
            }
          }
        },
        {
          "name": "Starten van TOP('s)",
          "id": "36.8",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Starten van TOP('s)"
            }
          },
          "options": [
            {
              "name": "TOP Alblasserwaard",
              "id": "36.8.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Alblasserwaard"
                }
              }
            },
            {
              "name": "TOP Hellevoetssluis - Quackstrand",
              "id": "36.8.10",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Hellevoetssluis - Quackstrand"
                }
              }
            },
            {
              "name": "TOP Hollandse Biesbosch",
              "id": "36.8.11",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Hollandse Biesbosch"
                }
              }
            },
            {
              "name": "TOP Hoornaar",
              "id": "36.8.12",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Hoornaar"
                }
              }
            },
            {
              "name": "TOP Lage Zwaluwe",
              "id": "36.8.13",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Lage Zwaluwe"
                }
              }
            },
            {
              "name": "TOP Lexmond",
              "id": "36.8.14",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Lexmond"
                }
              }
            },
            {
              "name": "TOP Loetbos",
              "id": "36.8.15",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Loetbos"
                }
              }
            },
            {
              "name": "TOP Maasland",
              "id": "36.8.16",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Maasland"
                }
              }
            },
            {
              "name": "TOP Nieuwkoop",
              "id": "36.8.17",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Nieuwkoop"
                }
              }
            },
            {
              "name": "TOP Nieuwpoort",
              "id": "36.8.18",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Nieuwpoort"
                }
              }
            },
            {
              "name": "TOP Oortjespad",
              "id": "36.8.19",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Oortjespad"
                }
              }
            },
            {
              "name": "TOP Ameide",
              "id": "36.8.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Ameide"
                }
              }
            },
            {
              "name": "TOP Oostvoorne",
              "id": "36.8.20",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Oostvoorne"
                }
              }
            },
            {
              "name": "TOP Oud-Alblas",
              "id": "36.8.21",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Oud-Alblas"
                }
              }
            },
            {
              "name": "TOP Pondskoekersluis",
              "id": "36.8.22",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Pondskoekersluis"
                }
              }
            },
            {
              "name": "TOP Reeuwijkse Hout",
              "id": "36.8.23",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Reeuwijkse Hout"
                }
              }
            },
            {
              "name": "TOP Rijnsaterwoude",
              "id": "36.8.24",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Rijnsaterwoude"
                }
              }
            },
            {
              "name": "TOP Roelofarendsveen",
              "id": "36.8.25",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Roelofarendsveen"
                }
              }
            },
            {
              "name": "TOP Rottemeren",
              "id": "36.8.26",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Rottemeren"
                }
              }
            },
            {
              "name": "TOP Sassenheim",
              "id": "36.8.27",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Sassenheim"
                }
              }
            },
            {
              "name": "TOP Schipluiden",
              "id": "36.8.28",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Schipluiden"
                }
              }
            },
            {
              "name": "TOP Schoonhoven",
              "id": "36.8.29",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Schoonhoven"
                }
              }
            },
            {
              "name": "TOP Bernisse-Oost",
              "id": "36.8.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Bernisse-Oost"
                }
              }
            },
            {
              "name": "TOP Spijkenisse",
              "id": "36.8.30",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Spijkenisse"
                }
              }
            },
            {
              "name": "TOP Vlaardingen",
              "id": "36.8.31",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Vlaardingen"
                }
              }
            },
            {
              "name": "TOP Wateringen",
              "id": "36.8.32",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Wateringen"
                }
              }
            },
            {
              "name": "TOP Winkelpolder",
              "id": "36.8.33",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Winkelpolder"
                }
              }
            },
            {
              "name": "TOP Zoetermeer - Bentwoud",
              "id": "36.8.34",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Zoetermeer - Bentwoud"
                }
              }
            },
            {
              "name": "TOP Zoeterwoude",
              "id": "36.8.35",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Zoeterwoude"
                }
              }
            },
            {
              "name": "TOP Alblasserdam",
              "id": "36.8.36",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Alblasserdam"
                }
              }
            },
            {
              "name": "TOP Brielle",
              "id": "36.8.4",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Brielle"
                }
              }
            },
            {
              "name": "TOP Delft - Ackerdijkse Bos",
              "id": "36.8.5",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Delft - Ackerdijkse Bos"
                }
              }
            },
            {
              "name": "TOP Gorinchem",
              "id": "36.8.6",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Gorinchem"
                }
              }
            },
            {
              "name": "TOP Haastrecht",
              "id": "36.8.7",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Haastrecht"
                }
              }
            },
            {
              "name": "TOP Hazerswoude",
              "id": "36.8.8",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Hazerswoude"
                }
              }
            },
            {
              "name": "TOP Heinoomsvaart",
              "id": "36.8.9",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "TOP Heinoomsvaart"
                }
              }
            }
          ]
        },
        {
          "name": "Toelichting paardrijden",
          "id": "36.9",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Toelichting paardrijden"
            }
          }
        },
        {
          "name": "Beschikbare talen",
          "id": "36.12",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Toelichting"
            }
          },
          "options": [
            {
              "name": "NL",
              "id": "36.12.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "NL"
                }
              }
            },
            {
              "name": "EN",
              "id": "36.12.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "EN"
                }
              }
            },
            {
              "name": "DE",
              "id": "36.12.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "DE"
                }
              }
            },
            {
              "name": "FR",
              "id": "36.12.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "FR"
                }
              }
            },
            {
              "name": "ES",
              "id": "36.12.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "ES"
                }
              }
            },
            {
              "name": "IT",
              "id": "36.12.6",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "IT"
                }
              }
            },
            {
              "name": "Overige talen",
              "id": "36.12.7",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Overige talen"
                }
              }
            }
          ]
        }
      ]
    },
    {
      "name": "Cultuur",
      "id": "37",
      "datatype": "freetext",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Cultuur"
        }
      },
      "properties": [
        {
          "name": "Genre",
          "id": "37.1",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Genre"
            }
          },
          "options": [
            {
              "name": "Tentoonstelling",
              "id": "37.1.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Tentoonstelling"
                }
              }
            },
            {
              "name": "Festivals",
              "id": "37.1.10",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Festivals"
                }
              }
            },
            {
              "name": "Architectuur",
              "id": "37.1.11",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Architectuur"
                }
              }
            },
            {
              "name": "Opera",
              "id": "37.1.12",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Opera"
                }
              }
            },
            {
              "name": "Dans/Ballet",
              "id": "37.1.13",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Dans/Ballet"
                }
              }
            },
            {
              "name": "Dance",
              "id": "37.1.15",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Dance"
                }
              }
            },
            {
              "name": "R&B/Soul",
              "id": "37.1.16",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "R&B/Soul"
                }
              }
            },
            {
              "name": "Jeugd",
              "id": "37.1.17",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Jeugd"
                }
              }
            },
            {
              "name": "Literatuur",
              "id": "37.1.18",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Literatuur"
                }
              }
            },
            {
              "name": "Concert",
              "id": "37.1.19",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Concert"
                }
              }
            },
            {
              "name": "Klassieke muziek",
              "id": "37.1.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Klassieke muziek"
                }
              }
            },
            {
              "name": "Blues",
              "id": "37.1.20",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Blues"
                }
              }
            },
            {
              "name": "Techno",
              "id": "37.1.21",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Techno"
                }
              }
            },
            {
              "name": "House",
              "id": "37.1.22",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "House"
                }
              }
            },
            {
              "name": "Hard Core",
              "id": "37.1.23",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Hard Core"
                }
              }
            },
            {
              "name": "Trance",
              "id": "37.1.24",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Trance"
                }
              }
            },
            {
              "name": "Drum & Bass",
              "id": "37.1.25",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Drum & Bass"
                }
              }
            },
            {
              "name": "Thema",
              "id": "37.1.26",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Thema"
                }
              }
            },
            {
              "name": "Amateur",
              "id": "37.1.27",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Amateur"
                }
              }
            },
            {
              "name": "Nederlandstalig",
              "id": "37.1.28",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Nederlandstalig"
                }
              }
            },
            {
              "name": "DJ/VJ",
              "id": "37.1.29",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "DJ/VJ"
                }
              }
            },
            {
              "name": "Lichte muziek",
              "id": "37.1.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Lichte muziek"
                }
              }
            },
            {
              "name": "Jazz/Wereldmuziek",
              "id": "37.1.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Jazz/Wereldmuziek"
                }
              }
            },
            {
              "name": "Lezing/debat",
              "id": "37.1.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Lezing/debat"
                }
              }
            },
            {
              "name": "Cabaret",
              "id": "37.1.7",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Cabaret"
                }
              }
            },
            {
              "name": "Musical/Show",
              "id": "37.1.8",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Musical/Show"
                }
              }
            },
            {
              "name": "Pop/Rock",
              "id": "37.1.9",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Pop/Rock"
                }
              }
            }
          ]
        },
        {
          "name": "Zaal/Ruimte",
          "id": "37.10",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Zaal/Ruimte"
            }
          }
        },
        {
          "name": "LanguageNoProblem",
          "id": "37.2",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "LanguageNoProblem"
            }
          }
        },
        {
          "name": "Taal",
          "id": "37.3",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Taal"
            }
          }
        },
        {
          "name": "Informatie kaartverkoop",
          "id": "37.4",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Informatie kaartverkoop"
            }
          }
        },
        {
          "name": "Start kaartverkoop",
          "id": "37.5",
          "datatype": "date",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Start kaartverkoop"
            }
          }
        },
        {
          "name": "Reserveren",
          "id": "37.6",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Reserveren"
            }
          },
          "options": [
            {
              "name": "reserveren niet mogelijk",
              "id": "37.6.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "reserveren niet mogelijk"
                }
              }
            },
            {
              "name": "reserveren niet noodzakelijk",
              "id": "37.6.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "reserveren niet noodzakelijk"
                }
              }
            },
            {
              "name": "reserveren aangeraden in hoogseizoen",
              "id": "37.6.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "reserveren aangeraden in hoogseizoen"
                }
              }
            },
            {
              "name": "reserveren aangeraden",
              "id": "37.6.4",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "reserveren aangeraden"
                }
              }
            },
            {
              "name": "reserveren verplicht in hoogseizoen",
              "id": "37.6.5",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "reserveren verplicht in hoogseizoen"
                }
              }
            },
            {
              "name": "reserveren voor groepen verplicht",
              "id": "37.6.6",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "reserveren voor groepen verplicht"
                }
              }
            },
            {
              "name": "reserveren verplicht",
              "id": "37.6.7",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "reserveren verplicht"
                }
              }
            }
          ]
        },
        {
          "name": "Kunststroming",
          "id": "37.7",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kunststroming"
            }
          },
          "options": [
            {
              "name": "Vroege Middeleeuwen",
              "id": "37.7.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Vroege Middeleeuwen"
                }
              }
            },
            {
              "name": "20e eeuw",
              "id": "37.7.10",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "20e eeuw"
                }
              }
            },
            {
              "name": "21e eeuw",
              "id": "37.7.11",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "21e eeuw"
                }
              }
            },
            {
              "name": "Hoge Middeleeuwen",
              "id": "37.7.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Hoge Middeleeuwen"
                }
              }
            },
            {
              "name": "Late Middeleeuwen",
              "id": "37.7.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Late Middeleeuwen"
                }
              }
            },
            {
              "name": "Renaissance",
              "id": "37.7.4",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Renaissance"
                }
              }
            },
            {
              "name": "Barok",
              "id": "37.7.5",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Barok"
                }
              }
            },
            {
              "name": "19de eeuw",
              "id": "37.7.6",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "19de eeuw"
                }
              }
            },
            {
              "name": "Modernisme",
              "id": "37.7.7",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Modernisme"
                }
              }
            },
            {
              "name": "Hedendaagse kunst",
              "id": "37.7.8",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Hedendaagse kunst"
                }
              }
            },
            {
              "name": "Post-Modernisme",
              "id": "37.7.9",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Post-Modernisme"
                }
              }
            }
          ]
        }
      ]
    },
    {
      "name": "Sport",
      "id": "38",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Sport"
        }
      },
      "properties": [
        {
          "name": "Type sportactiviteit",
          "id": "38.1",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Type sportactiviteit"
            }
          },
          "options": [
            {
              "name": "Autosport",
              "id": "38.1.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Autosport"
                }
              }
            },
            {
              "name": "Volleybal",
              "id": "38.1.10",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Volleybal"
                }
              }
            },
            {
              "name": "Zwemmen",
              "id": "38.1.11",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Zwemmen"
                }
              }
            },
            {
              "name": "Atletiek",
              "id": "38.1.12",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Atletiek"
                }
              }
            },
            {
              "name": "Badminton",
              "id": "38.1.13",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Badminton"
                }
              }
            },
            {
              "name": "Ballet",
              "id": "38.1.14",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ballet"
                }
              }
            },
            {
              "name": "Basketball",
              "id": "38.1.15",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Basketball"
                }
              }
            },
            {
              "name": "Biljart",
              "id": "38.1.16",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Biljart"
                }
              }
            },
            {
              "name": "Bowlen",
              "id": "38.1.17",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Bowlen"
                }
              }
            },
            {
              "name": "Danssport",
              "id": "38.1.18",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Danssport"
                }
              }
            },
            {
              "name": "Darts",
              "id": "38.1.19",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Darts"
                }
              }
            },
            {
              "name": "Golf",
              "id": "38.1.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Golf"
                }
              }
            },
            {
              "name": "Denksport",
              "id": "38.1.20",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Denksport"
                }
              }
            },
            {
              "name": "Fitness",
              "id": "38.1.21",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Fitness"
                }
              }
            },
            {
              "name": "Handbal",
              "id": "38.1.22",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Handbal"
                }
              }
            },
            {
              "name": "Honkbal",
              "id": "38.1.23",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Honkbal"
                }
              }
            },
            {
              "name": "Kanoën",
              "id": "38.1.24",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Kanoën"
                }
              }
            },
            {
              "name": "Korfbal",
              "id": "38.1.25",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Korfbal"
                }
              }
            },
            {
              "name": "Midgetgolf",
              "id": "38.1.26",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Midgetgolf"
                }
              }
            },
            {
              "name": "Poolen",
              "id": "38.1.27",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Poolen"
                }
              }
            },
            {
              "name": "Roeien",
              "id": "38.1.28",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Roeien"
                }
              }
            },
            {
              "name": "Scouting",
              "id": "38.1.29",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Scouting"
                }
              }
            },
            {
              "name": "Gymnastiek",
              "id": "38.1.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Gymnastiek"
                }
              }
            },
            {
              "name": "Squash",
              "id": "38.1.30",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Squash"
                }
              }
            },
            {
              "name": "Tafeltennis",
              "id": "38.1.31",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Tafeltennis"
                }
              }
            },
            {
              "name": "Turnen",
              "id": "38.1.32",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Turnen"
                }
              }
            },
            {
              "name": "Vechtsport",
              "id": "38.1.33",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Vechtsport"
                }
              }
            },
            {
              "name": "Yoga",
              "id": "38.1.34",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Yoga"
                }
              }
            },
            {
              "name": "Zaalvoetbal",
              "id": "38.1.35",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Zaalvoetbal"
                }
              }
            },
            {
              "name": "Hockey",
              "id": "38.1.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Hockey"
                }
              }
            },
            {
              "name": "Paardensport",
              "id": "38.1.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Paardensport"
                }
              }
            },
            {
              "name": "Schaatsen",
              "id": "38.1.6",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Schaatsen"
                }
              }
            },
            {
              "name": "Skiën",
              "id": "38.1.7",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Skiën"
                }
              }
            },
            {
              "name": "Tennis",
              "id": "38.1.8",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Tennis"
                }
              }
            },
            {
              "name": "Voetbal",
              "id": "38.1.9",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Voetbal"
                }
              }
            }
          ]
        },
        {
          "name": "Binnen/buiten",
          "id": "38.2",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Binnen/buiten"
            }
          },
          "options": [
            {
              "name": "Binnen",
              "id": "36.2.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Binnen"
                }
              }
            },
            {
              "name": "Buiten",
              "id": "36.2.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Buiten"
                }
              }
            }
          ]
        }
      ]
    },
    {
      "name": "Golfbaan",
      "id": "39",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Golfbaan"
        }
      },
      "properties": [
        {
          "name": "alleen voor leden",
          "id": "39.1",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "alleen voor leden"
            }
          }
        },
        {
          "name": "alleen voor leden NGF",
          "id": "39.2",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "alleen voor leden NGF"
            }
          }
        },
        {
          "name": "niet-leden op afspraak",
          "id": "39.3",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "niet-leden op afspraak"
            }
          }
        },
        {
          "name": "openbare golfbaan",
          "id": "39.4",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "openbare golfbaan"
            }
          }
        },
        {
          "name": "bezoekers welkom",
          "id": "39.5",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "bezoekers welkom"
            }
          }
        },
        {
          "name": "golfvaardigheidsbewijs (GVB) verplicht",
          "id": "39.6",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "golfvaardigheidsbewijs (GVB) verplicht"
            }
          }
        },
        {
          "name": "Aantal holes",
          "id": "39.7",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Aantal holes"
            }
          }
        }
      ]
    },
    {
      "name": "Classificatie",
      "id": "4",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Classificatie"
        }
      },
      "properties": [
        {
          "name": "Classificatie in sterren",
          "id": "4.1",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Classificatie in sterren"
            }
          }
        },
        {
          "name": "[ROTTERDAM] Classificatie in sterren",
          "id": "4.12",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "[ROTTERDAM] Classificatie in sterren"
            }
          },
          "options": [
            {
              "name": "**-hotel",
              "id": "4.12.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "**-hotel"
                }
              }
            },
            {
              "name": "***-hotel",
              "id": "4.12.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "***-hotel"
                }
              }
            },
            {
              "name": "****-hotel",
              "id": "4.12.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "****-hotel"
                }
              }
            },
            {
              "name": "*****-hotel",
              "id": "4.12.4",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "*****-hotel"
                }
              }
            },
            {
              "name": "hostel",
              "id": "4.12.5",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "hostel"
                }
              }
            },
            {
              "name": "bed & breakfast",
              "id": "4.12.6",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "bed & breakfast"
                }
              }
            },
            {
              "name": "appartement",
              "id": "4.12.7",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "appartement"
                }
              }
            }
          ]
        },
        {
          "name": "Classificatie Michelin",
          "id": "4.10",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Classificatie Michelin"
            }
          },
          "options": [
            {
              "name": "1 ster",
              "id": "4.10.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "1 ster"
                }
              }
            },
            {
              "name": "2 sterren",
              "id": "4.10.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "2 sterren"
                }
              }
            },
            {
              "name": "3 sterren",
              "id": "4.10.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "3 sterren"
                }
              }
            }
          ]
        },
        {
          "name": "Luxe overnachten",
          "id": "4.11",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Luxe overnachten"
            }
          }
        },
        {
          "name": "Classificatie in vlaggen",
          "id": "4.2",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Classificatie in vlaggen"
            }
          }
        },
        {
          "name": "Classificatie in punten",
          "id": "4.3",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Classificatie in punten"
            }
          }
        },
        {
          "name": "Classificatie in tulpen",
          "id": "4.4",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Classificatie in tulpen"
            }
          }
        },
        {
          "name": "Classificatie in hamers",
          "id": "4.5",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Classificatie in hamers"
            }
          }
        },
        {
          "name": "Greenkey",
          "id": "4.6",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Greenkey"
            }
          },
          "options": [
            {
              "name": "Greenkey",
              "id": "4.6.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Greenkey"
                }
              }
            },
            {
              "name": "Brons",
              "id": "4.6.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Brons"
                }
              }
            },
            {
              "name": "Zilver",
              "id": "4.6.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Zilver"
                }
              }
            },
            {
              "name": "Goud",
              "id": "4.6.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Goud"
                }
              }
            }
          ]
        },
        {
          "name": "Stayokay hostel",
          "id": "4.7",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Stayokay hostel"
            }
          }
        },
        {
          "name": "Classificatie op basis van",
          "id": "4.8",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Classificatie op basis van"
            }
          },
          "options": [
            {
              "name": "ANWB classificatie",
              "id": "4.8.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "ANWB classificatie"
                }
              }
            },
            {
              "name": "geen",
              "id": "4.8.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "geen"
                }
              }
            },
            {
              "name": "nieuwe NHC definitief",
              "id": "4.8.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "nieuwe NHC definitief"
                }
              }
            },
            {
              "name": "nieuwe NHC voorlopig",
              "id": "4.8.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "nieuwe NHC voorlopig"
                }
              }
            },
            {
              "name": "oude Benelux classificatie",
              "id": "4.8.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "oude Benelux classificatie"
                }
              }
            },
            {
              "name": "Vlaggen classificatie",
              "id": "4.8.6",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Vlaggen classificatie"
                }
              }
            },
            {
              "name": "onbekend",
              "id": "4.8.7",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "onbekend"
                }
              }
            }
          ]
        },
        {
          "name": "Zoover referentie",
          "id": "4.9",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Zoover referentie"
            }
          }
        }
      ]
    },
    {
      "name": "VVV",
      "id": "40",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "VVV"
        }
      },
      "properties": [
        {
          "name": "Regiogidsen",
          "id": "40.10",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Regiogidsen"
            }
          }
        },
        {
          "name": "Reisgidsen",
          "id": "40.11",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Reisgidsen"
            }
          }
        },
        {
          "name": "Rondvaarten",
          "id": "40.12",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Rondvaarten"
            }
          }
        },
        {
          "name": "Skateroutes",
          "id": "40.13",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Skateroutes"
            }
          }
        },
        {
          "name": "Souvenirs",
          "id": "40.14",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Souvenirs"
            }
          }
        },
        {
          "name": "Stadsgids / Rondleiding",
          "id": "40.15",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Stadsgids / Rondleiding"
            }
          }
        },
        {
          "name": "Streekproducten",
          "id": "40.16",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Streekproducten"
            }
          }
        },
        {
          "name": "Podiumkaart (Theater & Concertbon)",
          "id": "40.17",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Podiumkaart (Theater & Concertbon)"
            }
          }
        },
        {
          "name": "VVV Cadeaubon",
          "id": "40.18",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "VVV Cadeaubon"
            }
          }
        },
        {
          "name": "VVV Ticketpoint",
          "id": "40.19",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "VVV Ticketpoint"
            }
          }
        },
        {
          "name": "Autoroutes",
          "id": "40.2",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Autoroutes"
            }
          }
        },
        {
          "name": "VVV-arrangementen",
          "id": "40.20",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "VVV-arrangementen"
            }
          }
        },
        {
          "name": "Wandelroutes",
          "id": "40.21",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Wandelroutes"
            }
          }
        },
        {
          "name": "Winkelroutes",
          "id": "40.22",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Winkelroutes"
            }
          }
        },
        {
          "name": "Watersportfolders",
          "id": "40.23",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Watersportfolders"
            }
          }
        },
        {
          "name": "VVV Giftcard",
          "id": "40.24",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "VVV Giftcard"
            }
          }
        },
        {
          "name": "Bioscoopbon",
          "id": "40.3",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Bioscoopbon"
            }
          }
        },
        {
          "name": "Bol.com Cadeaubon",
          "id": "40.4",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Bol.com Cadeaubon"
            }
          }
        },
        {
          "name": "Falk-VVV Fietskaarten 1:50.000",
          "id": "40.5",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Falk-VVV Fietskaarten 1:50.000"
            }
          }
        },
        {
          "name": "Fietsverhuur",
          "id": "40.6",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Fietsverhuur"
            }
          }
        },
        {
          "name": "Landelijke ticketservice",
          "id": "40.7",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Landelijke ticketservice"
            }
          }
        },
        {
          "name": "VVV Dinercheque",
          "id": "40.8",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "VVV Dinercheque"
            }
          }
        },
        {
          "name": "Plattegronden",
          "id": "40.9",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Plattegronden"
            }
          }
        }
      ]
    },
    {
      "name": "Winkel",
      "id": "41",
      "datatype": "freetext",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Winkel"
        }
      },
      "properties": [
        {
          "name": "Type winkel",
          "id": "41.1",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Type winkel"
            }
          },
          "options": [
            {
              "name": "bloemen/planten",
              "id": "41.1.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "bloemen/planten"
                }
              }
            },
            {
              "name": "foto-/video-artikelen",
              "id": "41.1.10",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "foto-/video-artikelen"
                }
              }
            },
            {
              "name": "glas/porcelein",
              "id": "41.1.11",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "glas/porcelein"
                }
              }
            },
            {
              "name": "herenkleding",
              "id": "41.1.12",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "herenkleding"
                }
              }
            },
            {
              "name": "huishoudelijke apparatuur",
              "id": "41.1.13",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "huishoudelijke apparatuur"
                }
              }
            },
            {
              "name": "juweliersartikelen",
              "id": "41.1.14",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "juweliersartikelen"
                }
              }
            },
            {
              "name": "kinder/babykleding",
              "id": "41.1.15",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "kinder/babykleding"
                }
              }
            },
            {
              "name": "kunst/antiek",
              "id": "41.1.16",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "kunst/antiek"
                }
              }
            },
            {
              "name": "lederwaren/reisartikelen",
              "id": "41.1.17",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "lederwaren/reisartikelen"
                }
              }
            },
            {
              "name": "lingerie",
              "id": "41.1.18",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "lingerie"
                }
              }
            },
            {
              "name": "muziek/boeken",
              "id": "41.1.19",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "muziek/boeken"
                }
              }
            },
            {
              "name": "bruingoed",
              "id": "41.1.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "bruingoed"
                }
              }
            },
            {
              "name": "muziekinstrumenten",
              "id": "41.1.20",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "muziekinstrumenten"
                }
              }
            },
            {
              "name": "optiek",
              "id": "41.1.21",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "optiek"
                }
              }
            },
            {
              "name": "parfums/cosmetica",
              "id": "41.1.22",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "parfums/cosmetica"
                }
              }
            },
            {
              "name": "schoenen",
              "id": "41.1.23",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "schoenen"
                }
              }
            },
            {
              "name": "schrijfwaren",
              "id": "41.1.24",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "schrijfwaren"
                }
              }
            },
            {
              "name": "souvenirs",
              "id": "41.1.25",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "souvenirs"
                }
              }
            },
            {
              "name": "speelgoed",
              "id": "41.1.26",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "speelgoed"
                }
              }
            },
            {
              "name": "sportartikelen",
              "id": "41.1.27",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "sportartikelen"
                }
              }
            },
            {
              "name": "tabak",
              "id": "41.1.28",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "tabak"
                }
              }
            },
            {
              "name": "uurwerken",
              "id": "41.1.29",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "uurwerken"
                }
              }
            },
            {
              "name": "computers/telecom",
              "id": "41.1.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "computers/telecom"
                }
              }
            },
            {
              "name": "watersportartikelen",
              "id": "41.1.30",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "watersportartikelen"
                }
              }
            },
            {
              "name": "witgoed",
              "id": "41.1.31",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "witgoed"
                }
              }
            },
            {
              "name": "woninginrichting",
              "id": "41.1.32",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "woninginrichting"
                }
              }
            },
            {
              "name": "Hobbyartikelen",
              "id": "41.1.33",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Hobbyartikelen"
                }
              }
            },
            {
              "name": "Videoverhuur",
              "id": "41.1.34",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Videoverhuur"
                }
              }
            },
            {
              "name": "Reizen",
              "id": "41.1.35",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Reizen"
                }
              }
            },
            {
              "name": "Slijterij",
              "id": "41.1.36",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Slijterij"
                }
              }
            },
            {
              "name": "Doe het Zelf",
              "id": "41.1.37",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Doe het Zelf"
                }
              }
            },
            {
              "name": "Kapper",
              "id": "41.1.38",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Kapper"
                }
              }
            },
            {
              "name": "Beauty",
              "id": "41.1.39",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Beauty"
                }
              }
            },
            {
              "name": "dameskleding",
              "id": "41.1.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "dameskleding"
                }
              }
            },
            {
              "name": "Stomerij",
              "id": "41.1.40",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Stomerij"
                }
              }
            },
            {
              "name": "Medische artikelen",
              "id": "41.1.41",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Medische artikelen"
                }
              }
            },
            {
              "name": "Textiel",
              "id": "41.1.42",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Textiel"
                }
              }
            },
            {
              "name": "Drukkerij/Copyshop",
              "id": "41.1.43",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Drukkerij/Copyshop"
                }
              }
            },
            {
              "name": "Dienstverlening",
              "id": "41.1.44",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Dienstverlening"
                }
              }
            },
            {
              "name": "Zorg",
              "id": "41.1.45",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Zorg"
                }
              }
            },
            {
              "name": "Tweedehands artikelen",
              "id": "41.1.46",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Tweedehands artikelen"
                }
              }
            },
            {
              "name": "Autoaccessoires",
              "id": "41.1.47",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Autoaccessoires"
                }
              }
            },
            {
              "name": "Tweewielers",
              "id": "41.1.48",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Tweewielers"
                }
              }
            },
            {
              "name": "Kampeerartikelen",
              "id": "41.1.49",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Kampeerartikelen"
                }
              }
            },
            {
              "name": "delicatessen",
              "id": "41.1.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "delicatessen"
                }
              }
            },
            {
              "name": "Kantoor artikelen",
              "id": "41.1.50",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Kantoor artikelen"
                }
              }
            },
            {
              "name": "Keuken",
              "id": "41.1.51",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Keuken"
                }
              }
            },
            {
              "name": "diamanten",
              "id": "41.1.6",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "diamanten"
                }
              }
            },
            {
              "name": "drogisterijartikelen",
              "id": "41.1.7",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "drogisterijartikelen"
                }
              }
            },
            {
              "name": "eten en drinken",
              "id": "41.1.8",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "eten en drinken"
                }
              }
            },
            {
              "name": "fietsen",
              "id": "41.1.9",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "fietsen"
                }
              }
            }
          ]
        },
        {
          "name": "Acceptant VVV Giftcard",
          "id": "41.10",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Acceptant VVV Giftcard"
            }
          }
        },
        {
          "name": "Verkooppunt VVV Giftcard",
          "id": "41.11",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Verkooppunt VVV Giftcard"
            }
          }
        },
        {
          "name": "Fysieke winkel",
          "id": "41.12",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Fysieke winkel"
            }
          }
        },
        {
          "name": "Verkooppunt VVV Cadeaukaart",
          "id": "41.13",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Verkooppunt VVV Cadeaukaart"
            }
          }
        },
        {
          "name": "Detailpagina niet tonen",
          "id": "41.14",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Detailpagina niet tonen"
            }
          }
        },
        {
          "name": "Duurzaam",
          "id": "41.15",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Duurzaam"
            }
          }
        },
        {
          "name": "Acceptant VVV Cadeaubon",
          "id": "41.2",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Acceptant VVV Cadeaubon"
            }
          }
        },
        {
          "name": "Acceptant Nationale Dinercheque",
          "id": "41.3",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Acceptant Nationale Dinercheque"
            }
          }
        },
        {
          "name": "Webwinkel",
          "id": "41.5",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Webwinkel"
            }
          }
        },
        {
          "name": "Verkooppunt VVV Dinercheque",
          "id": "41.6",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Verkooppunt VVV Dinercheque"
            }
          }
        },
        {
          "name": "Verkooppunt VVV Cadeaubon",
          "id": "41.7",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Verkooppunt VVV Cadeaubon"
            }
          }
        },
        {
          "name": "Verkooppunt VVV Lekkerweg cadeaukaart",
          "id": "41.8",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Verkooppunt VVV Lekkerweg cadeaukaart"
            }
          }
        },
        {
          "name": "Keten",
          "id": "41.9",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Keten"
            }
          },
          "options": [
            {
              "name": "Bruna",
              "id": "41.9.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Bruna"
                }
              }
            },
            {
              "name": "GivaCard",
              "id": "41.9.10",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "GivaCard"
                }
              }
            },
            {
              "name": "Shoeby",
              "id": "41.9.100",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Shoeby"
                }
              }
            },
            {
              "name": "Van Os Tassen en Koffers",
              "id": "41.9.101",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Van Os Tassen en Koffers"
                }
              }
            },
            {
              "name": "Rituals",
              "id": "41.9.102",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Rituals"
                }
              }
            },
            {
              "name": "Durlinger",
              "id": "41.9.103",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Durlinger"
                }
              }
            },
            {
              "name": "Dungelmann",
              "id": "41.9.104",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Dungelmann"
                }
              }
            },
            {
              "name": "Tamaris",
              "id": "41.9.105",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Tamaris"
                }
              }
            },
            {
              "name": "Shoetime",
              "id": "41.9.106",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Shoetime"
                }
              }
            },
            {
              "name": "Deen",
              "id": "41.9.107",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Deen"
                }
              }
            },
            {
              "name": "Jan Linders Supermarkten",
              "id": "41.9.108",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Jan Linders Supermarkten"
                }
              }
            },
            {
              "name": "Ranzijn",
              "id": "41.9.109",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ranzijn"
                }
              }
            },
            {
              "name": "Libris/Blz",
              "id": "41.9.11",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Libris/Blz"
                }
              }
            },
            {
              "name": "Poiesz Supermarkten",
              "id": "41.9.110",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Poiesz Supermarkten"
                }
              }
            },
            {
              "name": "Ter Stal",
              "id": "41.9.111",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ter Stal"
                }
              }
            },
            {
              "name": "CASA",
              "id": "41.9.112",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "CASA"
                }
              }
            },
            {
              "name": "Albert Heijn",
              "id": "41.9.113",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Albert Heijn"
                }
              }
            },
            {
              "name": "Lidl",
              "id": "41.9.114",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Lidl"
                }
              }
            },
            {
              "name": "Bristol",
              "id": "41.9.115",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Bristol"
                }
              }
            },
            {
              "name": "Hunkemoller",
              "id": "41.9.116",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Hunkemoller"
                }
              }
            },
            {
              "name": "Prepaid Factory",
              "id": "41.9.12",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Prepaid Factory"
                }
              }
            },
            {
              "name": "Tabaktief",
              "id": "41.9.13",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Tabaktief"
                }
              }
            },
            {
              "name": "Tabaronde",
              "id": "41.9.14",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Tabaronde"
                }
              }
            },
            {
              "name": "Beter Horen",
              "id": "41.9.15",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Beter Horen"
                }
              }
            },
            {
              "name": "Plantage",
              "id": "41.9.16",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Plantage"
                }
              }
            },
            {
              "name": "Cook & Co",
              "id": "41.9.17",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Cook & Co"
                }
              }
            },
            {
              "name": "Dille & Kamille",
              "id": "41.9.18",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Dille & Kamille"
                }
              }
            },
            {
              "name": "Oil & Vinegair",
              "id": "41.9.19",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Oil & Vinegair"
                }
              }
            },
            {
              "name": "ANWB",
              "id": "41.9.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "ANWB"
                }
              }
            },
            {
              "name": "Enorm",
              "id": "41.9.20",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Enorm"
                }
              }
            },
            {
              "name": "Fixet",
              "id": "41.9.21",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Fixet"
                }
              }
            },
            {
              "name": "Formido",
              "id": "41.9.22",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Formido"
                }
              }
            },
            {
              "name": "Gamma",
              "id": "41.9.23",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Gamma"
                }
              }
            },
            {
              "name": "Hornbach",
              "id": "41.9.24",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Hornbach"
                }
              }
            },
            {
              "name": "Hubo",
              "id": "41.9.25",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Hubo"
                }
              }
            },
            {
              "name": "Karwei",
              "id": "41.9.26",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Karwei"
                }
              }
            },
            {
              "name": "Multimate",
              "id": "41.9.27",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Multimate"
                }
              }
            },
            {
              "name": "Praxis",
              "id": "41.9.28",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Praxis"
                }
              }
            },
            {
              "name": "Dobey",
              "id": "41.9.29",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Dobey"
                }
              }
            },
            {
              "name": "PostNL",
              "id": "41.9.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "PostNL"
                }
              }
            },
            {
              "name": "Faunaland",
              "id": "41.9.30",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Faunaland"
                }
              }
            },
            {
              "name": "Douglas",
              "id": "41.9.31",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Douglas"
                }
              }
            },
            {
              "name": "Etos",
              "id": "41.9.32",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Etos"
                }
              }
            },
            {
              "name": "Mooi Parfumerie",
              "id": "41.9.33",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Mooi Parfumerie"
                }
              }
            },
            {
              "name": "De Tuinen",
              "id": "41.9.34",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "De Tuinen"
                }
              }
            },
            {
              "name": "Trekpleister",
              "id": "41.9.35",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Trekpleister"
                }
              }
            },
            {
              "name": "Expert",
              "id": "41.9.36",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Expert"
                }
              }
            },
            {
              "name": "Pipoos",
              "id": "41.9.37",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Pipoos"
                }
              }
            },
            {
              "name": "Humphrey's",
              "id": "41.9.38",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Humphrey's"
                }
              }
            },
            {
              "name": "La Place",
              "id": "41.9.39",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "La Place"
                }
              }
            },
            {
              "name": "Primera",
              "id": "41.9.4",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Primera"
                }
              }
            },
            {
              "name": "Siebel Juweliers",
              "id": "41.9.40",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Siebel Juweliers"
                }
              }
            },
            {
              "name": "C&A",
              "id": "41.9.41",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "C&A"
                }
              }
            },
            {
              "name": "Duthler",
              "id": "41.9.42",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Duthler"
                }
              }
            },
            {
              "name": "Houtbrox",
              "id": "41.9.43",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Houtbrox"
                }
              }
            },
            {
              "name": "Jeans Centre",
              "id": "41.9.44",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Jeans Centre"
                }
              }
            },
            {
              "name": "Purdey",
              "id": "41.9.45",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Purdey"
                }
              }
            },
            {
              "name": "Terres des Hommes",
              "id": "41.9.46",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Terres des Hommes"
                }
              }
            },
            {
              "name": "Foot Locker",
              "id": "41.9.47",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Foot Locker"
                }
              }
            },
            {
              "name": "Top 1 Toys",
              "id": "41.9.48",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Top 1 Toys"
                }
              }
            },
            {
              "name": "Bever Zwerfsport",
              "id": "41.9.49",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Bever Zwerfsport"
                }
              }
            },
            {
              "name": "AKO",
              "id": "41.9.5",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "AKO"
                }
              }
            },
            {
              "name": "Optisport",
              "id": "41.9.50",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Optisport"
                }
              }
            },
            {
              "name": "Perry Sport",
              "id": "41.9.51",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Perry Sport"
                }
              }
            },
            {
              "name": "Game  Mania",
              "id": "41.9.52",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Game  Mania"
                }
              }
            },
            {
              "name": "Boerenbond",
              "id": "41.9.53",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Boerenbond"
                }
              }
            },
            {
              "name": "Eurofleur",
              "id": "41.9.54",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Eurofleur"
                }
              }
            },
            {
              "name": "Groenrijk",
              "id": "41.9.55",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Groenrijk"
                }
              }
            },
            {
              "name": "Fleurop",
              "id": "41.9.56",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Fleurop"
                }
              }
            },
            {
              "name": "Intratuin",
              "id": "41.9.57",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Intratuin"
                }
              }
            },
            {
              "name": "Welkoop",
              "id": "41.9.58",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Welkoop"
                }
              }
            },
            {
              "name": "Uw Groene Vakwinkel",
              "id": "41.9.59",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Uw Groene Vakwinkel"
                }
              }
            },
            {
              "name": "COOP",
              "id": "41.9.6",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "COOP"
                }
              }
            },
            {
              "name": "Hema",
              "id": "41.9.60",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Hema"
                }
              }
            },
            {
              "name": "Burmann",
              "id": "41.9.61",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Burmann"
                }
              }
            },
            {
              "name": "Xenos",
              "id": "41.9.62",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Xenos"
                }
              }
            },
            {
              "name": "Woonexpress",
              "id": "41.9.63",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Woonexpress"
                }
              }
            },
            {
              "name": "Intersport",
              "id": "41.9.64",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Intersport"
                }
              }
            },
            {
              "name": "Sundays",
              "id": "41.9.65",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Sundays"
                }
              }
            },
            {
              "name": "Big Bazar",
              "id": "41.9.66",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Big Bazar"
                }
              }
            },
            {
              "name": "Christine Le Duc",
              "id": "41.9.67",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Christine Le Duc"
                }
              }
            },
            {
              "name": "Van Haren",
              "id": "41.9.68",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Van Haren"
                }
              }
            },
            {
              "name": "Thermen & Beauty groep",
              "id": "41.9.69",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Thermen & Beauty groep"
                }
              }
            },
            {
              "name": "The Read Shop",
              "id": "41.9.7",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "The Read Shop"
                }
              }
            },
            {
              "name": "Bart Smit",
              "id": "41.9.70",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Bart Smit"
                }
              }
            },
            {
              "name": "BCC",
              "id": "41.9.71",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "BCC"
                }
              }
            },
            {
              "name": "Bijenkorf",
              "id": "41.9.72",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Bijenkorf"
                }
              }
            },
            {
              "name": "Blokker",
              "id": "41.9.73",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Blokker"
                }
              }
            },
            {
              "name": "Ici Paris XL",
              "id": "41.9.74",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Ici Paris XL"
                }
              }
            },
            {
              "name": "Kruidvat",
              "id": "41.9.75",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Kruidvat"
                }
              }
            },
            {
              "name": "Intertoys",
              "id": "41.9.76",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Intertoys"
                }
              }
            },
            {
              "name": "Kijkshop",
              "id": "41.9.77",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Kijkshop"
                }
              }
            },
            {
              "name": "Leen Bakker",
              "id": "41.9.78",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Leen Bakker"
                }
              }
            },
            {
              "name": "Toys XL",
              "id": "41.9.79",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Toys XL"
                }
              }
            },
            {
              "name": "Cigo",
              "id": "41.9.8",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Cigo"
                }
              }
            },
            {
              "name": "Lucardi",
              "id": "41.9.80",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Lucardi"
                }
              }
            },
            {
              "name": "Marskramer",
              "id": "41.9.81",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Marskramer"
                }
              }
            },
            {
              "name": "Prénatal",
              "id": "41.9.82",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Prénatal"
                }
              }
            },
            {
              "name": "Euroflorist",
              "id": "41.9.83",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Euroflorist"
                }
              }
            },
            {
              "name": "Aktiesport",
              "id": "41.9.84",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Aktiesport"
                }
              }
            },
            {
              "name": "Duifhuizen",
              "id": "41.9.85",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Duifhuizen"
                }
              }
            },
            {
              "name": "Pets Place",
              "id": "41.9.86",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Pets Place"
                }
              }
            },
            {
              "name": "ONLY",
              "id": "41.9.87",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "ONLY"
                }
              }
            },
            {
              "name": "Cineville",
              "id": "41.9.88",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Cineville"
                }
              }
            },
            {
              "name": "Pathé",
              "id": "41.9.89",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Pathé"
                }
              }
            },
            {
              "name": "Texaco",
              "id": "41.9.9",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Texaco"
                }
              }
            },
            {
              "name": "Jumbo",
              "id": "41.9.90",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Jumbo"
                }
              }
            },
            {
              "name": "Nelson Schoenen",
              "id": "41.9.91",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Nelson Schoenen"
                }
              }
            },
            {
              "name": "Dirk",
              "id": "41.9.92",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Dirk"
                }
              }
            },
            {
              "name": "DeKaMarkt",
              "id": "41.9.93",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "DeKaMarkt"
                }
              }
            },
            {
              "name": "Spar",
              "id": "41.9.94",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Spar"
                }
              }
            },
            {
              "name": "Emté",
              "id": "41.9.95",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Emté"
                }
              }
            },
            {
              "name": "Plus",
              "id": "41.9.96",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Plus"
                }
              }
            },
            {
              "name": "DA",
              "id": "41.9.97",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "DA"
                }
              }
            },
            {
              "name": "Fletcher",
              "id": "41.9.98",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Fletcher"
                }
              }
            },
            {
              "name": "Poeisz",
              "id": "41.9.99",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Poeisz"
                }
              }
            }
          ]
        }
      ]
    },
    {
      "name": "Contactinfo zakelijk",
      "id": "42",
      "datatype": "freetext",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Contactinfo zakelijk"
        }
      },
      "properties": [
        {
          "name": "Contactpersoon",
          "id": "42.1",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Contactpersoon"
            }
          }
        },
        {
          "name": "E-mail",
          "id": "42.2",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "E-mail"
            }
          }
        },
        {
          "name": "Telefoon",
          "id": "42.3",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Telefoon"
            }
          }
        },
        {
          "name": "Zakelijke beschrijving",
          "id": "42.4",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Zakelijke beschrijving"
            }
          }
        },
        {
          "name": "Reserveringswebsite",
          "id": "42.5",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Reserveringswebsite"
            }
          }
        }
      ]
    },
    {
      "name": "Marketing",
      "id": "43",
      "datatype": "freetext",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Marketing"
        }
      },
      "properties": [
        {
          "name": "Topper",
          "id": "43.1",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Topper"
            }
          }
        },
        {
          "name": "Rating",
          "id": "43.2",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Rating"
            }
          },
          "options": [
            {
              "name": "Goud",
              "id": "43.2.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Goud"
                }
              }
            },
            {
              "name": "Zilver",
              "id": "43.2.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Zilver"
                }
              }
            },
            {
              "name": "Brons",
              "id": "43.2.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Brons"
                }
              }
            }
          ]
        },
        {
          "name": "Topper VBT",
          "id": "43.3",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Topper VBT"
            }
          }
        },
        {
          "name": "Rondom Haarlem",
          "id": "43.4",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Rondom Haarlem"
            }
          }
        },
        {
          "name": "YouTube-link",
          "id": "43.5",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "YouTube-link"
            }
          }
        },
        {
          "name": "Pay Off",
          "id": "43.6",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Pay Off"
            }
          }
        },
        {
          "name": "Ext_Prod_CIDN",
          "id": "43.7",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Ext_Prod_CIDN"
            }
          }
        }
      ]
    },
    {
      "name": "Faciliteiten (Congrescentra)",
      "id": "44",
      "datatype": "freetext",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Faciliteiten (Congrescentra)"
        }
      },
      "properties": [
        {
          "name": "Conferentieruimte",
          "id": "44.1",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Conferentieruimte"
            }
          }
        },
        {
          "name": "Spreekgestoelte",
          "id": "44.2",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Spreekgestoelte"
            }
          }
        },
        {
          "name": "Flip-over",
          "id": "44.3",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Flip-over"
            }
          }
        },
        {
          "name": "Daglicht in ruimte",
          "id": "44.4",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Daglicht in ruimte"
            }
          }
        },
        {
          "name": "Aantal restaurants",
          "id": "44.5",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Aantal restaurants"
            }
          },
          "unit": "restaurants"
        },
        {
          "name": "Auditorium",
          "id": "44.6",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Auditorium"
            }
          }
        },
        {
          "name": "Alleen vergaderen",
          "id": "44.7",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Alleen vergaderen"
            }
          }
        }
      ]
    },
    {
      "name": "Fietsverhuur",
      "id": "45",
      "datatype": "freetext",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Fietsverhuur"
        }
      },
      "properties": [
        {
          "name": "Fiets met versnellingen",
          "id": "45.1",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Fiets met versnellingen"
            }
          }
        },
        {
          "name": "Elektrische fiets",
          "id": "45.2",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Elektrische fiets"
            }
          }
        },
        {
          "name": "Tandem",
          "id": "45.3",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Tandem"
            }
          }
        },
        {
          "name": "ATB/Mountainbike",
          "id": "45.4",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "ATB/Mountainbike"
            }
          }
        },
        {
          "name": "Kinderfiets",
          "id": "45.5",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kinderfiets"
            }
          }
        },
        {
          "name": "Kinderzitje",
          "id": "45.6",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kinderzitje"
            }
          }
        },
        {
          "name": "Fietsendrager",
          "id": "45.7",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Fietsendrager"
            }
          }
        },
        {
          "name": "Oplaadpunt elek. fiets",
          "id": "45.8",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Oplaadpunt elek. fiets"
            }
          }
        }
      ]
    },
    {
      "name": "Kijkwijzer",
      "id": "46",
      "datatype": "freetext",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Kijkwijzer"
        }
      },
      "properties": [
        {
          "name": "Voor alle leeftijden",
          "id": "46.1",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Voor alle leeftijden"
            }
          }
        },
        {
          "name": "Grof taalgebruik",
          "id": "46.10",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Grof taalgebruik"
            }
          }
        },
        {
          "name": "Let op met kinderen tot 9 jaar",
          "id": "46.11",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Let op met kinderen tot 9 jaar"
            }
          }
        },
        {
          "name": "Meekijken gewenst met kinderen jonger dan 6 jaar",
          "id": "46.2",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Meekijken gewenst met kinderen jonger dan 6 jaar"
            }
          }
        },
        {
          "name": "Niet voor kinderen jonger dan 12 jaar",
          "id": "46.3",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Niet voor kinderen jonger dan 12 jaar"
            }
          }
        },
        {
          "name": "Niet voor personen jonger dan 16 jaar",
          "id": "46.4",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Niet voor personen jonger dan 16 jaar"
            }
          }
        },
        {
          "name": "Geweld",
          "id": "46.5",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Geweld"
            }
          }
        },
        {
          "name": "Seks",
          "id": "46.6",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Seks"
            }
          }
        },
        {
          "name": "Angst",
          "id": "46.7",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Angst"
            }
          }
        },
        {
          "name": "Drugs en alcoholmisbruik",
          "id": "46.8",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Drugs en alcoholmisbruik"
            }
          }
        },
        {
          "name": "Discriminatie",
          "id": "46.9",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Discriminatie"
            }
          }
        }
      ]
    },
    {
      "name": "Regio",
      "id": "47",
      "datatype": "freetext",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Regio"
        }
      },
      "properties": [
        {
          "name": "Regio",
          "id": "47.1",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Regio"
            }
          }
        },
        {
          "name": "Overijssel",
          "id": "47.10",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Overijssel"
            }
          }
        },
        {
          "name": "Utrecht",
          "id": "47.11",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Utrecht"
            }
          }
        },
        {
          "name": "Zeeland",
          "id": "47.12",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Zeeland"
            }
          }
        },
        {
          "name": "Zuid-Holland",
          "id": "47.13",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Zuid-Holland"
            }
          },
          "options": [
            {
              "name": "Alblasserwaard / Vijfheerenlanden",
              "id": "47.13.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Alblasserwaard / Vijfheerenlanden"
                }
              }
            },
            {
              "name": "Zuid-Hollandse eilanden",
              "id": "47.13.10",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Zuid-Hollandse eilanden"
                }
              }
            },
            {
              "name": "Bollenstreek",
              "id": "47.13.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Bollenstreek"
                }
              }
            },
            {
              "name": "Groene Hart",
              "id": "47.13.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Groene Hart"
                }
              }
            },
            {
              "name": "Hoeksewaard",
              "id": "47.13.4",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Hoeksewaard"
                }
              }
            },
            {
              "name": "Midden-Delftland",
              "id": "47.13.5",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Midden-Delftland"
                }
              }
            },
            {
              "name": "Plassengebied",
              "id": "47.13.6",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Plassengebied"
                }
              }
            },
            {
              "name": "Den Haag",
              "id": "47.13.7",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Den Haag"
                }
              }
            },
            {
              "name": "Dordrecht",
              "id": "47.13.8",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Dordrecht"
                }
              }
            },
            {
              "name": "Rotterdam",
              "id": "47.13.9",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Rotterdam"
                }
              }
            }
          ]
        },
        {
          "name": "Toeristische regio",
          "id": "47.14",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Toeristische regio"
            }
          },
          "options": [
            {
              "name": "Strand/Kust",
              "id": "47.14.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Strand/Kust"
                }
              }
            },
            {
              "name": "Veluwe",
              "id": "47.14.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Veluwe"
                }
              }
            },
            {
              "name": "Waddeneilanden",
              "id": "47.14.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Waddeneilanden"
                }
              }
            },
            {
              "name": "Achterhoek",
              "id": "47.14.4",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Achterhoek"
                }
              }
            }
          ]
        },
        {
          "name": "Drenthe",
          "id": "47.2",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Drenthe"
            }
          }
        },
        {
          "name": "Flevoland",
          "id": "47.3",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Flevoland"
            }
          },
          "options": [
            {
              "name": "Almere",
              "id": "47.3.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Almere"
                }
              }
            },
            {
              "name": "Dronten",
              "id": "47.3.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Dronten"
                }
              }
            },
            {
              "name": "Lelystad",
              "id": "47.3.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Lelystad"
                }
              }
            },
            {
              "name": "Noordoostpolder",
              "id": "47.3.4",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Noordoostpolder"
                }
              }
            },
            {
              "name": "Urk",
              "id": "47.3.5",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Urk"
                }
              }
            },
            {
              "name": "Zeewolde",
              "id": "47.3.6",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Zeewolde"
                }
              }
            }
          ]
        },
        {
          "name": "Friesland",
          "id": "47.4",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Friesland"
            }
          }
        },
        {
          "name": "Gelderland",
          "id": "47.5",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Gelderland"
            }
          }
        },
        {
          "name": "Groningen",
          "id": "47.6",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Groningen"
            }
          }
        },
        {
          "name": "Limburg",
          "id": "47.7",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Limburg"
            }
          }
        },
        {
          "name": "Noord-Brabant",
          "id": "47.8",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Noord-Brabant"
            }
          }
        },
        {
          "name": "Noord-Holland",
          "id": "47.9",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Noord-Holland"
            }
          },
          "options": [
            {
              "name": "Amstelland - Meerlanden",
              "id": "47.9.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Amstelland - Meerlanden"
                }
              }
            },
            {
              "name": "West-Friesland",
              "id": "47.9.10",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "West-Friesland"
                }
              }
            },
            {
              "name": "Zaanstreek",
              "id": "47.9.11",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Zaanstreek"
                }
              }
            },
            {
              "name": "Zuid-Kennemerland",
              "id": "47.9.12",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Zuid-Kennemerland"
                }
              }
            },
            {
              "name": "Gooi en Vechtstreek",
              "id": "47.9.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Gooi en Vechtstreek"
                }
              }
            },
            {
              "name": "IJmond",
              "id": "47.9.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "IJmond"
                }
              }
            },
            {
              "name": "Kop van Noord-Holland",
              "id": "47.9.4",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Kop van Noord-Holland"
                }
              }
            },
            {
              "name": "Laag Holland",
              "id": "47.9.5",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Laag Holland"
                }
              }
            },
            {
              "name": "Noord-Kennemerland",
              "id": "47.9.6",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Noord-Kennemerland"
                }
              }
            },
            {
              "name": "Amsterdam",
              "id": "47.9.7",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Amsterdam"
                }
              }
            },
            {
              "name": "Texel",
              "id": "47.9.8",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Texel"
                }
              }
            },
            {
              "name": "Waterland",
              "id": "47.9.9",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Waterland"
                }
              }
            }
          ]
        }
      ]
    },
    {
      "name": "Leeftijd",
      "id": "48",
      "datatype": "freetext",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Leeftijd"
        }
      },
      "properties": [
        {
          "name": "Minimum leeftijd",
          "id": "48.1",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Minimum leeftijd"
            }
          }
        },
        {
          "name": "Maximum leeftijd",
          "id": "48.2",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Maximum leeftijd"
            }
          }
        },
        {
          "name": "Leeftijd jeugd",
          "id": "48.3",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Leeftijd jeugd"
            }
          },
          "options": [
            {
              "name": "0 tot 2 jaar",
              "id": "48.3.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "0 tot 2 jaar"
                }
              }
            },
            {
              "name": "2 tot 4 jaar",
              "id": "48.3.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "2 tot 4 jaar"
                }
              }
            },
            {
              "name": "4 tot 6 jaar",
              "id": "48.3.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "4 tot 6 jaar"
                }
              }
            },
            {
              "name": "6 tot 8 jaar",
              "id": "48.3.4",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "6 tot 8 jaar"
                }
              }
            },
            {
              "name": "8 tot 10 jaar",
              "id": "48.3.5",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "8 tot 10 jaar"
                }
              }
            },
            {
              "name": "10 tot 12 jaar",
              "id": "48.3.6",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "10 tot 12 jaar"
                }
              }
            },
            {
              "name": "Alle leeftijden",
              "id": "48.3.7",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Alle leeftijden"
                }
              }
            }
          ]
        }
      ]
    },
    {
      "name": "Provincies",
      "id": "49",
      "datatype": "freetext",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Provincies"
        }
      },
      "properties": [
        {
          "name": "Provincie",
          "id": "49.1",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Provincie"
            }
          },
          "options": [
            {
              "name": "Drenthe",
              "id": "49.1.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Drenthe"
                }
              }
            },
            {
              "name": "Utrecht",
              "id": "49.1.10",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Utrecht"
                }
              }
            },
            {
              "name": "Zeeland",
              "id": "49.1.11",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Zeeland"
                }
              }
            },
            {
              "name": "Zuid-Holland",
              "id": "49.1.12",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Zuid-Holland"
                }
              }
            },
            {
              "name": "Flevoland",
              "id": "49.1.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Flevoland"
                }
              }
            },
            {
              "name": "Friesland",
              "id": "49.1.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Friesland"
                }
              }
            },
            {
              "name": "Gelderland",
              "id": "49.1.4",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Gelderland"
                }
              }
            },
            {
              "name": "Groningen",
              "id": "49.1.5",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Groningen"
                }
              }
            },
            {
              "name": "Limburg",
              "id": "49.1.6",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Limburg"
                }
              }
            },
            {
              "name": "Noord-Brabant",
              "id": "49.1.7",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Noord-Brabant"
                }
              }
            },
            {
              "name": "Noord-Holland",
              "id": "49.1.8",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Noord-Holland"
                }
              }
            },
            {
              "name": "Overijssel",
              "id": "49.1.9",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Overijssel"
                }
              }
            }
          ]
        }
      ]
    },
    {
      "name": "Capaciteit",
      "id": "5",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Capaciteit"
        }
      },
      "properties": [
        {
          "name": "Oppervlakte",
          "id": "5.1",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Oppervlakte"
            }
          },
          "unit": "m2"
        },
        {
          "name": "aantal ligplaatsen",
          "id": "5.10",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "aantal ligplaatsen"
            }
          }
        },
        {
          "name": "Camperstandplaatsen",
          "id": "5.11",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Camperstandplaatsen"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Caravanstandplaatsen",
          "id": "5.12",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Caravanstandplaatsen"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Tentenstandplaatsen",
          "id": "5.13",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Tentenstandplaatsen"
            }
          },
          "unit": "aantal"
        },
        {
          "name": "Toelichting capaciteit",
          "id": "5.14",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Toelichting capaciteit"
            }
          }
        },
        {
          "name": "Minimale groepsgrootte",
          "id": "5.34",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Minimale groepsgrootte"
            }
          },
          "unit": "personen"
        },
        {
          "name": "Maximale groepsgrootte",
          "id": "5.15",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Maximale groepsgrootte"
            }
          },
          "unit": "personen"
        },
        {
          "name": "Aantal banen",
          "id": "5.16",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Aantal banen"
            }
          }
        },
        {
          "name": "Capaciteit grootste zaal",
          "id": "5.17",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Capaciteit grootste zaal"
            }
          },
          "unit": "personen"
        },
        {
          "name": "Capaciteit kleinste zaal",
          "id": "5.18",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Capaciteit kleinste zaal"
            }
          },
          "unit": "personen"
        },
        {
          "name": "Capaciteit in receptie opstelling",
          "id": "5.19",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Capaciteit in receptie opstelling"
            }
          },
          "unit": "personen"
        },
        {
          "name": "Oppervlakte (buiten)",
          "id": "5.2",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Oppervlakte (buiten)"
            }
          },
          "unit": "hectare"
        },
        {
          "name": "Capaciteit zittend diner",
          "id": "5.20",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Capaciteit zittend diner"
            }
          },
          "unit": "personen"
        },
        {
          "name": "Capaciteit in cabaret opstelling",
          "id": "5.21",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Capaciteit in cabaret opstelling"
            }
          },
          "unit": "personen"
        },
        {
          "name": "Capaciteit in theater opstelling",
          "id": "5.22",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Capaciteit in theater opstelling"
            }
          },
          "unit": "personen"
        },
        {
          "name": "Aantal vergaderplaatsen",
          "id": "5.23",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Aantal vergaderplaatsen"
            }
          }
        },
        {
          "name": "Capaciteit in U-vorm",
          "id": "5.24",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Capaciteit in U-vorm"
            }
          },
          "unit": "personen"
        },
        {
          "name": "Capaciteit in school opstelling",
          "id": "5.25",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Capaciteit in school opstelling"
            }
          },
          "unit": "personen"
        },
        {
          "name": "Capaciteit in carré opstelling",
          "id": "5.26",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Capaciteit in carré opstelling"
            }
          },
          "unit": "personen"
        },
        {
          "name": "Lengte",
          "id": "5.27",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Lengte"
            }
          },
          "unit": "meter"
        },
        {
          "name": "Breedte",
          "id": "5.28",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Breedte"
            }
          },
          "unit": "meter"
        },
        {
          "name": "Hoogte",
          "id": "5.29",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Hoogte"
            }
          },
          "unit": "meter"
        },
        {
          "name": "Aantal personen",
          "id": "5.3",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Aantal personen"
            }
          }
        },
        {
          "name": "Capaciteit in blokopstelling",
          "id": "5.30",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Capaciteit in blokopstelling"
            }
          },
          "unit": "personen"
        },
        {
          "name": "Maximale hoogte grootste zaal",
          "id": "5.31",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Maximale hoogte grootste zaal"
            }
          },
          "unit": "meter"
        },
        {
          "name": "Minimale capaciteit",
          "id": "5.33",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Minimale capaciteit"
            }
          },
          "unit": "personen"
        },
        {
          "name": "Maximale capaciteit",
          "id": "5.32",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Maximale capaciteit"
            }
          },
          "unit": "personen"
        },
        {
          "name": "Aantal zalen",
          "id": "5.4",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Aantal zalen"
            }
          }
        },
        {
          "name": "Aantal bedden",
          "id": "5.5",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Aantal bedden"
            }
          }
        },
        {
          "name": "Aantal kamers",
          "id": "5.6",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Aantal kamers"
            }
          }
        },
        {
          "name": "Aantal couverts",
          "id": "5.7",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Aantal couverts"
            }
          }
        },
        {
          "name": "aantal toeristische plaatsen",
          "id": "5.8",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "aantal toeristische plaatsen"
            }
          }
        },
        {
          "name": "aantal vaste plaatsen",
          "id": "5.9",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "aantal vaste plaatsen"
            }
          }
        }
      ]
    },
    {
      "name": "Erkenningen",
      "id": "50",
      "datatype": "freetext",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Erkenningen"
        }
      },
      "properties": [
        {
          "name": "Erkenningen",
          "id": "50.1",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Erkenningen"
            }
          },
          "options": [
            {
              "name": "Bib Gourmand",
              "id": "50.1.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Bib Gourmand"
                }
              }
            },
            {
              "name": "Lekker500",
              "id": "50.1.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Lekker500"
                }
              }
            },
            {
              "name": "Restaurantweek",
              "id": "50.1.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Restaurantweek"
                }
              }
            },
            {
              "name": "Gault&Millau",
              "id": "50.1.4",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Gault&Millau"
                }
              }
            }
          ]
        }
      ]
    },
    {
      "name": "Synergy",
      "id": "55",
      "datatype": "freetext",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Synergy"
        }
      },
      "properties": [
        {
          "name": "Synergy Relatiecode",
          "id": "55.1",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Synergy Relatiecode"
            }
          }
        },
        {
          "name": "Archie-Cleavis ID",
          "id": "55.2",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Archie-Cleavis ID"
            }
          }
        },
        {
          "name": "Feet ID",
          "id": "55.3",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Feet ID"
            }
          }
        }
      ]
    },
    {
      "name": "Prijs",
      "id": "7",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Prijs"
        }
      },
      "properties": [
        {
          "name": "Prijs vanaf",
          "id": "7.1",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Prijs vanaf"
            }
          }
        },
        {
          "name": "diner vanaf",
          "id": "7.10",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "diner vanaf"
            }
          }
        },
        {
          "name": "toeslag halfpension",
          "id": "7.11",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "toeslag halfpension"
            }
          }
        },
        {
          "name": "toeslag volpension",
          "id": "7.12",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "toeslag volpension"
            }
          }
        },
        {
          "name": "per week vanaf",
          "id": "7.13",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "per week vanaf"
            }
          }
        },
        {
          "name": "per week per persoon vanaf",
          "id": "7.14",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "per week per persoon vanaf"
            }
          }
        },
        {
          "name": "per weekend vanaf",
          "id": "7.15",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "per weekend vanaf"
            }
          }
        },
        {
          "name": "per weekend per persoon vanaf",
          "id": "7.16",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "per weekend per persoon vanaf"
            }
          }
        },
        {
          "name": "per midweek vanaf",
          "id": "7.17",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "per midweek vanaf"
            }
          }
        },
        {
          "name": "per midweek per persoon vanaf",
          "id": "7.18",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "per midweek per persoon vanaf"
            }
          }
        },
        {
          "name": "per dag vanaf",
          "id": "7.19",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "per dag vanaf"
            }
          }
        },
        {
          "name": "Prijs tot",
          "id": "7.2",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Prijs tot"
            }
          }
        },
        {
          "name": "per dag per persoon vanaf",
          "id": "7.20",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "per dag per persoon vanaf"
            }
          }
        },
        {
          "name": "bedlinnen p.p.",
          "id": "7.21",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "bedlinnen p.p."
            }
          }
        },
        {
          "name": "eindschoonmaak vanaf",
          "id": "7.22",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "eindschoonmaak vanaf"
            }
          }
        },
        {
          "name": "toeristenbelasting p.p.p.d.",
          "id": "7.23",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "toeristenbelasting p.p.p.d."
            }
          }
        },
        {
          "name": "leden inclusief ontbijt vanaf",
          "id": "7.24",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "leden inclusief ontbijt vanaf"
            }
          }
        },
        {
          "name": "niet-leden inclusief ontbijt vanaf",
          "id": "7.25",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "niet-leden inclusief ontbijt vanaf"
            }
          }
        },
        {
          "name": "kinderen",
          "id": "7.26",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "kinderen"
            }
          }
        },
        {
          "name": "groepen kinderen",
          "id": "7.27",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "groepen kinderen"
            }
          }
        },
        {
          "name": "volwassenen",
          "id": "7.28",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "volwassenen"
            }
          }
        },
        {
          "name": "groepen volwassenen",
          "id": "7.29",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "groepen volwassenen"
            }
          }
        },
        {
          "name": "1-persoons kamer met privé sanitair vanaf",
          "id": "7.3",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "1-persoons kamer met privé sanitair vanaf"
            }
          }
        },
        {
          "name": "senioren",
          "id": "7.30",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "senioren"
            }
          }
        },
        {
          "name": "leden/begunstigers",
          "id": "7.31",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "leden/begunstigers"
            }
          }
        },
        {
          "name": "mindervaliden",
          "id": "7.32",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "mindervaliden"
            }
          }
        },
        {
          "name": "MJK (Museumjaarkaart)",
          "id": "7.33",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "MJK (Museumjaarkaart)"
            }
          }
        },
        {
          "name": "CJP (Cultureel Jongeren Paspoort)",
          "id": "7.34",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "CJP (Cultureel Jongeren Paspoort)"
            }
          }
        },
        {
          "name": "hoofdgerecht vanaf",
          "id": "7.35",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "hoofdgerecht vanaf"
            }
          }
        },
        {
          "name": "driegangenmenu vanaf",
          "id": "7.36",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "driegangenmenu vanaf"
            }
          }
        },
        {
          "name": "toegang leden",
          "id": "7.37",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "toegang leden"
            }
          }
        },
        {
          "name": "toegang niet-leden",
          "id": "7.38",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "toegang niet-leden"
            }
          }
        },
        {
          "name": "toelichting prijs",
          "id": "7.39",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "toelichting prijs"
            }
          }
        },
        {
          "name": "1-persoons kamer zonder privé sanitair vanaf",
          "id": "7.4",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "1-persoons kamer zonder privé sanitair vanaf"
            }
          }
        },
        {
          "name": "groepen vanaf",
          "id": "7.40",
          "datatype": "integer",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "groepen vanaf"
            }
          },
          "unit": "personen"
        },
        {
          "name": "tent per dag",
          "id": "7.41",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "tent per dag"
            }
          }
        },
        {
          "name": "auto per dag",
          "id": "7.42",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "auto per dag"
            }
          }
        },
        {
          "name": "caravan per dag",
          "id": "7.43",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "caravan per dag"
            }
          }
        },
        {
          "name": "camper per dag",
          "id": "7.44",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "camper per dag"
            }
          }
        },
        {
          "name": "Borg",
          "id": "7.45",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Borg"
            }
          }
        },
        {
          "name": "Per uur vanaf",
          "id": "7.46",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Per uur vanaf"
            }
          }
        },
        {
          "name": "Prijs",
          "id": "7.47",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Prijs"
            }
          }
        },
        {
          "name": "Verkooppunt",
          "id": "7.48",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Verkooppunt"
            }
          },
          "properties": [
            {
              "name": "Verkooppunt naam",
              "id": "7.48.1",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Verkooppunt naam"
                }
              }
            },
            {
              "name": "Verkooppunt straat en nr",
              "id": "7.48.2",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Verkooppunt straat en nr"
                }
              }
            },
            {
              "name": "Verkooppunt postcode",
              "id": "7.48.3",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Verkooppunt postcode"
                }
              }
            },
            {
              "name": "Verkooppunt plaatsnaam",
              "id": "7.48.4",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Verkooppunt plaatsnaam"
                }
              }
            },
            {
              "name": "Verkooppunt toelichting",
              "id": "7.48.5",
              "datatype": "freetext",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Verkooppunt toelichting"
                }
              }
            }
          ]
        },
        {
          "name": "LMTS prijs vanaf",
          "id": "7.49",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "LMTS prijs vanaf"
            }
          }
        },
        {
          "name": "2-persoons kamer met privé sanitair vanaf",
          "id": "7.5",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "2-persoons kamer met privé sanitair vanaf"
            }
          }
        },
        {
          "name": "LMTS prijs tot",
          "id": "7.50",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "LMTS prijs tot"
            }
          }
        },
        {
          "name": "LMTS Kaartverkoop URL",
          "id": "7.51",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "LMTS Kaartverkoop URL"
            }
          }
        },
        {
          "name": "LMTS Prijsomschrijving",
          "id": "7.52",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "LMTS Prijsomschrijving"
            }
          }
        },
        {
          "name": "Jeugd prijs vanaf",
          "id": "7.53",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Jeugd prijs vanaf"
            }
          },
          "unit": "€"
        },
        {
          "name": "Jeugd prijs tot",
          "id": "7.54",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Jeugd prijs tot"
            }
          },
          "unit": "€"
        },
        {
          "name": "Jeugd Kaartverkoop URL",
          "id": "7.55",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Jeugd Kaartverkoop URL"
            }
          }
        },
        {
          "name": "Jeugd prijsomschrijving",
          "id": "7.56",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Jeugd prijsomschrijving"
            }
          }
        },
        {
          "name": "Uitpas prijs vanaf",
          "id": "7.57",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Uitpas prijs vanaf"
            }
          }
        },
        {
          "name": "Uitpas prijs tot",
          "id": "7.58",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Uitpas prijs tot"
            }
          }
        },
        {
          "name": "Uitpas Kaartverkoop URL",
          "id": "7.59",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Uitpas Kaartverkoop URL"
            }
          }
        },
        {
          "name": "2-persoons kamer zonder privé sanitair vanaf",
          "id": "7.6",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "2-persoons kamer zonder privé sanitair vanaf"
            }
          }
        },
        {
          "name": "Uitpas Prijsomschrijving",
          "id": "7.60",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Uitpas Prijsomschrijving"
            }
          }
        },
        {
          "name": "Kaartverkoop URL",
          "id": "7.61",
          "datatype": "freetext",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Kaartverkoop URL"
            }
          }
        },
        {
          "name": "per nacht per strekkende meter v.a.",
          "id": "7.62",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "per nacht per strekkende meter v.a."
            }
          },
          "unit": "€"
        },
        {
          "name": "Prijs is incl. ontbijt",
          "id": "7.63",
          "datatype": "yes",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Prijs is incl. ontbijt"
            }
          }
        },
        {
          "name": "ontbijt vanaf",
          "id": "7.7",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "ontbijt vanaf"
            }
          }
        },
        {
          "name": "lunch vanaf",
          "id": "7.8",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "lunch vanaf"
            }
          }
        },
        {
          "name": "lunchpakket vanaf",
          "id": "7.9",
          "datatype": "decimal",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "lunchpakket vanaf"
            }
          }
        }
      ]
    },
    {
      "name": "Doelgroep",
      "id": "8",
      "datatype": "freetext",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Doelgroep"
        }
      },
      "properties": [
        {
          "name": "kinderen",
          "id": "8.1",
          "datatype": "yes"
        },
        {
          "name": "alternatief",
          "id": "8.10",
          "datatype": "yes"
        },
        {
          "name": "groepen",
          "id": "8.16",
          "datatype": "yes"
        },
        {
          "name": "zakelijk",
          "id": "8.17",
          "datatype": "yes"
        },
        {
          "name": "gezinnen",
          "id": "8.2",
          "datatype": "yes",
        },
        {
          "name": "jongeren",
          "id": "8.3",
          "datatype": "yes",
        },
        {
          "name": "scholieren",
          "id": "8.4",
          "datatype": "yes",
        },
        {
          "name": "studenten",
          "id": "8.5",
          "datatype": "yes",
        },
        {
          "name": "volwassenen",
          "id": "8.6",
          "datatype": "yes",
        },
        {
          "name": "senioren",
          "id": "8.7",
          "datatype": "yes",
        },
        {
          "name": "gay",
          "id": "8.8",
          "datatype": "yes",
        },
        {
          "name": "lesbisch",
          "id": "8.9",
          "datatype": "yes",
        },
        {
          "name": "single",
          "id": "8.15",
          "datatype": "yes",
        },
        {
          "name": "met partner",
          "id": "8.11",
          "datatype": "yes",
        },
        {
          "name": "met vriend(in)",
          "id": "8.12",
          "datatype": "yes",
        },
        {
          "name": "hondenbezitter",
          "id": "8.13",
          "datatype": "yes",
        },
        {
          "name": "paardrijders",
          "id": "8.14",
          "datatype": "yes",
        }
      ]
    },
    {
      "name": "Belang",
      "id": "9",
      "datatype": "freetext",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Belang"
        }
      },
      "properties": [
        {
          "name": "Belang",
          "id": "9.0",
          "datatype": "choice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Belang"
            }
          },
          "options": [
            {
              "name": "internationaal",
              "id": "9.1",
              "datatype": "yes",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "internationaal"
                }
              }
            },
            {
              "name": "nationaal",
              "id": "9.2",
              "datatype": "yes",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "nationaal"
                }
              }
            },
            {
              "name": "regionaal",
              "id": "9.3",
              "datatype": "yes",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "regionaal"
                }
              }
            },
            {
              "name": "lokaal",
              "id": "9.4",
              "datatype": "yes",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "lokaal"
                }
              }
            }
          ]
        }
      ]
    },
    {
      "name": "Toegankelijkheid",
      "id": "100",
      "datatype": "freetext",
      "properties": [
        {
          "name": "Voorzieningen voor mensen die minder mobiel zijn",
          "id": "100.1",
          "datatype": "yesno"
        },
        {
          "name": "Voorzieningen voor mensen die blind- of slechtziend zijn",
          "id": "100.1.2",
          "datatype": "yesno"
        }
        ,
        {
          "name": "Voorzieningen voor mensen die doof of slechthorend zijn",
          "id": "100.1.3",
          "datatype": "yesno"
        },
        {
          "name": "Voorzieningen voor mensen die gevoelig voor prikkels zijn",
          "id": "100.1.4",
          "datatype": "yesno"
        },
        {
          "name": "URL met verdere informatie",
          "id": "100.2",
          "datatype": "freetext"
        },
        {
          "datatype": "break"
        },
        {
          "name": "Toegankelijk gebouw",
          "id": "100.1.5",
          "datatype": "yesno"
        },
        {
          "name": "Drempelloos gebouw en doorgangen zijn voldoende breed",
          "id": "100.1.6",
          "datatype": "yesno"
        },
        {
          "name": "Rolstoelvriendelijk toilet",
          "id": "100.1.7",
          "datatype": "yesno"
        },
        {
          "name": "Rustplekken",
          "id": "100.1.8",
          "datatype": "yesno"
        },
        {
          "name": "Mobiele pin",
          "id": "100.1.9",
          "datatype": "yesno"
        },
        {
          "name": "Assistentie hond",
          "id": "100.1.10",
          "datatype": "yesno"
        },
        {
          "name": "Persoonlijke assistent",
          "id": "100.1.11",
          "datatype": "yesno"
        },
        {
          "name": "Zintuigelijke beleving - Digitale tour",
          "id": "100.1.12",
          "datatype": "yesno"
        }, {
          "name": "Geleidelijnen en vloermarkeringen",
          "id": "100.1.13",
          "datatype": "yesno"
        }, {
          "name": "Lus voor slechthorende (vast of mobiel)",
          "id": "100.1.14",
          "datatype": "yesno"
        },
        {
          "name": "Gebarentaalinterpretatie",
          "id": "100.1.15",
          "datatype": "yesno"
        },
        {
          "name": "Gesloten ondertiteling",
          "id": "100.1.16",
          "datatype": "yesno"
        },
        {
          "name": "Open ondertiteling",
          "id": "100.1.17",
          "datatype": "yesno"
        },
        {
          "name": "Herkenning Sunflower Keycord",
          "id": "100.1.18",
          "datatype": "yesno"
        }, {
          "name": "Prikkelarme uren",
          "id": "100.1.19",
          "datatype": "yesno"
        },
        {
          "name": "Zintuigelijke beleving",
          "id": "100.1.20",
          "datatype": "yesno"
        },
        {
          "name": "Beeldbeschrijving",
          "id": "100.1.21",
          "datatype": "yesno"
        },
        {
          "name": "Toegankelijke afdruk (18 pt. of groter)",
          "id": "100.1.22",
          "datatype": "yesno"
        },
        {
          "name": "Materiaal in braille",
          "id": "100.1.23",
          "datatype": "yesno"
        },
        {
          "name": "Trappen met leuning",
          "id": "100.1.24",
          "datatype": "yesno"
        },
        {
          "name": "Toegankelijke lift",
          "id": "100.1.25",
          "datatype": "yesno"
        },
        {
          "name": "Prikkelarme ruimte",
          "id": "100.1.26",
          "datatype": "yesno"
        },
        {
          "name": "Toegang zonder drempels en treden worden overbrugd door een hellend vlak",
          "id": "100.1.27",
          "datatype": "yesno"
        },
        {
          "name": "Toelichting toegankelijkheid",
          "id": "100.1.28",
          "datatype": "freetext",
        }
      ]
    },
    {
      "name": "Services",
      "id": "101",
      "datatype": "freetext",
      "propertytranslations": {
        "Propertytranslation": {
          "lang": "nl",
          "text": "Services"
        }
      },
      "properties": [
        {
          "name": "Aangeboden services",
          "id": "101.1",
          "datatype": "multichoice",
          "propertytranslations": {
            "Propertytranslation": {
              "lang": "nl",
              "text": "Toelichting"
            }
          },
          "options": [
            {
              "name": "Pco",
              "id": "101.1.1",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Pco"
                }
              }
            },
            {
              "name": "Dmc",
              "id": "101.1.2",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Dmc"
                }
              }
            },
            {
              "name": "Meeting supplies",
              "id": "101.1.3",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Meeting supplies"
                }
              }
            },
            {
              "name": "Hospitality Hostess services",
              "id": "101.1.4",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Hospitality Hostess services"
                }
              }
            },
            {
              "name": "Catering",
              "id": "101.1.5",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Catering"
                }
              }
            },
            {
              "name": "Audio visuele middelen",
              "id": "101.1.6",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Audio visuele middelen"
                }
              }
            },
            {
              "name": "Branding & Decor",
              "id": "101.1.7",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Branding & Decor"
                }
              }
            },
            {
              "name": "Evenementenbureaus",
              "id": "101.1.8",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Evenementenbureaus"
                }
              }
            },
            {
              "name": "Vervoer",
              "id": "101.1.9",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Vervoer"
                }
              }
            },
            {
              "name": "Overige",
              "id": "101.1.10",
              "propertytranslations": {
                "Propertytranslation": {
                  "lang": "nl",
                  "text": "Overige"
                }
              }
            }
          ]
        }
      ]
    }
  ]
}
